export default {
    "de": {
      "AT": "Österreich",

      "Ab": "Ab",

      "BE": "Belgien",

      "BG": "Bulgarien",

      "CH": "Schweiz",

      "CY": "Zypern",

      "CZ": "Tschechien",

      "Camera": "Kamera",

      "DE": "Deutschland",

      "DK": "Dänemark",

      "DL.button.text": "Führerschein jetzt scannen",

      "DL.desr": "Lass deine Fahrerlaubnis bestätigen, indem du deinen Führerschein jetzt hochlädst.",

      "DL.paper.stepOne": "Lege deinen vollständig aufgeklappten Führerschein auf einen farblich neutralen Untergrund.",

      "DL.paper.stepThree": "Dein Führerschein muss auf dich und in einem EU-Land ausgestellt worden sein.",

      "DL.paper.stepTwo": "Nimm je ein Foto von der kompletten Vorder- und Rückseite auf.",

      "DL.stepFour": "Achte auf die Lichtverhältnisse und nimm alles ab, was dein Gesicht verdeckt (Brille, Hut etc.).",

      "DL.stepOne": "Halte deinen persönlichen Führerschein (nicht Ihren Personalausweis!) im Original bereit.",

      "DL.stepThree": "Dein Führerschein muss auf dich persönlich in einem EU-Land ausgestellt worden sein.",

      "DL.stepTwo": "Lege deinen Führerschein auf einen farblich neutralen Untergrund.",

      "DL.title": "Fahrerlaubnis",

      "EE": "Estland",

      "EL": "Griechenland",

      "ES": "Spanien",

      "EUR": "€",

      "FI": "Finnland",

      "FR": "Frankreich",

      "FRM_Franklin_Partner_Fix": "Franklin Partner Fix",

      "GB": "Großbritannien",

      "GR": "Griechenland",

      "HR": "Kroatien",

      "HU": "Ungarn",

      "ID.button.text": "Ausweisdokument jetzt scannen",

      "ID.desr": "Wähle, mit welchem Dokumenten-Typ du dich ausweisen möchtest.",

      "ID.id_card.stepFour": "Dein Ausweis muss in einem EU-Land ausgestellt worden sein.",

      "ID.id_card.stepOne": "Halte deinen persönlichen Personalausweis oder Aufenthaltstitel im Original bereit.",

      "ID.id_card.stepThree": "Achte auf die Lichtverhältnisse und nimm alles ab, was dein Gesicht verdeckt (Brille, Hut etc.).",

      "ID.id_card.stepTwo": "Lege deinen Ausweis auf einen farblich neutralen Untergrund.",

      "ID.manual.option": "Ich habe kein Dokument mit meiner Adresse",

      "ID.passport.stepOne": "Halte deinen persönlichen Reisepass im Original bereit.",

      "ID.passport.stepThree": "Achte auf die Lichtverhältnisse und nimm alles ab, was dein Gesicht verdeckt (Brille, Hut etc.).",

      "ID.passport.stepTwo": "Lege den Reisepass auf einen farblich neutralen Untergrund.",

      "ID.stepOne": "Ich habe ein in der EU ausgestelltes Ausweisdokument (Personalausweis, Reisepass oder Aufenthaltstitel).",

      "ID.stepThree": "Achte auf die Lichtverhältnisse und nimm alles ab, was dein Gesicht verdeckt (Brille, Hut etc.)",

      "ID.stepTwo": "Lege dein Ausweisdokument auf einen farblich neutralen Untergrund.",

      "ID.title": "Ausweisdokument",

      "ID.type.card": "Personalausweis oder Aufenthaltstitel",

      "ID.type.label": "Art des Dokuments wählen",

      "ID.type.passport": "Reisepass",

      "IE": "Irland",

      "IS": "Island",

      "IT": "Italien",

      "LI": "Liechtenstein",

      "LT": "Litauen",

      "LU": "Luxemburg",

      "LV": "Lettland",

      "MC": "Monaco",

      "MR": "Herr",

      "MRS": "Frau",

      "MT": "Malta",

      "NL": "Niederlande",

      "NO": "Norwegen",

      "PL": "Polen",

      "PT": "Portugal",

      "RO": "Rumänien",

      "SE": "Schweden",

      "SI": "Slowenien",

      "SK": "Slowakei",

      "UK": "Vereinigtes Königreich",

      "about.dialog": "Über GETAWAY",

      "about.url": "https://get-a-way.com/getaway-carsharing?utm_source=app",

      "accepted.invitation": "ANGENOMMENE EINLADUNGEN",

      "account-deposit.warning.btn": "Kaution hinterlegen",

      "account-deposit.warning.desc": "Um Zahlungen per SEPA-Lastschrift freizuschalten, hinterlege bitte jetzt den Kautionsbetrag. Kehre im Anschluss zu deinem Nutzerkonto zurück.",

      "account.deposit.description": "Um Zahlungen per SEPA-Lastschrift freizuschalten, hinterlege bitte jetzt den Kautionsbetrag. Kehre im Anschluss zu deinem Nutzerkonto zurück.",

      "account.low.schufa.desc": "Die Freischaltung dieser Zahlungsmethode ist fehlgeschlagen. Die Bonitätsprüfung ergab keine Übereinstimmung. Leider können wir Ihnen derzeit keine Alternative anbieten, um die Registrierung abzuschließen.\n\nWir bitten, von weiteren Registrierungsversuchen abzusehen.",

      "accountHolder": "Kontoinhaber",

      "action.permission.button.title": "Neu anmelden",

      "action.permission.details": "Wir haben eine Fehlermeldung erhalten und arbeiten bereits an der Lösung. Bitte beende jetzt die App und starte sie neu. \n\nSollte das Problem weiterhin auftreten, kontaktiere bitte unser Service Team.",

      "action.permission.title": "Etwas hat nicht funktioniert.",

      "add": "HINZUFÜGEN",

      "add.car.enhancement.wizard.description": "Je besser dein Auto ausgestattet ist, desto eher wird es gemietet.",

      "add.car.enhancement.wizard.title": "Aktualisiere die Ausstattung deines Autos",

      "add.damage.wizard.description": "Bitte protokolliere alle aktuellen Mängel am Auto, um für eventuelle Neuschäden reibungsfreier entschädigt zu werden.",

      "add.damage.wizard.footer": "Ich bestätige, dass das Auto bis auf die bereits aufgeführten Mängel schadenfrei ist.",

      "add.damage.wizard.title": "Schäden & Mängel",

      "add.damage.wizard.update": "ÜBERNEHMEN",

      "add.payment.method.success": "Danke, deine Zahlungsmethode ist aktualisiert worden.",

      "add.tariff": "Zur Tarifwahl",

      "addpayment.card.desc": "Kredit-/Debitkarte hinzufügen",

      "addpayment.card.title": "Visa oder MasterCard",

      "addpayment.directdebit.desc": "Girokonto hinzufügen",

      "addpayment.paypal": "Paypal",

      "addpayment.paypal.desc": "Verbinde dein Paypal-Konto",

      "address": "Meldeadresse",

      "address.austria.streetNum.label": "Haus/Stiege/Tür",

      "addressCheck.dialog.message": "Die Überprüfung deiner Meldeadresse war nicht erfolgreich. \n\nBitte kontaktiere unser Service Team.",

      "addressCheck.dialog.title": "Überprüfung der Meldeadresse",

      "addressCheck.dialog.tryAgain": "ERNEUT VERSUCHEN",

      "addressCheck.dialog.yes": "OK",

      "addressProof.button.text": "Dokument hochladen",

      "addressProof.desr": "Meine Adresse durch ein offizielles Dokument bestätigen lassen",

      "addressProof.stepOne": "Schreiben vom Finanzamt, von deiner Bank, etc.",

      "addressProof.stepTwo": "Adresse und Name gut lesbar",

      "addressProof.title": "Dokument mit Adresse",

      "addressSearch.cancel.text": "Abbrechen",

      "addressSearch.current.label": "Mein Standort",

      "addressSearch.search.placeholder": "Adresse suchen",

      "always.open": "rund um die Uhr",

      "android.update.dialog.message": "Es ist eine neue Version erhältlich. Bitte aktualisiere auf die neuste Version.",

      "android.update.dialog.no": "Später",

      "android.update.dialog.title": "Update erhältlich",

      "android.update.dialog.yes": "Update",

      "api_error.block_payment_parameter_missing": "Zahlung fehlgeschlagen. Bitte mit alternativer Zahlungsmethode oder später erneut versuchen.",

      "api_error.booking.confirm.blocked_payment_failed": "Problem mit autorisiertem Betrag. Buchung konnte nicht bestätigt werden.",

      "api_error.booking.confirm.fleet_not_allowed_for_circle": "Fahrzeug ist für diesen Nutzerkreis nicht verfügbar.",

      "api_error.booking.confirm.rental_type_not_matching": "Falscher Nutzerkreis-Typ für diese Buchung",

      "api_error.booking.payin.from": "Bitte wähle eine spätere Startzeit, um fortzufahren",

      "api_error.booking.update.conflicting_booking_for_car": "Es besteht bereits eine Reservierung für diesen Zeitraum.",

      "api_error.booking.update.conflicting_booking_for_user": "Nutzer hat bereits eine Reservierung in diesem Zeitraum",

      "api_error.booking.update.conflicting_rental_for_car": "Fahrzeug ist bereits in Miete",

      "api_error.booking.update.conflicting_rental_for_user": "Nutzer ist bereits in Miete",

      "api_error.booking.update.invalid_interval": "Verlängerung nicht möglich, es besteht bereits Folgebuchung.",

      "api_error.carUnavailable": "dieses Auto ist nicht zur Verfügung bitte ein anderes wählen",

      "api_error.not_available": "Diese Aktion kann nicht ausgeführt werden.",

      "api_error.not_available_forPayin": "Diese Aktion kann mit der momentan ausgewählten Zahlungsmethode nicht ausgeführt werden.",

      "api_error.payin_notAllowed": "Bitte wähle eine spätere Startzeit, um fortzufahren",

      "api_error.payment.3ds_required": "Bitte authentifiziere jetzt deine Kreditkarte (Händlername \"GETAWAY\"), um fortzufahren.\n\nDie Authentifizierung löst keine Zahlung aus.",

      "api_error.payment.adyen_3ds_response_not_received": "Die Antwort unseres Zahlungsdienstleisters hat zu lange gedauert. Bitte versuche es erneut.",

      "api_error.payment.adyen_rejected_request": "Zahlungsanfrage wurde abgelehnt.",

      "api_error.payment.adyen_response_not_received": "Zahlungsanfrage ausgelaufen.",

      "api_error.payment.card_validation_attempts_exceeded": "Verifizierung der Zahlungsmethode fehlgeschlagen, bitte kontaktiere den Support",

      "api_error.payment.card_validation_failed": "Verifizierung fehlgeschlagen",

      "api_error.payment.method_not_allowed": "Deine aktive Zahlungsmethode wird in diesem Land derzeit nicht unterstützt, bitte füge eine andere hinzu.",

      "api_error.payment.paypal_validation_attempts_exceeded": "PayPal Verifizierung fehlgeschlagen.",

      "api_error.payment.paypal_validation_failed": "PayPal Verifizierung zu oft fehlgeschlagen. Bitte kontaktiere unser Service Team.",

      "api_error.rental.update.cannot_reduce_back_by_if_pay_as_reserved": "Die Rückgabezeit kann nicht verkürzt werden, da die gesamte gebuchte Dauer berechnet wird.",

      "api_error.rental.update.invalid_back_by": "Falsch eingegebene Zeit",

      "api_error.ride.pooling.no_offer": "Es sind keine Fahrzeuge in Deiner Nähe",

      "api_error.ride_pooling.no_offer": "Es sind keine Fahrzeuge in Deiner Nähe",

      "api_error.ride_pooling.no_offer.has_ongoing_trip": "Du hast bereits eine aktive Fahrt oder Reservierung",

      "api_error.ride_pooling.no_offer.no_seats": "Es gibt keine freien Plätze für diese Fahrt",

      "api_error.ride_pooling.no_offer.resolved_same_stop_for_pickup_and_release": "Einstiegs- und Ausstiegsort sind identisch",

      "api_error.user.email.change_not_allowed": "Diese E-Mail Adresse kann nicht genutzt werden.",

      "api_error.user.iban.duplicate": "Diese IBAN kann nicht registriert werden. Möglicherweise wird sie bereits genutzt. Kontaktiere den Support für weitere Hilfe.",

      "api_error.user.id_submission_attempts_exceeded": "Maximale Anzahl an Versuchen überschritten. Bitte kontaktiere unser Service Team.",

      "api_error.user.identityMismatch": "Diese Person kann nicht zur Bonitätsprüfung herangezogen werden",

      "api_error.user.license_submission_attempts_exceeded": "Maximale Anzahl an Versuchen überschritten. Bitte kontaktiere unser Service Team.",

      "api_error.user.phone_verification.max_attempts": "Maximale Anzahl an Versuchen überschritten. Bitte kontaktiere unser Service Team.",

      "api_error.user.schufaCheck.data_incomplete": "Daten unvollständig",

      "api_error.user.tariff_missing": "Um fortzufahren, wähle  bitte zuerst einen Tarif.",

      "api_error.user.verificationCode.incorrectOrExpired": "Code inkorrekt oder abgelaufen.",

      "api_error.user_not_belong_to_circle_brand": "Kein Mitglied des Nutzerkreises",

      "app.cookies.url": "https://mietsmart.app/cookies",

      "app.old.dialog.description": "Wir haben festgestellt, dass auf deinem Gerät noch die alte GETAWAY-App installiert ist. \n\nBitte klicke auf \"Deinstallieren\", um diese zu entfernen.",

      "app.old.dialog.title": "Deinstalliere die alte App",

      "app.privacy.url": "https://mietsmart.app/privacy",

      "app.terms.url": "https://mietsmart.app/terms",

      "applink.error": "Dieser Link ist nicht mehr aktuell. Bitte kontaktiere unser Service Team für weitere Infos.",

      "apply": "ÜBERNEHMEN",

      "area.brand_not_resolved": "Gewählter Anbieter wurde nicht erkannt.",

      "area.card.info": "Infos zur Zone",

      "area.card.navigate": "Navigieren",

      "area.fleet.brand.not_match": "Gewählte Flotte gehört nicht zum ausgewählten Anbieter.",

      "area.fleet_not_resolved": "Flotte wurde nicht erkannt.",

      "area.label": "Rückgabezone",

      "area.label.outside": "Bitte beachte die Rückgabezone",

      "area.walkthrough.description": "Bringe das Fahrzeug zum Beenden der Miete in die farblich hervorgehobene Rückgabezone mit einem Tankstand von mind. 25% zurück und verriegele es mit deinem Handy.\n\nNeu: Möchtest du, dass wir das Fahrzeug zurückzubringen? Dann wähle am Ende der Miete einfach den bequemen Rückbring-Service iHv. nur 10€ + 0,49€ je 100m.",

      "area.walkthrough.title": "Zurückbringen und Miete beenden",

      "auth.failed.alert.body": "Bitte versuche es erneut oder klicke \"Vergessen?\", um dein Passwort zurückzusetzen.",

      "auth.failed.alert.title": "Falsches Passwort",

      "backend.error": "Ups..etwas ist schief gelaufen. Bitte probiere es gleich noch einmal.",

      "balance": "Guthaben",

      "balance.addFunds": "Guthaben hinzufügen",

      "balance.addFunds.description": "Spare mit unseren Guthabenpaketen",

      "balance.addPaymentMethod": "Zahlungsmittel hinzufügen",

      "balance.delete.confirm": "Möchtest du dieses Zahlungsmittel tatsächlich entfernen?",

      "balance.getFreeCredit": "Erhalte gratis Guthaben",

      "balance.getFreeCredit.description": "Meistere Herausforderungen und erhalte Belohnungen.",

      "balance.invoice.failure": "Entschuldige bitte. Der Beleg konnte nicht versendet werden.",

      "balance.invoice.success": "Dein Beleg wird dir zeitnah per E-Mail gesendet!",

      "balance.paymentMethods": "ZAHLUNGSMITTEL",

      "balance.payoutFunds": "Guthaben auszahlen",

      "balance.payoutFunds.addSEPA.dialog.message": "Bitte füge deine Kontodaten hinzu, sodass wir dir dein Guthaben auszahlen können.",

      "balance.payoutFunds.addSEPA.dialog.title": "Auszahlungsart hinzufügen",

      "balance.payoutFunds.description": "Auszahlbares Guthaben",

      "balance.payoutFunds.dialog.message": "Auszahlbares Guthaben wird deinem Konto am Monatsende automatisch gutgeschrieben.",

      "balance.payoutFunds.dialog.title": "Guthaben auszahlen",

      "balance.payoutFunds.error.message": "Auszahlbares Guthaben wird einmal im Monat automatisch auf dein hinterlegtes Bankonto überwiesen.",

      "balance.send.invoice": "BELEG\SENDEN",

      "balance.transactions": "UMSÄTZE",

      "bluetooth.dialog.description": "Per Bluethooth kannst du Autos in deiner Umgebung einfach per Handy öffnen.",

      "bluetooth.dialog.title": "Bluetooth® aktivieren",

      "bluetooth.disabled.message": "Achtung: Bitte erlaube Zugriff auf dein Bluetooth, um Fahrzeuge ent-/verriegeln zu können.",

      "bluetooth.permission.allow.details": "Achtung: Bitte erlaube Zugriff auf dein Bluetooth, um Fahrzeuge ent-/verriegeln zu können.",

      "bluetooth.permission.allow.title": "Bluetooth Freigabe",

      "bluetooth.permission.details": "Zur sicheren Handy/Auto-Interaktion aktiviere jetzt die Bluetooth-Funktion im Control Center deines Handys.",

      "bluetooth.permission.message": "Achtung: Bitte erlaube Zugriff auf dein Bluetooth, um Fahrzeuge ent-/verriegeln zu können.",

      "bluetooth.permission.title": "Aktiviere Bluethooth®",

      "bluetooth.permission.yes": "Erlauben",

      "bonus.sheet.description.lowAndOut": "Der Tankstand des Fahrzeugs muss bei Rückgabe mindestens 25% betragen.\n\nErhalte 5 € Sofort-Gutschrift, wenn du die Miete in der Rückgabezone beendest und weitere 5 €, wenn du das Fahrzeug betankst.",

      "bonus.sheet.description.lowFuel": "Der Tankstand des Fahrzeugs muss bei Rückgabe mindestens 25% betragen.\n\nErhalte eine 5€ Sofort-Gutschrift als Dank, wenn du das Fahrzeug volltankst. Die Bezahlung ist kostenfrei und bargeldlos mit der bereitgestellten Tankkarte im Handschuhfach. ",

      "bonus.sheet.description.out&low": "Der Tankstand des Fahrzeugs muss bei Rückgabe mindestens 25% betragen und das Beenden der Miete muss in der Rückgabezone erfolgen\n\nErhalte eine 5€ Sofort-Gutschrift, wenn du den Mietvorgang in der Rückgabezone des Fahrzeugs beendest.\n\nErhalte weitere 5€, wenn du das Fahrzeug volltankst. Die Bezahlung ist kostenfrei und bargeldlos mit der bereitgestellten Tankkarte im Handschuhfach.",

      "bonus.sheet.description.outsideArea": "Das Beenden der Miete muss in der Rückgabezone erfolgen.\n\nErhalte eine 5€ Sofort-Gutschrift als Dank, wenn du den Mietvorgang in der Rückgabezone des Fahrzeugs beendest.",

      "bonus.sheet.ok": "Ok",

      "booking.BookedCarIsBLOCKED": "Fahrzeug war nicht verfügbar",

      "booking.BookedCarIsRELEASE_READY": "Fahrzeug war nicht verfügbar",

      "booking.BookedCarIsRENTED": "Fahrzeug war nicht verfügbar",

      "booking.BookedCarIsUnavailable": "Fahrzeug war nicht verfügbar",

      "booking.BookingCancelled": "Reservierung wurde storniert",

      "booking.DriverHasOngoingRental": "Es lief bereits eine Miete",

      "booking.OtherReason": "technische Probleme",

      "booking.OtherRentalConstraintViolation": "Fahrzeug war nicht verfügbar",

      "booking.RentalCancelled": "Miete wurde storniert",

      "booking.RentalExpired": "Reservierung abgelaufen",

      "booking.accessToken": "technische Probleme",

      "booking.backByConflictsWithBooking": "technische Probleme",

      "booking.backByExpired": "technische Probleme",

      "booking.blockedByUpcomingBooking": "technische Probleme",

      "booking.blockedPaymentConfirmed": "Zahlung fehlgeschlagen",

      "booking.button.text": "Auf Karte anzeigen",

      "booking.cancel.fee.footnote": "Danach fällt eine Stornierungsgebühr iHv. bis zu %s an. Diese Gebühr wird erlassen, sofern bis %s ein alternativer Mietvorgang gestartet wird.",

      "booking.cancellation.text": "✓ Gratis stornierbar bis %s",

      "booking.cancelled.at": "Reservierung storniert um %s",

      "booking.car.unavailable": "Das Fahrzeug ist nicht verfügbar",

      "booking.confirm.cost": "Initiale Kosten",

      "booking.confirm.from": "Von",

      "booking.confirm.payin.text": "Direkt im Anschluss an die Reservierung ist das Hinterlegen einer Kaution fällig. Es wird hierfür ein Zahlungs-Link zur Verfügung gestellt.                                               \n                                                            \nMöchtest du das nicht, dann wähle eine alternative Zahlungsart.",

      "booking.confirm.payin.title": "Kaution",

      "booking.confirm.profile": "Fahrzeug",

      "booking.confirm.to": "Bis",

      "booking.confirmation.button": "Jetzt reservieren",

      "booking.confirmation.title": "Prüfe die Reservierung",

      "booking.conformation.no.address": "Abholzone anzeigen",

      "booking.consent.button": "Reservierung bestätigen",

      "booking.consent.cancel": "Ablehnen",

      "booking.consent.cancel.dialog.message": "Wenn du die Reservierungsanfrage ablehnst, wird diese storniert. Das Fahrzeug kann dann von anderen Nutzern gemietet werden.",

      "booking.consent.cancel.dialog.title": "Bitte bestätige deine Auswahl",

      "booking.consent.cancel.dialog.yes": "Ablehnen & stornieren",

      "booking.consent.circle": "Nutzerkreis",

      "booking.consent.later.dialog.message": "Prüfe und bestätige die Reservierungsanfrage rechtzeitig. Andernfalls wird diese automatisch storniert.",

      "booking.consent.later.dialog.yes": "Ok",

      "booking.cost.footnote": "Zusätzliche Entgelte können anfallen. Mehr Infos sind dem %s zu entnehmen.\n\nBitte beachte bei geplanten Buchungen, dass für die Berechnung der Mietkosten auch bei einer vorzeitigen Rückgabe der gesamte ursprünglich reservierte Zeitraum herangezogen werden kann.",

      "booking.cost.footnote.url": "https://google.com",

      "booking.cost.footnote.url.text": "Fahrzeugprofil",

      "booking.cost.includedkm": "Inklusivkilometer",

      "booking.create": "GETAWAY finden",

      "booking.create.success": "Danke! Wir haben deine Anfrage erhalten und suchen jetzt einen passenden GETAWAY für dich.",

      "booking.date.fake.url": "https://googles.com",

      "booking.deposit.footnote": "Mit der Reservierung wird eine initiale Kaution iHv. %s fällig. Übersteigen die Mietkosten die hinterlegte Kaution, werden weitere Kautionszahlungen fällig. Bitte sorge stets für ausreichend Deckung.\n\nIm Anschluss an den Mietvorgang wird die Gesamtkaution nach Verrechnung mit den Mietkosten unter Vorbehalt weiterer möglicher Forderungen erstattet.",

      "booking.deposit.title": "Kaution",

      "booking.desktop.start": "Miete Starten",

      "booking.details.header.descr": "Sobald die Reservierung beginnt, hast du 30 Minuten Zeit, das Fahrzeug mit deinem Handy zu entriegeln.",

      "booking.details.section.title": "Deine Reservierung",

      "booking.driver.accepted.title": "gute Fahrt mit deinem GETAWAY!",

      "booking.driver.cancel": "Reservierung stornieren",

      "booking.driver.cancel.dialog.message": "Ist eine Kaution hinterlegt worden, wird diese unmittelbar im Anschluss an die Stornierung erstattet.",

      "booking.driver.cancel.dialog.title": "Reservierung stornieren",

      "booking.driver.cancel.dialog.yes": "Jetzt stornieren",

      "booking.driver.cancel.success": "Deine Reservierung ist storniert worden. Ist eine Kaution hinterlegt worden, wird diese automatisch erstattet.",

      "booking.driver.confirm.dialog.message": "Gute Wahl! Mit deiner Bestätigung wird dieser GETAWAY für dich reserviert.",

      "booking.driver.confirm.dialog.title": "Diesen GETAWAY reservieren",

      "booking.driver.confirm.dialog.yes": "Jetzt reservieren",

      "booking.driver.details.description": "Sobald wir passende Autos für dich gefunden haben, schicken wir dir die Auswahl.",

      "booking.driver.details.header.description": "Bitte sei nachsichtig. Dieses Feature befindet sich in der Testphase. Wir freuen uns über dein Feedback!",

      "booking.driver.details.header.title": "Finde deinen perfekten GETAWAY! [BETA]",

      "booking.driver.details.text": "Die Preise basieren auf der genannten Dauer und Gesamtstrecke inkl. Kraftstoff. Der Endpreis berechnet sich nach tatsächlicher Dauer und zurückgelegter Strecke.",

      "booking.driver.details.title": "Deine Reservierung",

      "booking.driver.nooffers": "✨\nVerfügbare GETAWAYs\nwerden dir hier angezeigt.",

      "booking.driver.offer.choose": "Auswählen",

      "booking.driver.offer.request": "Anfragen",

      "booking.driver.offer.reserve": "Reservieren",

      "booking.driver.offer.reserving": "Reservierung läuft...",

      "booking.driver.offered.title": "wähle deinen perfekten GETAWAY!",

      "booking.driver.waitingoffer": "Angebot warten",

      "booking.early.start": "Reservierung früher starten",

      "booking.end": "Bis",

      "booking.fee.fake.url": "https://google.com",

      "booking.fee.title": "Gratis stornierbar bis",

      "booking.fee.value": "25€",

      "booking.free.cancellation.text": "✓ Gratis stornierbar ",

      "booking.fuelCardMissing": "technische Probleme",

      "booking.guest.confirmation.button": "Jetzt anmelden",

      "booking.haveBlockedPaymentIfPayInOnly": "bitte wählest du den Zeitrahmen für die  Reservierung.",

      "booking.header.in": "Noch",

      "booking.headline.expired": "Reservierung ist abgelaufen",

      "booking.headline.payment": "Zahlungsdaten",

      "booking.headline.reservation": "Reservierungsdaten",

      "booking.headline.started": "Reservierung hat begonnen",

      "booking.label.checked": "Zurückgenommen",

      "booking.label.expired": "Abgelaufen",

      "booking.label.started": "Gestartet",

      "booking.label.waiting.checkin": "in Rückgabe",

      "booking.map.bar.text": "Nächste Reservierung:",

      "booking.map.pre.signup": "Klicke hier um deine Buchung abzuschließen.",

      "booking.mileage.placeholder": "Ich weiß noch nicht",

      "booking.mileage.title": "Gesamtstrecke (circa)",

      "booking.online": "technische Probleme",

      "booking.payByLink.description": "Schließe bitte jetzt die Zahlung ab und kehre im Anschluss zu deinem Nutzerkonto zurück.",

      "booking.payin.blockPayment.description": "Hinterlege jetzt eine Kaution iHv. 50€, um die Reservierung abzuschließen.Übersteigen die Mietkosten den Kautionsbetrag, können weitere Zahlungen fällig werden.\n\nIm Anschluss an den Mietvorgang wird die Kaution nach Verrechnung mit den Mietkosten unter Vorbehalt weiterer möglicher Forderungen wieder freigegeben.",

      "booking.payin.blockPayment.title": "Kaution bei Vorkasse-Zahlung",

      "booking.payin.blockPayment.yes.text": "Weiter",

      "booking.payment": "Zahlungsart",

      "booking.payment.change": "Zahlungsmethode wechseln",

      "booking.payment.payIn.title": "Vorkasse",

      "booking.places.current": "Aktuelle Position",

      "booking.places.search": "Adresse eingeben",

      "booking.places.title": "Von wo",

      "booking.pre.signup.clear": "Löschen",

      "booking.preferredcar.title": "Auto",

      "booking.requestedCar.title": "Fahrzeug",

      "booking.start": "Von",

      "booking.start.address": "Von wo",

      "booking.userMarkedForFraud": "Zahlung fehlgeschlagen",

      "booking.warning.payin.button": "Jetzt Kaution hinterlegen",

      "booking.warning.payin.text": "Hinterlege jetzt die Kaution, um die Reservierung abzuschließen. Andernfalls wird sie in wenigen Minuten storniert.",

      "booking.warning.payin.text.payed": "Du hast die Kaution erfolgreich hinterlegt. Bitte warte nun auf den Beginn deiner Miete.",

      "booking.warning.payin.title": "Kaution",

      "booking.warning.payin.title.payed": "Gut gemacht!",

      "booking.warning.payin.value": "150,00 Euro",

      "bookings.headline.booked": "Gebucht",

      "bookings.headline.reservation": "Reserviert",

      "bookings.profile.cancel.text": "Reservierung stornieren",

      "bookings.profile.title": "Meine Reservierungen",

      "bookings.where.desc": "Der genaue Standort des Fahrzeugs wird 30 Minuten vor Buchungsbeginn angezeigt.",

      "brand.bnd.name": "book-n-drive",

      "brand.getaway.name": "GETAWAY",

      "brand.mba.name": "Mietbar",

      "brand.mis.name": "Miet Smart",

      "brand.msi.name": "Miet Smart",

      "brand.rw.name": "Share die Robbe",

      "brand.ubq.name": "ubeeqo",

      "business": "geschäftlich",

      "call": "Anrufen",

      "callout.show.availability": "Verfügbarkeit anzeigen",

      "camera.addressProof.upload": "hochladen",

      "camera.permission.details": "Um fortzufahren und Fotos aufzunehmen, erlaube GETAWAY jetzt Zugriff auf deine Kamera.",

      "camera.permission.no": "Später",

      "camera.permission.title": "Zugriff auf Kamera",

      "camera.permission.yes": "Erlauben",

      "cancel": "Abbrechen",

      "cancel.FirstSteps.dialog.message": "Nur 2 Minuten: Schließe jetzt die Registrierung ab und starte sofort deine erste Miete.",

      "cancel.FirstSteps.dialog.message.noBonus": "Nur 2 Minuten: Schließe jetzt die Registrierung ab und starte sofort deine erste Miete.",

      "cancel.FirstSteps.dialog.no": "Abbrechen",

      "cancel.FirstSteps.dialog.yes": "Jetzt nicht",

      "cancel.rental.handover": "Möchtest du den aktuellen Mietvorgang abbrechen?",

      "car.approximateMileage.gt250k": "über 250.000 km",

      "car.approximateMileage.lt100k": "50.000 - 100.000 km",

      "car.approximateMileage.lt150k": "100.000 - 150.000 km",

      "car.approximateMileage.lt200k": "150.000 - 200.000 km",

      "car.approximateMileage.lt250k": "200.000 - 250.000 km",

      "car.approximateMileage.lt50k": "unter 50.000 km",

      "car.available.book": "BUCHEN",

      "car.co2.text": "Kg eingespartes CO2",

      "car.damage": "Schaden",

      "car.damage.create.dialog.title": "Schaden oder Mangel melden",

      "car.damage.edit.notallowed.description": "Während einer laufenden Miete kann das Schadenprotokoll nicht geändert werden.",

      "car.damage.edit.notallowed.title": "Änderungen nicht möglich",

      "car.damage.remove.dialog.remove": "Schaden entfernen",

      "car.damage.remove.dialog.title": "Bist du sicher?",

      "car.damages": "Schäden",

      "car.damages.photos.dialog.description": "Füge jetzt bitte mindestens ein Foto von diesem Schaden/Mangel hinzu, um diesen besser nachvollziehbar zu machen.",

      "car.damages.photos.dialog.title": "Fotos vom Schaden",

      "car.data.title": "Stammdaten",

      "car.demo.unavailable.messege": "Bitte öffne den Appstore und aktualisiere jetzt deine App-Version, um dieses Auto zu mieten.",

      "car.demo.unavailable.title": "Miete nicht möglich",

      "car.details.available.until.placeholder": "Verfügbar bis",

      "car.details.car.info": "AUTOPROFIL",

      "car.details.carplate.placeholder": "Kennzeichen",

      "car.details.damages": "Schäden",

      "car.details.equipment": "AUSSTATTUNG",

      "car.details.fuel.diesel": "Diesel",

      "car.details.fuel.electric": "Elektrisch",

      "car.details.fuel.hybrid": "Hybrid",

      "car.details.fuel.hybrid_diesel": "Hybrid/Diesel",

      "car.details.fuel.hybrid_unleaded95": "Hybrid/Benzin 95",

      "car.details.fuel.hybrid_unleaded98": "Hybrid/Benzin 98",

      "car.details.fuel.lpg": "LPG",

      "car.details.fuel.other": "Anderer",

      "car.details.fuel.placeholder": "Kraftstoff",

      "car.details.fuel.unleaded95": "Benzin 95",

      "car.details.fuel.unleaded98": "Benzin 98",

      "car.details.gear.automatic": "Automatik",

      "car.details.gear.manual": "Manuell",

      "car.details.gear.type.placeholder": "Getriebeart",

      "car.details.height": "Ladehöhe",

      "car.details.initial.registration.placeholder": "Erstzulassung",

      "car.details.length": "Ladelänge",

      "car.details.list.damages.placeholder": "Gemeldete Schäden",

      "car.details.listofdamages.title": "SCHADENSLISTE",

      "car.details.loadCapacity": "Ladegewicht",

      "car.details.lock.text": "VERRIEGELN",

      "car.details.mileage.gt100": "Mehr als 100.000 km",

      "car.details.mileage.lt100": "Weniger als 100.000 km",

      "car.details.mileage.lt20": "Weniger als 20.000 km",

      "car.details.mileage.lt50": "Weniger als 50.000 km",

      "car.details.mileage.placeholder": "Kilometerstand",

      "car.details.pickupreturn.placeholder": "Standort",

      "car.details.pollution.placeholder": "Umweltplakette",

      "car.details.pos.reviews": "Positive Bewertungen",

      "car.details.reviews": "Bewertungen",

      "car.details.techincaldata": "Technische Daten",

      "car.details.uhr": "Uhr",

      "car.details.unlock.text": "Miete starten",

      "car.details.unlock.text.brand": "Miete starten",

      "car.details.unlocking.text": "Entriegeln...",

      "car.details.width": "Ladebreite",

      "car.device.doors.open": "Bitte stelle sicher, dass alle Türen verschlossen sind.",

      "car.device.fuel.card.missing": "Bitte lege die Tankkarte in die dafür vorgesehene Halterung im Handschuhfach.",

      "car.device.keys.missing": "Bitte stelle sicher, dass der Autoschlüssel in der dafür vorgesehenen Halterung im Handschuhfach liegt.",

      "car.device.windows.open": "Bitte stelle sicher, dass alle Fenster verschlossen sind.",

      "car.equipment.abs": "ABS",

      "car.equipment.adaptiveLighting": "Kurvenlicht",

      "car.equipment.airConditioning": "Klimaanlage",

      "car.equipment.alloyWheels": "Leichtmetallfelgen",

      "car.equipment.auxiliaryHeating": "Standheizung",

      "car.equipment.childSeat": "Kindersitz",

      "car.equipment.collisionAvoidanceSystem": "Notbremsassistent",

      "car.equipment.cruiseControl": "Tempomat",

      "car.equipment.disabledAccessible": "Behindertengerecht",

      "car.equipment.distanceControlAssistSystem": "Abstandstempomat",

      "car.equipment.electricHeatedSeats": "Sitzheizung",

      "car.equipment.esp": "ESP",

      "car.equipment.feature": "Extras",

      "car.equipment.fourWheelDrive": "Allradantrieb",

      "car.equipment.handsFreeKit": "Freisprechanlage",

      "car.equipment.interior": "Innenausstattung",

      "car.equipment.laneDepartureWarningSystem": "Spurhalteassistent",

      "car.equipment.multifunctionalWheel": "Multifunktionslenkrad",

      "car.equipment.navigationSystem": "Navigationssystem",

      "car.equipment.panoramicRoof": "Panorama-Dach",

      "car.equipment.parkingAssistSystem": "Einparkhilfe",

      "car.equipment.powerAssistedSteering": "Servolenkung",

      "car.equipment.rainSensor": "Regensensor",

      "car.equipment.roofBars": "Dachgepäckträger",

      "car.equipment.safety": "Sicherheit",

      "car.equipment.startStopSystem": "Start/Stopp-Automatik",

      "car.equipment.sunroof": "Schiebedach",

      "car.equipment.tractionControl": "Traktionskontrolle",

      "car.equipment.trailerCoupling": "Anhängerkupplung",

      "car.feedback.text": "liebe dein Auto",

      "car.findNearest.notExist": "Kein verfügbares Fahrzeug. Versuche einen anderen Zeitraum oder vergrößere den Kartenausschnitt.",

      "car.handover.failed.description.unlock": "Entriegele das Auto und starte den Vorgang erneut.",

      "car.handover.failed.title": "Vorgang fehlgeschlagen",

      "car.handover.manual.fuelLevel.description": "Niemand möchte ein Auto mit fast leerem Tank hinterlassen bekommen. Bitte bestätige, dass das der Tankstand mindestens ca. 25% beträgt.",

      "car.handover.manual.fuelLevel.title": "Tankstand ausreichend?",

      "car.handover.nodamages": "Keine gemeldeten Schäden",

      "car.insurance": "Versicherungsschutz",

      "car.owner.dialog.rented.message": "Zum Schutz der Privatsphäre des Mieters ist die aktuelle Position deines Autos verschlüsselt. Wir benachrichtigen dich im Anschluss des Mietvorgangs.",

      "car.owner.dialog.rented.title": "Aktuell vermietet",

      "car.pollution.green": "Grün",

      "car.pollution.nobadge": "Keine",

      "car.pollution.red": "Rot",

      "car.pollution.unknown": "Unbekannt",

      "car.pollution.yellow": "Gelb",

      "car.price": "%@ € pro km",

      "car.price.desc": "Lege fest, was du pro Kilometer verdienen möchtest. Du kannst diesen Wert jederzeit anpassen.",

      "car.price.footer": "*Hierbei handelt es sich um eine unverbindliche Schätzung. Letztlich hängen die tatsächlichen Einnahmen von der Verfügbarkeit und Begehrtheit deines Autos ab.",

      "car.price.high": "Zu teuer",

      "car.price.low": "Zu günstig",

      "car.price.title": "Verdienst pro Kilometer",

      "car.price.update": "SPEICHERN",

      "car.profile.area.note": "Hinweise zur Zone",

      "car.profile.area.openinghrs": "Zugang",

      "car.profile.area.title": "Abhol-/Rückgabezone",

      "car.profile.blockReasons.label": "Downtime-Grund:",

      "car.profile.brand": "Marke",

      "car.profile.damages": "Schäden & Mängel",

      "car.profile.description": "Bearbeite dein Autoprofil",

      "car.profile.dimensions.note": "Maße können abweichen. Wenn es knapp werden könnte, wähle zur Sicherheit eine größere Fahrzeugkategorie.",

      "car.profile.engine": "Motorleistung",

      "car.profile.equipment": "Ausstattung",

      "car.profile.history.date.interval.to": "bis",

      "car.profile.instructions": "Die besten Tipps, um deine Mieteinnahmen zu erhöhen, findest du in unserem %@.",

      "car.profile.instructions.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#mein-autoprofil",

      "car.profile.instructions.url.text": "HilfeCenter",

      "car.profile.lastCleaning.label": "Letzte Reinigung:",

      "car.profile.model": "Modell",

      "car.profile.price.details": " https://robbe.app/feetable",

      "car.profile.price.message": "Circa %@ € pro Monat*",

      "car.profile.price.per.km": "Dein Verdienst pro km",

      "car.profile.rental.description": "Liste aller Mietvorgänge",

      "car.profile.rental.history": "MIETVORGÄNGE",

      "car.profile.rented.back": "Rückkehr spät.",

      "car.profile.terminate": "AUTO ENTFERNEN",

      "car.profile.view": "Autoprofil",

      "car.registration.camera.description": "Positioniere die aufgeklappte Kfz-Schein Vorderseite innerhalb der Markierung.",

      "car.registration.photo.description": "(vollständig aufgeklappt)",

      "car.registration.photo.title": "Kfz-Schein Vorderseite",

      "car.release.ignition.message": "Du kannst dein Auto nur freigeben, wenn der Motor aus ist.",

      "car.release.ignition.title": "Freigabe nicht möglich",

      "car.released": "Dein Auto ist freigegeben worden",

      "car.released.error": "Bitte probiere es später erneut",

      "car.rental.cancelled": "Deine Reservierung ist storniert worden. Ist eine Kaution hinterlegt worden, wird diese automatisch erstattet.",

      "car.rental.cancelled.time": "Deine Buchung ist storniert worden. Du hast das Auto nicht rechtzeitig geöffnet.",

      "car.rental.count": "Mietvorgänge",

      "car.rental.rental": "Mietvorgang",

      "car.rental.rentals": "Mietvorgänge",

      "car.rental.trips": "FAHRTEN",

      "car.rentals.title": "Mietvorgänge",

      "car.rented.back": "Zurück am",

      "car.rented.backtoday": "Zurück um",

      "car.rented.backtomorrow": "Morgen zurück um",

      "car.reserve.blockPayment.description": "Mit der Reservierung werden initial 150,-€ auf deiner Kreditkarte geblockt. Übersteigen die Mietkosten den Kautionsbetrag, können weitere Kautionszahlungen fällig werden. Bitte sorge stets für ausreichend Deckung.\n\nIm Anschluss an den Mietvorgang wird die Kaution nach Verrechnung mit den Mietkosten unter Vorbehalt weiterer möglicher Forderungen wieder freigegeben.",

      "car.reserve.blockPayment.paypal.description": "Mit der Reservierung werden initial 150,-€ auf deinem Paypal-Konto geblockt. Übersteigen die Mietkosten den Kautionsbetrag, können weitere Zahlungen fällig werden. Bitte sorge stets für ausreichend Deckung. Im Anschluss an den Mietvorgang wird die Kaution nach Verrechnung mit den Mietkosten unter Vorbehalt weiterer möglicher Forderungen wieder freigegeben.",

      "car.reserve.blockPayment.paypal.title": "Kaution bei Zahlung via PayPal",

      "car.reserve.blockPayment.paypal.yes": "Fortfahren",

      "car.reserve.blockPayment.title": "Kaution bei Kreditkartenzahlung",

      "car.reserve.blockPayment.yes.text": "Fortfahren",

      "car.reserve.carBooked.description": "Dieses Fahrzeug hat eine Folgemiete und muss bis zu diesem Zeitpunkt zurückgebracht werden. Andernfalls können Gebühren anfallen.",

      "car.reserve.carBooked.title": "Fahrzeugrückgabe in %s.",

      "car.reserve.carBooked.yes.text": "Zustimmen",

      "car.review.title": "Erfahrungen",

      "car.search.notExist": "Dieses Fahrzeug existiert nicht.",

      "car.service.count": "Service-Fahrten",

      "car.status.not.found": "Autostatus kann nicht gelesen werden.",

      "car.status.not.required": "Nicht verfügbar",

      "car.submit.approximateMileage": "Ungefährer Kilometerstand",

      "car.submit.footer": "Das Auto  ist verkehrstüchtig mit gültiger Haftpflichtversicherung und Hauptuntersuchung.",

      "car.submit.leased": "Ich bin Leasingnehmer",

      "car.submit.legalnotice": "Ich stimme einer etwaigen Datenprüfung zur Zahlungswahrscheinlichkeit zu und bestätige, dass das Auto verkehrstüchtig ist und über eine gültige Haftpflichtversicherung wie auch einer Hauptuntersuchung verfügt.",

      "car.submit.legalnotice.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",

      "car.submit.legalnotice.url.text": "Datenprüfung",

      "car.submit.notowner": "Ich bin nicht Halter/Eigentümer",

      "car.submit.owner": "Ich bin Halter/Eigentümer",

      "car.submit.ownership": "Wem gehört das Auto?",

      "car.submit.ownership.description": "Du kannst das Auto problemlos anmelden, auch wenn du selbst nicht Halter/Eigentümer bist. Mehr Infos folgen in der Anmeldebestätigung.",

      "car.terminate.dialog.message": "Bitte kontaktiere unser Service Team, um dein Autoprofil entfernen zu lassen.",

      "car.terminate.dialog.title": "Autoprofil entfernen",

      "car.title": "DEIN AUTO",

      "car.transaction.title": "Mieteinnahme %@",

      "car.unlock.bluetooth.disabled": "Bluetooth® aktivieren \nzum Entriegeln",

      "car.unlock.bluetooth.disabled.brand": "Bluetooth® aktivieren",

      "car.unlock.bluetooth.searching": "Distanz verringern\nzum Entriegeln",

      "car.unlock.bluetooth.searching.brand": "Distanz verringern",

      "car.unlock.closeToCar.action": "Ich stehe am Fahrzeug - jetzt entriegeln",

      "car.unlock.locationpermission.dialog.message": "Bitte erteile über die Browsereinstellungen die Standortberechtigung, um das Auto aufzuschließen.",

      "car.unlock.locationpermission.dialog.title": "standortermittlung ist deaktiviert",

      "car.unlock.tooFar.message": "Bitte probiere es erneut aus geringerer Distanz. Sobald sich dein Handy mit dem Fahrzeug verbunden hat, kannst du es mit der App entriegeln.\n\nBei Problemen kontaktiere bitte unser Service Team über das Chat-Symbol oben rechts im Bildschirm.",

      "car.unlock.tooFar.title": "Bitte verringere die Distanz",

      "car.unreleased": "Dein Auto ist nicht mehr freigegeben",

      "car.unreleased.error": "Dein Auto kann nicht freigegeben werden. Bitte probiere es später erneut.",

      "carCheck.addImage.labelFour": "Hinten, links",

      "carCheck.addImage.labelOne": "Vorne, links",

      "carCheck.addImage.labelThree": "Hinten, rechts",

      "carCheck.addImage.labelTwo": "Vorne, rechts",

      "card.number": "Kreditkartennummer",

      "carprofile.footnote": "Die besten Tipps, um deine Mieteinnahmen zu erhöhen, findest du in unserem %@.",

      "carprofile.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#mein-autoprofil",

      "carprofile.footnote.url.text": "HilfeCenter",

      "carprofile.troubleshoot.description": "Der Motor deines Autos springt nicht an? Hier kannst du die Motorsperre entriegeln.",

      "carprofile.troubleshoot.title": "Motorsperre verwalten",

      "cars.carousel.title": "Finde deinen perfekten GETAWAY!",

      "challenges.completed.section": "GEMEISTERTE HERAUSFORDERUNGEN",

      "challenges.firstSteps.addPayment.text": "Zur Verifizierung deines Nutzerkontos",

      "challenges.firstSteps.addPayment.title": "Hinterlege eine Zahlungsmethode",

      "challenges.firstSteps.bonusTransactionReason": "Erste Schritte",

      "challenges.firstSteps.createAccount.text": "Großartig, du hast dich erfolgreich registriert",

      "challenges.firstSteps.createAccount.title": "Erstelle ein Nutzerkonto",

      "challenges.firstSteps.description": "Du bist kurz davor, die Straße zu erobern. Erledige schnell die ersten Schritte und erhalte sofort %s € Startguthaben!",

      "challenges.firstSteps.firstRental.text": "Mache deinen ersten Ausflug mit GETAWAY",

      "challenges.firstSteps.firstRental.title": "Miete ein Auto",

      "challenges.firstSteps.listCar.text": "Lass dein Auto Geld für dich verdienen!",

      "challenges.firstSteps.listCar.title": "Registriere dein Auto",

      "challenges.firstSteps.saveAddress.text": "Schreibe uns, wo du wohnst",

      "challenges.firstSteps.saveAddress.title": "Hinterlege deine Meldeadresse",

      "challenges.firstSteps.selectTariff.title": "Tarif auswählen",

      "challenges.firstSteps.title": "Erste Schritte",

      "challenges.firstSteps.verifyDriversLicense.text": "Um Zugang zum Fuhrpark zu erhalten",

      "challenges.firstSteps.verifyDriversLicense.title": "Bestätige deine Fahrerlaubnis",

      "challenges.firstSteps.verifyEmail.text": "Um deine E-Mail Adresse zu verifizieren",

      "challenges.firstSteps.verifyEmail.title": "Verifiziere deine E-Mail Adresse.",

      "challenges.firstSteps.verifyIDDocument.text": "Um Zugang zur Fahrzeugflotte zu erhalten.",

      "challenges.firstSteps.verifyIDDocument.title": "Identitäts-Dokument verifizieren",

      "challenges.firstSteps.verifyPhoneNumber.text": "Um dein Handy zum Autoschlüssel zu machen",

      "challenges.firstSteps.verifyPhoneNumber.title": "Bestätige deine Handy-Nummer",

      "challenges.inviteFriends.bonusTransactionReason": "Eingeladener Freund",

      "challenges.inviteFriends.description": "Schenke deinen Freunden und Nachbarn einen 10€ Willkommensbonus und erhalte selbst 10€, wenn sie diesen einlösen.",

      "challenges.inviteFriends.title": "Lade 5 Freunde ein",

      "challenges.up.to": "Bis zu",

      "change": "Ändern",

      "change.password.success": "Dein Passwort ist erfolgreich aktualisiert worden.",

      "circle.brand_not_resolved": "Nutzerkreis nicht erkannt",

      "circles.button.text": "ÜBERNEHMEN",

      "circles.input.placeholder": "Einladungscode eingeben",

      "circles.join.success.msg": "Du hast jetzt besonderen Zugang zu Fahrzeugen und Preisen!",

      "circles.join.success.title": "Du bist dem Nutzerkreis beigetreten.",

      "clear.damages": "Schäden zurücksetzen",

      "clear.damages.prompt.text": "Bist du sicher?",

      "clipboard.link.copied": "Dein Einladungslink ist in deine Zwischenablage kopiert worden.",

      "closed": "geschlossen",

      "confirm": "Bestätigen",

      "consents.consent": "Einwilligung",

      "consents.details": "Details",

      "consents.newsletter.title": "Newsletter",

      "consents.privacy.policy.title": "Datenschutzerklärung",

      "consents.schufa.desc": "Ich willige der Verarbeitung meiner Daten zur [Prüfung der Zahlungswahrscheinlichkeit](https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_smp/WL_DataPrivacy-DATA_Checks-de+02_2024.pdf) ein.",

      "consents.schufa.title": "Bonitätscheck",

      "consents.title": "Meine Einwilligungen",

      "contacts.invite.contact": "LADE %d KONTAKT EIN",

      "contacts.invite.contact.many": "LADE %d KONTAKTE EIN",

      "contacts.invite.earn": "Verdiene bis zu %@",

      "contacts.permission.details": "Um fortzufahren und Kontakte auszuwählen, erlaube GETAWAY jetzt Zugang zu deinen Kontakten.",

      "contacts.permission.no": "später",

      "contacts.permission.title": "Zugriff auf Kontakte",

      "contacts.permission.yes": "Erlauben",

      "continue": "Fortfahren",

      "creditcheck.terms": "Mit der Auswahl des SEPA-Lastschriftverfahrens willige ich der Verarbeitung meiner Daten zur %s ein.",

      "creditcheck.terms.highlight": "Prüfung der Zahlungswahrscheinlichkeit",

      "creditcheck.terms.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/WL_DataPrivacy-DATA_Checks-de.pdf",

      "custom.camera.description.car.registration": "Bitte fotografiere deinen Fahrzeugschein auf einer neutralen Oberfläche und stelle sicher, dass er innerhalb des Rahmens ist.",

      "custom.camera.description.damage": "Bitte achte darauf, dass der Schaden gut zu erkennen und in der Mitte des Photos ist.",

      "custom.camera.description.license": "Bitte lege deinen Führerschein auf eine neutrale Oberfläche innerhalb der Markierung und nimm ein Foto auf.",

      "custom.camera.description.selfie": "Bitte halte den Aufnahme-Button für 3 Sekunden gedrückt.",

      "custom.camera.holdmessage.selfie": "Halte den Aufnahme-Button für 3 Sekunden gedrückt, um dein Selfie aufzunehmen.",

      "custom.camera.repeat": "Wiederholen",

      "custom.camera.submit": "Übernehmen",

      "custom.camera.title.car.registration": "Fahrzeugschein",

      "custom.camera.title.damage": "Schadenaufnahme",

      "custom.camera.title.license.back": "Führerschein-Rückseite",

      "custom.camera.title.license.front": "Führerschein-Vorderseite",

      "custom.camera.title.selfie": "Selfie",

      "customerSupport.number": "<Nummer des MIS Support>",

      "damage.add.noImages.error": "Bitte stelle sicher, dass du mindestens zwei Bilder hinzugefügt hast.",

      "damage.add.success": "Vielen Dank für deine Schadensmeldung!",

      "damage.detials.reportedon": "Gemeldet am:",

      "damage.new": "NEU",

      "damage.new.notification.description": "Vielen Dank für die Aktualisierung des Schadenprotokolls! Du hast nun die Möglichkeit, die Vermieterin anzurufen.",

      "damages.add": "Schaden melden",

      "damages.add.button.text": "Schaden hinzufügen",

      "damages.add.error.noimages": "Bitte füge mind. ein Foto des Schadens hinzu, um fortzufahren.",

      "damages.add.image.note": "Mindestens zwei Fotos (unterschiedliche Blickwinkel/ Abstände). Auf einem Foto muss das Kennzeichen zu erkennen sein.",

      "damages.bumper": "Stoßstange",

      "damages.burn": "Brandfleck",

      "damages.carRoadworthy": "Ist das Fahrzeug verkehrstüchtig?",

      "damages.characteristic": "Eigenschaften",

      "damages.characteristic.freeText": "anderes",

      "damages.characteristics.burn": "Brandfleck",

      "damages.characteristics.crack": "Riss",

      "damages.characteristics.deepScratch": "Kratzer (bis zur Grundierung)",

      "damages.characteristics.deepscratch": "Kratzer (bis zur Grundierung)",

      "damages.characteristics.dent": "Delle",

      "damages.characteristics.dirt": "Verschmutzung",

      "damages.characteristics.missing": "Nicht vorhanden",

      "damages.characteristics.other": "Anderes",

      "damages.characteristics.permanentDirt": "Dauerhafte Verschmutzung",

      "damages.characteristics.scratch": "Kratzer (oberflächlich)",

      "damages.characteristics.stoneChip": "Steinschlag",

      "damages.chars.left": "verbleibende Zeichen",

      "damages.component": "Bauteil",

      "damages.component.bumper": "Stoßstange",

      "damages.component.door": "Tür",

      "damages.component.engineBonnet": "Motorhaube",

      "damages.component.exteriorMirror": "Spiegel",

      "damages.component.floor": "Boden",

      "damages.component.hubcap": "Radkappe",

      "damages.component.interiorTrim": "Innenverkleidung",

      "damages.component.other": "Anderes",

      "damages.component.rearWindow": "Fenster",

      "damages.component.seat": "Sitz",

      "damages.component.sill": "Schweller",

      "damages.component.trunk": "Kofferraum/ Ladefläche",

      "damages.component.wheelRim": "Felge",

      "damages.component.windscreen": "Frontscheibe",

      "damages.component.wing": "Kotflügel",

      "damages.confirmRecord.button.text": "Schadensmeldung bestätigen",

      "damages.crack": "Riss",

      "damages.damage.desc": "Schadenbeschreibung",

      "damages.deepscratch": "Kratzer",

      "damages.dent": "Delle",

      "damages.description": "Schadensbeschreibung",

      "damages.dirt": "Verschmutzung",

      "damages.door": "Tür",

      "damages.engine.bonnet": "Motorhaube",

      "damages.exterior": "Außen",

      "damages.exterior.mirror": "Spiegel",

      "damages.floor": "Boden",

      "damages.gt10cm": ">10 cm",

      "damages.hubcap": "Radkappe",

      "damages.interior": "Innen",

      "damages.location": "Lage",

      "damages.location.front": "Vorderseite",

      "damages.location.na": "nicht verfügbar",

      "damages.location.rear": "Rückseite",

      "damages.location.side.center": "Mitte",

      "damages.location.side.codriver": "Beifahrerseite",

      "damages.location.side.driver": "Fahrerseite",

      "damages.lt10cm": "5 - 10cm",

      "damages.lt5cm": "<5 cm",

      "damages.new.photos.add": "Fotos hinzufügen",

      "damages.noRecord.text": "Keine Schadenmeldung",

      "damages.other": "Anderes",

      "damages.pernament.dirt": "Dauerhafte Verschmutzung",

      "damages.rear.window": "Fenster",

      "damages.scratch": "Kratzer",

      "damages.seat": "Sitz",

      "damages.side": "Seite",

      "damages.side.center": "Mitte",

      "damages.side.coDriversSide": "Beifahrerseite",

      "damages.side.driversSide": "Fahrerseite",

      "damages.sill": "Schweller",

      "damages.size": "Größe",

      "damages.size.gt10cm": ">10cm",

      "damages.size.lt10cm": "5 - 10cm",

      "damages.size.lt5cm": "<5cm",

      "damages.stonechip": "Steinschlang",

      "damages.thirdParty": "Beteiligung einer dritten Partei",

      "damages.thirdParty.carRoadworthyDontKnow": "Ich weiß es nicht",

      "damages.thirdParty.dontKnow": "Ich weiß nicht",

      "damages.thirdParty.isInvolved": "Dritte Parteien sind beteiligt",

      "damages.thirdParty.notInvolved": "Dritte Parteien sind nicht beteiligt",

      "damages.thirdParty.notRoadworthy": "Nicht verkehrstüchtig",

      "damages.thirdParty.roadworthy": "Verkehrstüchtig",

      "damages.title": "Schadensbericht",

      "damages.toolbar.done": "Fertig",

      "damages.toolbar.next": "Nächstes",

      "damages.trim": "Innenverkleidung",

      "damages.trunk": "Kofferraum",

      "damages.wheel.rim": "Felge",

      "damages.where": "Wo",

      "damages.where.exterior": "Außen",

      "damages.where.interior": "Innen",

      "damages.windscreen": "Frontscheibe",

      "damages.wing": "Kotflügel",

      "dashboard.about": "Rechtliches",

      "dashboard.balance": "Zahlungen",

      "dashboard.carprofile": "Autoprofil",

      "dashboard.dialog": "Impressum",

      "dashboard.dialog..url": "https://mietsmart.app/imprint",

      "dashboard.dialog.bnd.url": "https://book-n-drive.app/imprint",

      "dashboard.dialog.cookies": "Cookies",

      "dashboard.dialog.getaway": "GETAWAY Impressum",

      "dashboard.dialog.getaway.url": "https://getaway.pro/imprint",

      "dashboard.dialog.interrent.url": "https://www.interrent.com/de/rechtliche-hinweise",

      "dashboard.dialog.mba": "Impressum",

      "dashboard.dialog.mba.url": "https://mietbar.app/privacy#datenschutz",

      "dashboard.dialog.msi.url": "https://mietsmart.app/imprint",

      "dashboard.dialog.privacy": "Datenschutzerklärung",

      "dashboard.dialog.rw": "Impressum",

      "dashboard.dialog.rw.url": "https://robbe.app/imprint",

      "dashboard.dialog.terms": "AGB",

      "dashboard.dialog.ubq": "Impressum",

      "dashboard.dialog.ubq.url": "https://ubq.app/imprint",

      "dashboard.freecredit": "Erhalte gratis Guthaben",

      "dashboard.headline": "Hallo 👋\n%@ %@",

      "dashboard.help": "Hilfe-Center",

      "dashboard.help.url": "https://www.mietsmart.com/about/",

      "dashboard.hi": "Hi",

      "dashboard.list.car": "Erfahre, wie viel du mit deinem Auto verdienen kannst.",

      "dashboard.list.cars": "Weiteres Auto anmelden",

      "dashboard.logout": "ABMELDEN",

      "dashboard.logout.active.rental.prompt": "Bitte beende den Mietvorgang",

      "dashboard.logout.prompt": "Möchtest du dich tatsächlich abmelden?",

      "dashboard.logout.success": "Du hast dich erfolgreich abgemeldet.",

      "dashboard.myRentals": "Mietvorgänge",

      "dashboard.profile": "Nutzerkonto",

      "dashboard.releasing": "Freigeben...",

      "dashboard.review": "Die App bewerten",

      "dashboard.services": "Serviceaufträge",

      "dashboard.settings": "EINSTELLUNGEN",

      "day": "Tag",

      "day.ago": "Tage her",

      "day.fr": "Freitag",

      "day.mo": "Montag",

      "day.sa": "Samstag",

      "day.short": "Tag",

      "day.su": "Sonntag",

      "day.th": "Donnerstag",

      "day.tu": "Dienstag",

      "day.we": "Mittwoch",

      "days": "Tage",

      "days.ago": "Tage her",

      "days.short": "Tage",

      "delete": "Entfernen",

      "deposit.balance.title": "Hinterlegte Kaution",

      "deposit.dialog.receipt": "Beleg anzeigen",

      "deposit.dialog.refund": "Erstattung anfordern",

      "deposit.dialog.title": "Hinterlegte Kaution",

      "description": "Beschreibung",

      "desktop.switch.mobile.desc": "Um fortzufahren, scanne bitte jetzt den QR-Code mit deinem Smartphone.",

      "desktop.switch.mobile.title": "Mit Smartphone fortfahren",

      "dialog.camera.permission.desc": "Bitte aktiviere den Kamerazugriff in den Browsereinstellungen für diese Seite",

      "dialog.camera.permission.title": "Kamera-Berechtigung verweigert",

      "dialog.later": "Später",

      "direct.debit": "Lastschriftverfahren",

      "direct.debit.account.bic": "Bankleitzahl",

      "direct.debit.account.number": "Kontonummer",

      "direct.debit.add": "HINZUFÜGEN",

      "direct.debit.agreements.less": "Hiermit erteile ich der S&S Car Service GbR und ihrem Zahlungsdienstleister Adyen N.V. (Gläubiger-ID NL48ZZZ342764500000) eine Einzugsermächtigung als SEPA-Lastschriftmandat zur Einziehung fälliger Zahlungen und weise mein Geldinstitut an, die auf mein Konto gezogenen Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. Ich stimme der Fristverkürzung für die Vorankündigungen (Pre-Notification) zukünftiger SEPA-Lastschriften auf einen (1) Kalendertag zu.",

      "direct.debit.agreements.more": "Hiermit erteile ich der S&S Car Service GbR und ihrem Zahlungsdienstleister Adyen N.V. (Gläubiger-ID NL48ZZZ342764500000) eine Einzugsermächtigung",

      "direct.debit.desc": "Für sichere und bequeme Zahlungen per Lastschrift hinterlege bitte deine IBAN.",

      "direct.debit.iban": "IBAN",

      "direct.debit.title": "Lastschriftverfahren",

      "directdebit": "Lastschriftverfahren",

      "disconnected.dialog.message": "Du scheinst keine Internetverbindung zu haben. Bitte gehe online, um die App nutzen zu können.",

      "disconnected.dialog.title": "Du bist offline",

      "distance": "Gesamtstrecke",

      "dl.tab.paper": "Papierformat",

      "dl.tab.plastic": "Plastikformat",

      "document.upload.success": "Hochladen erfolgreich abgeschlossen",

      "done": "Fertig",

      "dongle.rental.fuelSettings.instruction": "Die Quittung muss nicht aufbewahrt werden. Im Anschluss lege bitte die Tankkarte zurück in das Heftchen zum KFZ-Schein.",

      "dongle.rental.section.locked": "Zurückgegeben",

      "dongle.rental.section.unlocked": "Übernommen",

      "driver.distanceduration.price.description": "{0} €/Std. + {1} €/km",

      "driver.distanceduration.price.map.description": "€/Std.",

      "driver.id.status.open": "Ausweisdokument hinzufügen",

      "driver.id.status.verified": "Personalausweis",

      "driver.id.status.verifying": "Dokument wird geprüft",

      "driver.license.banned.failure": "Bitte trage den Zeitraum ein",

      "driver.license.banned.update.button": "AKTUALISIEREN",

      "driver.license.banned.update.date.button": "WÄHLE",

      "driver.license.banned.update.description": "Ich habe ein behördlich angeordnetes Fahrverbot innerhalb des angegebenen Zeitraums.",

      "driver.license.banned.update.end": "Verbotsende",

      "driver.license.banned.update.start": "Verbotsbeginn",

      "driver.license.banned.update.title": "Fahrverbot",

      "driver.license.banned.warning": "Bitte beginne mit dem Anfangsdatum des Fahrverbots",

      "driver.license.bottom.description": "Hiermit versichere ich, dass meine Fahrerlaubnis behördlich ausgestellt und in Deutschland gültig ist und ich bei Bekanntwerden eines behördlich angeordneten Fahrverbots, diesen unverzüglich an GETAWAY melde.",

      "driver.license.frontImage": "Vorderseite",

      "driver.license.info.desc": "Bitte lass deinen Führerschein bestätigen, damit du Autos in deiner Umgebung mieten kannst.",

      "driver.license.info.save": "BESTÄTIGEN",

      "driver.license.info.title": "Deine Fahrerlaubnis",

      "driver.license.notuploaded.description": "Bitte lass deinen Führerschein bestätigen",

      "driver.license.open.description": "Eingereicht am %@",

      "driver.license.rearImage": "Rückseite",

      "driver.license.selfieImage": "Dein Selfie",

      "driver.license.status.deleted": "Abgelehnt, bitte erneut prüfen lassen",

      "driver.license.status.notuploaded": "Führerschein hinzufügen",

      "driver.license.status.open": "Wird geprüft...",

      "driver.license.status.paused": "Pausiert",

      "driver.license.status.verified": "Führerschein",

      "driver.license.status.verifying": "Führerschein wird geprüft",

      "driver.license.submit.failed": "Bitte lade alle Fotos hoch, um fortzufahren.",

      "driver.license.submit.notNow": "Bitte lass deine Fahrerlaubnis bestätigen, um Autos in deiner Nähe mieten zu können.",

      "driver.license.submit.success": "Danke. Wir haben deine Fahrerlaubnis erhalten und melden uns bei dir nach erfolgter Prüfung.",

      "driver.license.submited.description": "Eingereicht am %@",

      "driver.license.title": "Fahrerlaubnis",

      "driver.license.top.description": "Lass deine Fahrerlaubnis bestätigen, indem du deinen Führerschein jetzt hochlädst.",

      "driver.license.verified.description": "Aktualisiere deine Fahrerlaubnis",

      "driver.mba.passenger.price.description": "{0} €/Std. + {1} €/Extra Km",

      "driver.mba.passenger.price.description.daily": "{0} €/Tag + {1} €/Mehr-Km",

      "driver.mba.passenger.price.map.description": "€/Std.",

      "driver.mba.passenger.price.map.description.daily": "€/Tag",

      "driver.mba.transporter.price.description": "{0} €/Std. + {1} €/Extra Km",

      "driver.mba.transporter.price.description.daily": "{0} €/Tag + {1} €/Mehr-Km",

      "driver.mba.transporter.price.map.description": "€/Std.",

      "driver.mba.transporter.price.map.description.daily": "€/Tag",

      "driver.mis.price.description": "{0} €/Tag + {1} €/Mehr-Km",

      "driver.mis.price.map.description": "€/Tag",

      "driver.mis.spain.price.description": "{0} €/Tag",

      "driver.mis.spain.price.map.description": "€/Tag",

      "driver.mis.transporter.price.description": "{0} €/Std + {1} €/Mehr-Km",

      "driver.mis.transporter.price.map.description": "€/Std",

      "driver.price.description": "{0} €/Tag + {1} €/Mehr-Km",

      "driver.price.getaway.description": "ab 0,00 €/Std",

      "driver.price.ikea.description": "{0} €/Std + {1} €/km",

      "driver.price.ikea.description.weekend": "{0} € + {1} €/km",

      "driver.price.ikea.map.description": "€/Std.",

      "driver.price.ikea.map.description.weekend": "€",

      "driver.price.map.description": "{0} €/Tag",

      "driver.price.map.description.weekend": "€",

      "driver.price.obi.description": "{0} €/Std. + {1} €/Mehr-Km",

      "driver.price.obi.description.weekend": "{0} € + {1} €/Mehr-Km",

      "drivercar.profile.priceDetails": "Preisinfo",

      "drivercar.profile.request.action": "Anfragen",

      "drivercar.profile.reserve.action": "Jetzt buchen",

      "drivercar.profile.service.reserve.action": "Service starten",

      "drivercar.rental.dialog.message": "Möchtest du das Auto für den persönlichen Gebrauch mieten oder einen GETAWAY Service durchführen?",

      "drivercar.rental.dialog.reserve.rental": "Weiter für persönlichen Gebrauch",

      "drivercar.rental.dialog.reserve.service": "Weiter als Service-Trip",

      "drivercar.rental.dialog.title": "Bitte wähle dein Fahrerprofil",

      "drivercar.reserved.status": "RESERVIERT",

      "drivercar.review.novalue": "✨ Neues Auto",

      "drivercar.unavailable.status": "Aktuell in Nutzung",

      "driving.damage.candrive": "Auto ist noch verkehrstüchtig.",

      "driving.damage.cantdrive": "Auto ist nicht mehr verkehrstüchtig.",

      "driving.finish.rental.dialog.message": "Der Mietvorgang kann nur beendet werden, wenn der Motor aus ist.",

      "driving.finish.rental.dialog.retry": "Erneut versuchen",

      "driving.finish.rental.dialog.title": "Beenden nicht möglich",

      "driving.refuel.dialog.message": "Der Tankvorgang kann nur gestartet werden, wenn der Motor aus ist.",

      "driving.refuel.dialog.title": "Tanken nicht möglich",

      "drivinglicense.form.selfie.description": "Video (3 Sekunden) aufnehmen",

      "drivinglicense.form.selfie.done.dialog.again": "Wiederholen",

      "drivinglicense.form.selfie.done.dialog.message": "Dein Selfie wird zur Verifizierung deines Führerscheins benötigt.",

      "drivinglicense.form.selfie.done.dialog.title": "Selfie hochladen",

      "duration": "Gesamtdauer",

      "email.update.button": "Übernehmen",

      "email.update.desc": "Aktuell: %s",

      "email.update.success": "Eine neue E-Mail-Adresse ist hinterlegt worden. Bitte bestätige sie indem du den Bestätigungs-Code eingibst.",

      "email.update.title": "E-Mail Adresse ändern",

      "email.verify.desc": "Gib den Code ein, den wir soeben an diese E-Mail-Adresse gesendet haben:",

      "email.verify.skip": "Später bestätigen",

      "email.verify.success": "E-Mail erfolgreich verifiziert",

      "email.verify.title": "E-Mail-Bestätigungscode eingeben",

      "enjoyYourTrip.location.subtitle": "Abhol- & Rückkehrort",

      "enjoyYourTrip.title": "Gute Fahrt!",

      "equipment.4wd": "Allradantrieb",

      "equipment.abs": "ABS",

      "equipment.aircondition": "Klimaanlage",

      "equipment.childseat": "Kindersitz",

      "equipment.collosion.avoidance": "Notbremsassistent",

      "equipment.cruise": "Tempomat",

      "equipment.disabled": "Behindertengerecht",

      "equipment.esp": "ESP",

      "equipment.handsfree": "Freisprechanlage",

      "equipment.heating.aux": "Standheizung",

      "equipment.heating.seat": "Sitzheizung",

      "equipment.light.adaptive": "Kurvenlicht",

      "equipment.multifunctionalwheel": "Multifunktionslenkrad",

      "equipment.navigation": "Navigationssystem",

      "equipment.parking.assistant": "Einparkhilfe",

      "equipment.powersteering": "Servolenkung",

      "equipment.roof.bars": "Dachgepäckträger",

      "equipment.roof.panorama": "Panorama-Dach",

      "equipment.sensor.lane": "Spurhalteassistent",

      "equipment.sensor.proximity": "Abstandstempomat",

      "equipment.sensor.rain": "Regensensor",

      "equipment.start-stop": "Start/Stopp-Automatik",

      "equipment.sunroof": "Schiebedach",

      "equipment.traction": "Traktionskontrolle",

      "equipment.trailor": "Anhängerkupplung",

      "equipment.wheels.alloy": "Leichtmetallfelgen",

      "error": "Etwas hat nicht funktioniert. Bitte probiere es erneut. Danke!",

      "feature.gate.register.title": "PLZ FREISCHALTEN",

      "feature.gate.register.url": "https://www.get-a-way.com/",

      "feature.gate.start": "2018-01-18",

      "feature.gate.submit": "STARTEN",

      "feature.gate.zip.error": "Diese Postleitzahl ist noch nicht freigeschaltet. Sichere dir jetzt deine Einladung.",

      "feature.gate.zipcodes": "10589",

      "fees.url": "https://get-a-way.com/entgelte",

      "finish.rental.refreshButton": "Aktualisieren",

      "finished.rental.carkey.description": "Bitte lege den Autoschlüssel in das dafür vorgesehene Fach zurück.",

      "finished.rental.carkey.title": "Autoschlüssel",

      "finished.rental.checkBelongings.button": "FINISHED",

      "finished.rental.checkBelongings.title": "Bitte achte auf deine persönlichen Gegenstände.",

      "finished.rental.doors.description": "Bitte stelle sicher",

      "finished.rental.doors.title": "Fenster & Türen",

      "finished.rental.fuelLevel.description": "Bitte betanke das Fahrzeug (kostenfrei mit Tankkarte), bevor du den Mietvorgang beendest. Andernfalls können Entgelte (<25%: 5€; <10%: 50€) fällig werden.",

      "finished.rental.fuelLevel.title": "Geringer Tankstand",

      "finished.rental.fuelcard.description": "Bitte lege die Tankkarte in das dafür vorgesehene Fach zurück.",

      "finished.rental.fuelcard.title": "Tankkarte",

      "finished.rental.location.button": "ROUTE ANZEIGEN",

      "finished.rental.location.description": "Bitte beende die Miete in der angegebenen Rückgabezone. Andernfalls können Gebühren anfallen.",

      "finished.rental.location.force": "Mietende erzwingen",

      "finished.rental.location.title": "Rückgabezone",

      "finished.rental.locked.button": "VERRIEGELN",

      "finished.rental.locked.description": "Um fortzufahren, verriegele jetzt bitte das Auto von außen und stelle sicher, dass alle Türen und Fenster verschlossen sind.",

      "finished.rental.locked.title": "Auto von außen verriegeln",

      "finished.rental.thank.you.button": "JA",

      "finished.rental.thank.you.description": "Bitte entnehme den Schlüssel aus dem Handschuhfach.",

      "firstSteps.address.city.placeholder": "Stadt",

      "firstSteps.address.country.placeholder": "Land",

      "firstSteps.address.instructions": "Bitte beachte, dass es sich hierbei um deine aktuelle Meldeadresse handeln muss.",

      "firstSteps.address.optimized.title": "Aktuelle Meldeadresse",

      "firstSteps.address.personalInfo": "Persönliche Informationen",

      "firstSteps.address.postalCode.placeholder": "PLZ",

      "firstSteps.address.search.text": "Adresse suchen",

      "firstSteps.address.streetName.placeholder": "Straße",

      "firstSteps.address.streetNum.placeholder": "Nr.",

      "firstSteps.address.subTitle": "Meldeadresse",

      "firstSteps.address.submitBtn.text": "Hinzufügen",

      "firstSteps.address.title": "Meldeadresse",

      "firstSteps.email.code.placeholder": "E-Mail-Bestätigungscode",

      "firstSteps.payment.bic.placeholder": "BIC",

      "firstSteps.payment.optimized.sepa.desc": "Für sichere und bequeme Zahlungen per Lastschrift hinterlege bitte deine IBAN.",

      "firstSteps.payment.optimized.sepa.title": "Lastschriftverfahren",

      "firstSteps.payment.sepa.description.text": "Hiermit erteile ich der S&S Car Service GbR und ihrem Zahlungsdienstleister Adyen N.V. (Gläubiger-ID NL48ZZZ342764500000) eine Einzugsermächtigung als SEPA-Lastschriftmandat zur Einziehung fälliger Zahlungen und weise mein Geldinstitut an, die auf mein Konto gezogenen Lastschriften einzulösen. Ich kann innerhalb von acht Wochen, beginnend mit dem Belastungsdatum, die Erstattung des belasteten Betrages verlangen. Es gelten dabei die mit meinem Kreditinstitut vereinbarten Bedingungen. Ich stimme der Fristverkürzung für die Vorankündigungen (Pre-Notification) zukünftiger SEPA-Lastschriften auf einen (1) Kalendertag zu.",

      "firstSteps.payment.sepa.iban.placeholder": "IBAN",

      "firstSteps.payment.sepa.info": "Zustimmung zum Lastschriftverfahren",

      "firstSteps.payment.sepa.instruction": "Für sichere und bequeme Zahlungen per Lastschrift hinterlege bitte deine IBAN.",

      "firstSteps.payment.sepa.name.placeholder": "Kontoinhaber",

      "firstSteps.payment.sepa.submit.text": "Hinzufügen",

      "firstSteps.payment.sepa.title": "Lastschriftverfahren",

      "firstSteps.payment.sepa.url": "https://google.com",

      "firstSteps.payment.sepa.url.text": "Mehr",

      "firstSteps.phone.code.placeholder": "SMS-Bestätigungscode",

      "fleetadmin.admins.admin.new": "Neuen Administrator erstellen",

      "fleetadmin.admins.info.account": "Nutzerkonto",

      "fleetadmin.admins.info.active": "aktiv",

      "fleetadmin.admins.info.archive": "Nutzerkonto archivieren",

      "fleetadmin.admins.info.cancel": "Abbrechen",

      "fleetadmin.admins.info.created": "Erstellt am",

      "fleetadmin.admins.info.createdby": "Erstellt von",

      "fleetadmin.admins.info.delete.title": "Sicher, dass du diesen Administrator löschen willst?",

      "fleetadmin.admins.info.deleted": "archiviert",

      "fleetadmin.admins.info.email": "E-Mail",

      "fleetadmin.admins.info.firstName": "Vorname",

      "fleetadmin.admins.info.language": "Sprache",

      "fleetadmin.admins.info.lastName": "Nachname",

      "fleetadmin.admins.info.merchant": "Kunden-Account",

      "fleetadmin.admins.info.status": "Status",

      "fleetadmin.admins.info.update": "Aktualisieren",

      "fleetadmin.admins.info.update.title": "Nutzerinformationen aktualisieren",

      "fleetadmin.admins.info.user": "Nutzer",

      "fleetadmin.admins.permission.automatically": "Neue automatisch hinzufügen",

      "fleetadmin.admins.permission.claimsmanager": "Schadensbearbeitung",

      "fleetadmin.admins.permission.clear.all": "Alle entfernen",

      "fleetadmin.admins.permission.fleet.title": "Flottenverwaltung",

      "fleetadmin.admins.permission.fleetmanager": "Fahrzeuge und Zonen",

      "fleetadmin.admins.permission.mechanic": "Mechaniker (G-Connect)",

      "fleetadmin.admins.permission.merchant.title": "Account und Markenzugang",

      "fleetadmin.admins.permission.merchantmanager": "Administratorenverwaltung",

      "fleetadmin.admins.permission.select.all": "Alle hinzufügen",

      "fleetadmin.admins.permission.tripsmanager": "Mieten und Reservierungen",

      "fleetadmin.admins.permission.user.title": "Nutzerverwaltung",

      "fleetadmin.admins.permission.usergroupsmanager": "Nutzerkreise",

      "fleetadmin.admins.permission.usermanager": "Endnutzer",

      "fleetadmin.admins.permissions.update": "Aktualisieren",

      "fleetadmin.admins.tab.info": "Info",

      "fleetadmin.admins.tab.permissions": "Berechtigungen",

      "fleetadmin.admins.table.access": "Berechtigung",

      "fleetadmin.admins.table.blocked": "Status",

      "fleetadmin.admins.table.created": "Angelegt am",

      "fleetadmin.admins.table.email": "E-Mail",

      "fleetadmin.admins.table.firstname": "Vorname",

      "fleetadmin.admins.table.fleets": "Flottenzugang",

      "fleetadmin.admins.table.full": "Uneingeschränkt",

      "fleetadmin.admins.table.fullaccess": "Zugriff",

      "fleetadmin.admins.table.lastname": "Nachname",

      "fleetadmin.admins.table.limited": "Eingeschränkt",

      "fleetadmin.booking.blockreasons.label": "Gründe für Blockierung",

      "fleetadmin.booking.button.assign": "Zuweisen",

      "fleetadmin.booking.button.cancel": "Abbrechen",

      "fleetadmin.booking.button.update": "Aktualisieren",

      "fleetadmin.booking.create.button": "Erstellen",

      "fleetadmin.booking.create.duration": "Dauer",

      "fleetadmin.booking.create.plate": "Kennzeichen",

      "fleetadmin.booking.create.title": "Neue Buchung erstellen",

      "fleetadmin.booking.create.type": "Typ",

      "fleetadmin.booking.create.type.offline": "Nutzer",

      "fleetadmin.booking.create.type.service": "Service",

      "fleetadmin.booking.create.user": "Nutzer",

      "fleetadmin.booking.create.user.placeholder": "E-Mail",

      "fleetadmin.booking.statuschange.label": "Kommentar",

      "fleetadmin.booking.type.offline": "Offline",

      "fleetadmin.booking.type.rental": "Miete",

      "fleetadmin.booking.type.service": "Service",

      "fleetadmin.booking.type.statusChange": "Statusänderung",

      "fleetadmin.booking.update.from.label": "Start ",

      "fleetadmin.booking.update.from.title": "Start- und End-Datum der Buchung aktualisieren?",

      "fleetadmin.booking.update.to.label": "End ",

      "fleetadmin.booking.update.to.title": "Zeit aktualisieren",

      "fleetadmin.booking.update.user.title": "User aktualisieren",

      "fleetadmin.booking.update.vehicle.label": "Kennzeichen",

      "fleetadmin.booking.update.vehicle.title": "Neues Fahrzeug der Reservierung zuweisen?",

      "fleetadmin.booking.user.label": "Zugeordneter User",

      "fleetadmin.booking.user.placeholder": "E-Mail Adresse",

      "fleetadmin.car.status.clear.note": "Notiz entfernen.",

      "fleetadmin.car.status.range.instant": "Sofort",

      "fleetadmin.claim.after": "Nach",

      "fleetadmin.claim.invoice.create": "Rechnung erstellen",

      "fleetadmin.claim.invoice.created": "Erstellte Rechnung",

      "fleetadmin.claim.invoice.wontinvoice": "Geschlossen (nicht berechnet)",

      "fleetadmin.claim.section.complete": "Forderung abschließen",

      "fleetadmin.claim.section.completed": "Vollständig",

      "fleetadmin.claim.section.incomplete": "Schließen als unvollständig",

      "fleetadmin.filter.BOOKING.type": "Buchungs-Typ",

      "fleetadmin.trip.info.backby.label": "Rückgabe-Zeit",

      "fleetadmin.trip.info.tripstarted.label": "Begann um",

      "fleetadmin.trip.update.backby.label": "Rückgabezeit  ",

      "fleetadmin.trip.update.backby.title": "Rückgabezeit aktualisieren?",

      "fleetadmin.users.create.title": "Nutzer erstellen",

      "fleetadmin.users.info.upsellings.servicepaket300sb": "Service-Paket",

      "form.31MgGL.1.desc": "Diese Angabe ist wichtig für die Teilnahme an unseren Vorteilsprogrammen.",

      "form.31MgGL.1.title": "Bist du  geworben worden?",

      "form.3yYJ8x.1.desc": "Sofern Sie in der Immobilie eines unserer Immobilienpartner\nwohnen, wählen Sie bitte den entsprechenden Partner.",

      "form.3yYJ8x.1.title": "Immobilienpartner wählen",

      "form.3yYJ8x.2.desc": "An welchem Standort werden Sie unseren Service\nvoraussichtlich am häufigsten nutzen?",

      "form.3yYJ8x.2.title": "Standort wählen",

      "form.woGyQx.1.desc": "Diese Angabe ist wichtig für die Teilnahme an unseren\nVorteilsprogrammen.",

      "form.woGyQx.1.title": "Sind Sie geworben worden?",

      "from": "Ab",

      "fuelstation.ap.name": "AP",

      "fuelstation.aral.name": "ARAL",

      "fuelstation.aral.title": "Aral Tankstelle",

      "fuelstation.avanti.name": "Avanti",

      "fuelstation.bp.name": "BP",

      "fuelstation.eni.name": "Eni",

      "fuelstation.help.text": "Hilfe zur Nutzung der Tankkarte",

      "fuelstation.help.url": "https://service.robbe.app/de/articles/3210304-wie-funktioniert-das-tanken",

      "fuelstation.om.name": "OMV",

      "fuelstation.omv.name": "OMV",

      "fuelstation.openallday": "24 Stunden geöffnet",

      "fuelstation.opennow": "Aktuell geöffnet:",

      "fuelstation.openstoday": "Heute geöffnet:",

      "fuelstation.openstomorrow": "Morgen geöffnet:",

      "fuelstation.status.prefix": "Tankstelle",

      "fuelstation.total.name": "Total",

      "fuelstation.westfalen.name": "Westfalen",

      "handover.all.around.confirm": "Ignorieren & fortfahren",

      "handover.all.around.no": "Rundum-Fotos aufnehmen",

      "handover.all.around.warning": "Bitte bedenke, dass du verpflichtet bist, den Fahrzeugzustand beim Beenden der Miete mittels Bereitstellung der Rundum-Fotos zu bezeugen und der Vermieterin auszuhändigen.",

      "handover.belonging.title": "Alle Habseligkeiten entnommen",

      "handover.belongings.button.title": "Ich habe alles bei mir",

      "handover.belongings.decr": "Bitte überprüfe, dass du keine privaten Gegenstände im Auto vergessen hast.",

      "handover.belongings.title": "Nichts vergessen?",

      "handover.bottom.description": "Du findest den Schlüssel im Handschuhfach auf der Beifahrerseite",

      "handover.carCheck.title": "Rundum-Fotos aufgenommen",

      "handover.card.title": "Tankkarte zurückgelegt",

      "handover.damages.count": "%d Schäden",

      "handover.demages.description": "Gemeldete Schäden",

      "handover.dongle.card.title": "Tankkarte zurückgeben",

      "handover.dongle.key.title": "Schlüssel zurückgeben",

      "handover.doorsWidows.title": "Nichts vergessen & ausgestiegen",

      "handover.doorsWindows.title": "Ich habe nichts vergessen & bin ausgestiegen",

      "handover.engine.dialog.message": "Starte einfach den Motor, um den Übergabe-Check zu bestätigen. Du findest den Autoschlüssel im Handschuhfach auf der Beifahrerseite.",

      "handover.engine.dialog.title": "Jetzt Motor starten",

      "handover.fuel.title": "Ich wähle den Tank-Service",

      "handover.fuelCard.button.title": "Aktualisieren",

      "handover.fuelCard.decr": "Bitte sicherstellen, dass sich die Tankkarte im Handschuhfach befindet.",

      "handover.fuelCard.title": "Tankkarte zurückgelegt",

      "handover.key.title": "Schlüssel zurückgelegt",

      "handover.location.button.title": "Rückgabeort anzeigen",

      "handover.location.decr": "Bitte die Miete im Umkreis des angegebenen Rückgabeorts beenden. ",

      "handover.location.option": "Selbst fahren",

      "handover.location.title": "Rückgabeort",

      "handover.location.warning": "Ich erkläre mich einverstanden, 500 € zu zahlen.",

      "handover.lock.button.title": "Auto verriegeln",

      "handover.lock.locking": "Verriegle ...",

      "handover.lock.title": "Schließe alle Türen und Fenster und verriegele das Fahrzeug von außen.",

      "handover.lowFuel.button.title": "Suche nächste Tankstelle",

      "handover.lowFuel.decr": "Bitte tanke das Auto, bevor du die Miete beendest. Für Hilfe, kontaktiere unser Service-Team.",

      "handover.lowFuel.option": "Selbst tanken",

      "handover.lowFuel.title": "Niedriger Kraftstoffstand",

      "handover.lowFuel.warning": "Ich erkläre mich einverstanden, 50 € zu zahlen.",

      "handover.lowfuel.fee": "Ich wähle den Tank-Service (%s)",

      "handover.payAsReserved.prefix": "Zahlungspflichtig bis",

      "handover.report": "Schaden bzw. Mangel melden",

      "handover.returnKey.button.title": "Aktualisieren",

      "handover.returnKey.decr": "Bitte sicherstellen, dass sich der Autoschlüssel im Handschuhfach befindet.",

      "handover.returnKey.title": "Autoschlüssel zurückgeben",

      "handover.returnLocation.title": "Ich wähle den Rückbring-Service (nur innerhalb von 5km um die Rückgabezone möglich)",

      "handover.title": "Starte jetzt den Motor",

      "handover.top.description": "Wenn das Auto bis auf die bereits aufgeführten Mängel schadenfrei ist, starte den Motor und habe eine gute Fahrt!",

      "handover.unlock.description": "Deine Miete wurde nicht beendet. Bitte versuche es erneut. Sollte das Problem anschließend nicht behoben sein, wende dich bitte an unser Service Team.",

      "handover.unlock.slider.title": "Fahrzeug öffnen",

      "handover.unlock.title": "Etwas ist schief gelaufen!",

      "help.dialog.chat": "LiveChat",

      "help.dialog.helpcenter": "HilfeCenter",

      "help.dialog.title": "Service Team kontaktieren",

      "heyOBI.heyOBI.pricing": "| Mietdauer    |  |\n| :-------- | -------: |\n| Mo-Sa, 8-20 Uhr | ~~19,99~~ 16,99 €/Std. (inkl. 20km/Std)|\n| Mo-Sa, 20-8 Uhr | ~~59,90~~ 50,92 € (inkl. 100km)|\n| Sa, 20 Uhr - Mo, 8 Uhr | ~~139,90~~ 102,00 € (inkl. 150km) |\n| An Feiertagen | ~~59,90~~ 50,92 €  € (inkl. 100km) |\n\n| Fahrtstrecke (inkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Je Mehrkilometer | 0,30 € |\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 1.500€ | 0 € (inklusive) |\n| max. 500€ | 11 €/Kal.Tag |\n| max. 150€ | 19 €/Kal.Tag |\n\n| Zusatzleistungen    |  |\n| :-------- | -------: |\n| Insassenschutz  | 10 €/Miete|\n| Rückgabe außerhalb der Zone | 25€ + 0,49€/100m (max. 500 EUR) |\n| Grenzübertrittsgebühr | 7,50 € / Tag |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand < 25%  | 15 € |\n| Tankstand < 10%  | 50 € |\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf) zu entnehmen.",

      "home.about": "© GETAWAY, rechtl. Info ",

      "home.walkthrough.guest": "Als Gast fortfahren",

      "home.walkthrough.login": "Login",

      "home.walkthrough.page1.description": "Hol dir deinen Smart bereits ab 12,50€/Tag - inklusive Steuern, Wartung, Pflege und Parken in der Rückgabezone.",

      "home.walkthrough.page1.title": "Günstig Smart fahren",

      "home.walkthrough.page2.description": "Reserviere und entriegele dein Auto einfach per App. Melde dich jetzt gratis an.",

      "home.walkthrough.page2.title": "Günstig Smart fahren",

      "home.walkthrough.signup": "Los geht's",

      "hours.short": "Std.",

      "id.tab.card": "Personalausweis",

      "id.tab.passport": "Reisepass",

      "ikeaInnsbruck.ubeeqo.pricing": "| Mietdauer (9:30 bis 21 Uhr)    |  |\n| :-------- | -------: |\n| Stunde 1 bis 4 | 7,90 €/Std|\n| ab 5. Stunde | 9,90 €/Std|\n\n\n|  Nachtzuschlag (21 bis 9:30 Uhr)    |  |\n| :-------- | -------: |\n| Mo - Fr | +20 €|\n| Sa - Mo | +89 €|\n\n\n| Fahrtstrecke (inkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Je Kilometer | 0,30 € / km |\n\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 750€ | 0 € (inklusive) |\n| max. 500€ | 11 €/Kal.Tag |\n| max. 150€ | 19 €/Kal.Tag |\n\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand < 25%  | 15 € |\n| Tankstand < 5%  | 50 € |\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://api.get-a-way.com/pricing/5d8f8cbba3dd8910deb5d7ce/pdf) zu entnehmen.",

      "ikeaMain.duration.prefix": ".",

      "ikeaMain.ubeeqo.pricing": "| Mietdauer    |  |\n| :-------- | -------: |\n| Stunde 1 bis 4 | 7,90 €/Std|\n| Ab 5. Stunde | 9,90 €/Std|\n\n\n| Fahrtstrecke (inkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Je Kilometer | 0,30 € / km |\n\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 750€ | 0 € (inklusive) |\n| max. 500€ | 11 €/Kal.Tag |\n| max. 150€ | 19 €/Kal.Tag |\n\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand < 25%  | 15 € |\n| Tankstand < 5%  | 50 € |\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://api.get-a-way.com/pricing/5d8f8cbba3dd8910deb5d7ce/pdf) zu entnehmen.",

      "image.upload.success": "Hochladen erfolgreich",

      "input.error.invalid": "Falsche Eingabe",

      "input.password.error.invalid": "Passwort muss mindestens 6 Zeichen, eine Zahl, einen Großbuchstaben, und ein Symbol enthalten.",

      "insecuredevice.dialog.message": "Dieses Handy ist nicht mit deinem Nutzerkonto verknüpft. Um es zu verifizieren, senden wir dir einen Bestätigungscode per SMS an die Handynummer, die in deinem Konto hinterlegt ist.",

      "insecuredevice.dialog.no": "Jetzt nicht",

      "insecuredevice.dialog.title": "Dieses Handy verifizieren",

      "insecuredevice.dialog.yes": "Verifizieren",

      "insurance.charterline.150": "Maximal 150 €",

      "insurance.charterline.1500": "Maximal 1.500 €",

      "insurance.charterline.15000": "Maximal 15.000 €",

      "insurance.charterline.151": "Maximal 150 €",

      "insurance.charterline.500": "Maximal 500 €",

      "insurance.charterline.501": "Maximal 500 €",

      "insurance.charterline.650": "Maximal 650 €",

      "insurance.charterline.750": "Maximal 750 €",

      "insurance.charterline.description": "Selbstbeteiligung",

      "insurance.charterline.description.1500": "Bis zu 15.000 €",

      "insurance.charterline.headline": "Selbstbeteiligung",

      "insurance.charterline.value.150": "+19 €/KalTag",

      "insurance.charterline.value.1500": "inkl.",

      "insurance.charterline.value.15000": "inkl.",

      "insurance.charterline.value.151": "+29 €/KalTag",

      "insurance.charterline.value.500": "+11 €/KalTag",

      "insurance.charterline.value.501": "+21 €/KalTag",

      "insurance.charterline.value.650": "inkl.",

      "insurance.charterline.value.750": "inkl.",

      "insurance.description": "maximal",

      "insurance.description.free": "Bis zu",

      "insurance.fee_item.description": "Reduzierung SB %s",

      "insurance.label.free": "max. 950€",

      "insurance.previous_for_the_same_car_must_be_used": "Bei Benutzung des gleichen Fahrzeugs muss die selbe Versicherungsoption gewählt werden.",

      "insurance.rental.title": "Deine Selbstbeteiligung!",

      "insurance.value": "€/Tag",

      "insurance.value.free": "inkl.",

      "internet.disconnected.message": "Bitte überprüfe deine Internetverbindung",

      "invitation.code.label": "Einladungscode eingeben",

      "invitation.code.submit": "Los geht’s",

      "invitation.description": "Gib jetzt deinen Einladungscode an",

      "invitation.remove.text": "ENTFERNEN",

      "invitation.submit.text": "ÜBERNEHMEN",

      "invitation.text.placeholder": "Einladungscode",

      "invitation.title": "Du hast eine Einladung?",

      "invite.browse.contacts.button": "KONTAKTE DURCHSUCHEN",

      "invite.contacs.success": "Einladung ist abgeschickt worden. Wir benachrichtigen dich, sobald sie angenommen wird.",

      "invite.contact.email.warning": "Du musst einen E-Mail Account eingerichtet haben",

      "invite.email.description": "GETAWAY ist der erste professionelle Fuhrpark von Autofahrern für Autofahrer. Wenn du ein Auto hast, kannst du es Geld verdienen lassen und somit alle deine Autokosten decken. Hast du einen Führerschein, kannst du jederzeit und überall dein Wunschauto per App finden und sofort losfahren. Probiere es aus und sichere dir mit meiner Einladung 10€ Startguthaben auf %@",

      "invite.email.title": "Mach mit bei GETAWAY",

      "invite.friends.share.button": "TEILEN",

      "invite.friends.share.description": "Zum Kopieren, Einladungslink klicken und halten",

      "invite.message": "Ich schenke dir 10€ Startguthaben für GETAWAY, deinem Universalschlüssel zu jedem Auto in deiner Umgebung.  Oder vermiete dein eigenes Auto und verdiene 100€ wöchentlich. Lade dir jetzt gratis die App herunter.",

      "invite.message.title": "Du bist zu GETAWAY eingeladen worden",

      "joinCircle.title": "Nutzerkreis beitreten",

      "keine": "Keine",

      "landing.card1.desc": "Decke deine Autokosten, indem du es sicher und ohne Aufwand in deiner Nachbarschaft vermietest.",

      "landing.card1.title": "Dein Auto verdient es!",

      "landing.card2.desc": "Unsere Versicherung schützt dein Auto und deine Mobilität.",

      "landing.card2.title": "Rundum versichert",

      "landing.later": "SPÄTER",

      "landing.login": "Ich habe bereits ein Nutzerkonto",

      "landing.signup": "Willkommen!",

      "landing.signup.dialog.email.exists": "Es scheint, als sei deine E-Mail Adresse bereits hinterlegt. Möchtest du dein Passwort zurücksetzen?",

      "language.dialog.cz": "Čeština",

      "language.dialog.de": "Deutsch",

      "language.dialog.en": "English",

      "language.dialog.fr": "Français",

      "language.dialog.it": "Italiano",

      "language.dialog.pl": "Polski",

      "language.dialog.sk": "Slovak",

      "language.dialog.title": "Sprache wählen",

      "language.dialog.tr": "Türkçe",

      "late": "abgelaufen",

      "less": "Weniger",

      "license.addPhoto.back": "Führerschein-Rückseite hinzufügen",

      "license.addPhoto.front": "Führerschein-Vorderseite hinzufügen",

      "license.addVideo": "Selfie-Video hinzufügen",

      "license.addedPhoto.back": "Führerschein-Rückseite",

      "license.addedPhoto.front": "Führerschein-Vorderseite",

      "license.addedVideo": "Selfie-Video",

      "license.submit.title": "Weiter",

      "license.title": "Führerschein hinzufügen",

      "list.car.desc": "Fotografiere jetzt die Vorderseite deines Fahrzeugscheins und lass gratis prüfen, wie viel dein Auto für dich verdienen kann.",

      "list.car.footer": "Das Auto hat eine gültige Haftpflichtversicherung und eine gültige Hauptuntersuchung.",

      "list.car.license": "Kfz-Schein Vorderseite (vollst. aufgeklappt)",

      "list.car.ownership.error": "Bitte gib das Eigentumsverhältnis an",

      "list.car.ownership.message": "Wer nicht Halter bzw. Eigentümer des Fahrzeugs ist, muss alternativ eine Vollmacht vorlegen. Eine Vorlage wird zur Verfügung gestellt.",

      "list.car.submit": "JETZT CHECKEN LASSEN",

      "list.car.success": "Danke, dein Fahrzeugschein ist übermittelt worden. Wir schicken dir eine E-Mail mit der weiteren Vorgehensweise.",

      "list.car.takeaphoto.error": "Bitte lade deinen Kfz-Schein hoch, um fortzufahren.",

      "list.car.title": "Wie viel verdient dein Auto?",

      "loading": "loading ...",

      "location.permission": "Achtung: Bitte erlaube Zugriff auf deinen Standort, um Fahrzeuge finden und mieten zu können.",

      "location.permission.details": "Um u.a. Autos in deiner Nähe angezeigt zu bekommen, aktiviere jetzt bitte die Standortdienste.",

      "location.permission.no": "Später",

      "location.permission.title": "Standortdienste aktivieren",

      "location.permission.unauthorized": "GETAWAY kann ohne die Info über deinen Standort nicht einwandfrei funktionieren.",

      "location.permission.yes": "Erlauben",

      "lock.error": "Auto kann nicht verschlossen werden. Bitte kontaktiere unser Service Team.",

      "login.button.title": "Login",

      "login.email.placeholder": "E-Mail",

      "login.failed.again.text": "Erneut versuchen",

      "login.failed.alert.body": "E-Mail und/oder Passwort stimmen nicht. Versuche es noch einmal oder klicke \"Vergessen?\"",

      "login.failed.alert.title": "Anmelden fehlgeschlagen",

      "login.footnote.signup.action": "Hier registrieren",

      "login.footnote.signup.text": "Noch kein Nutzerkonto?",

      "login.forgot.text": "Passwort zurücksetzen",

      "login.forgot.title": "Passwort vergessen?",

      "login.orloginfacebook.text": "Oder per",

      "login.orsignupfacebook.text": "Oder per",

      "login.password.placeholder": "Passwort",

      "login.title": "Schön, dich wiederzusehen!",

      "logout.prompty.logout": "JA",

      "mages.510cm": "5 - 10cm",

      "map.booking.reserve": "Reservieren",

      "map.bookings.button.text": "Reservierung ab",

      "map.callout.bonus.lowAndOut": "Bis zu 10 € Sofort-Gutschrift.",

      "map.callout.bonus.lowFuel": "5€ Sofort-Bonus",

      "map.callout.bonus.lowFuel&out": "Bis zu 10€ Sofort-Bonus",

      "map.car.eligible.description": "[In Warteschlange] Bald in deiner Nähe verfügbar.",

      "map.car.inqueue.callout": "Melden sich mehr aus meiner Nähe an, stehe ich in Kürze zur Verfügung.",

      "map.car.release_ready.callout": "Dieses Fahrzeug ist leider aktuell nicht verfügbar. Bitte wähle einen anderen Zeitraum.",

      "map.car.release_ready.description": "[In Nutzung] Bitte probiere es später erneut.",

      "map.car.released.callout": "Verfügbar bis\n%@",

      "map.car.selected.callout": "Ich bin bald verfügbar. Du wirst mich in Kürze mieten können 🙌",

      "map.card.refuel": "Bitte tanken",

      "map.consent.returnrefuel.no": "Abbrechen",

      "map.consent.returnrefuel.yes": "Zustimmen",

      "map.embedded.card.button": "Fahrzeuge anzeigen",

      "map.embedded.card.title": "Miete Fahrzeuge bequem in deiner Nähe",

      "map.embedded.from": "Von",

      "map.embedded.to": "Bis",

      "map.fillProfile.dialog.add": "Hinzufügen",

      "map.fillProfile.dialog.message": "Vervollständige jetzt deine Nutzerangaben (nur 2 Min.), um fortzufahren.",

      "map.fillProfile.dialog.no": "Jetzt nicht",

      "map.fillProfile.dialog.secure": "Kreditkartenüberprüfung",

      "map.fillProfile.dialog.support": "Registrierung abschließen",

      "map.fillProfile.dialog.title": "Fehlende Nutzerangaben",

      "map.fillProfile.dialog.yes": "OK",

      "map.filter.check": "Verfügbarkeit prüfen",

      "map.filter.description": "Gib den gewünschten Abholungs- und Rückgabezeitpunkt an.",

      "map.filter.findCar": "Fahrzeug finden",

      "map.filter.header": "Wann möchtest du ein Fahrzeug?",

      "map.filter.now": "Ab sofort",

      "map.filter.pickup": "Abholung",

      "map.filter.reset": "Zurücksetzen",

      "map.filter.return": "Rückgabe",

      "map.filter.secondary.text": "Zeitraum ändern",

      "map.filter.time.to": "bis",

      "map.filter.title": "Wann brauchst du ein Fahrzeug?",

      "map.marker.perHours": "€/Std.",

      "map.popup.firststeps": "%@ und erhalte deinen Willkommensbonus.",

      "map.popup.firststeps.url": "https://applink.get-a-way.com/user-challenges-firststeps",

      "map.popup.firststeps.url.text": "Schließe jetzt deine Anmeldung ab",

      "map.rental.blocked": "Miete ist geblockt",

      "map.rented.callout": "Im gewählten Zeitraum nicht verfügbar.",

      "map.reservedCarHeader.settings.title": "Mieteinstellungen (Versicherung etc.)",

      "map.reservedCarHeader.title": "Das Auto ist reserviert!",

      "map.service.lastCleaning.new": "neu",

      "map.station.card.button": "Auswählen",

      "map.station.card.description": "Station",

      "map.station.card.headline": "Wähle eine Fahrzeugkategorie",

      "map.station.card.no.categories": "In dieser Station stehen im gewählten Zeitraum keine Fahrzeuge per App zur Verfügung.",

      "map.station.card.position": "Standort",

      "map.station.card.title": "Robben & Wientjes",

      "map.station.card.workinghrs": "Öffnungszeiten",

      "map.station.closed.text": "In dieser Station stehen im gewählten Zeitraum keine Fahrzeuge per App zur Verfügung.",

      "map.time": "Autos filtern",

      "map.time.select.apply": "Filtern",

      "map.time.select.title": "wie lange möchtest du einen GETAWAY?",

      "map.unavailable.callout": "Im gewählten Zeitraum nicht verfügbar. %s .",

      "map.unavailable.callout.link": "https://www.google.com/",

      "map.unavailable.callout.link.text": "Verfügbarkeit anzeigen",

      "map.user.blocked": "Nutzerkonto blockiert",

      "map.usercar.BLOCKED.callout": "✋ Ich bin aktuell geblockt. Bitte kontaktiere das Service Team.",

      "map.usercar.ELIGIBLE.callout": "💪 Ich habe alle Tests bestanden",

      "map.usercar.EQUIPPED.callout": "⚙ Ich bin ausgerüstet",

      "map.usercar.SELECTED.callout": "🎉 Ich kann es kaum erwarten, ausgerüstet zu werden!",

      "map.usercar.blocked.callout": "✋ Ich bin aktuell geblockt. Bitte kontaktiere das Service Team.",

      "map.usercar.eligible.callout": "💪 Ich habe alle Tests bestanden",

      "map.usercar.equipment_ready.callout": "👨‍🔧 Ich warte auf den GETAWAY Techniker",

      "map.usercar.listed.callout": "💰 Ich könnte bis zu 400€ pro Monat verdienen!",

      "map.usercar.released.callout": "Freigegeben bis\n%@",

      "map.usercar.selected.callout": "🎉 Ich kann es kaum erwarten, ausgerüstet zu werden!",

      "map.usercar.service.callout": "🚗✨ Ich genieße gerade einen Service",

      "md.demo": "| Kurzzeit (bis 30 Tage)|||\n| :------------- |-------------:|-------------:|\n|       | <b>Tag 1 bis 3     | <b>Ab Tag 4     |\n| Smart 451      | 30 €/Tag     | 15 €/Tag     |\n| Smart 453      | 40 €/Tag     | 20 €/Tag     |\n| Audi      | 40 €/Tag     | 20 €/Tag     |\n\n| Langzeit (über 30 Tage)   |  |\n| :-------- | -------: |\n| Smart 451 | 12,50 €/Tag |\n| Smart 453 | 15 €/Tag |\n| Audi | 15 €/Tag |\n\n\n| Fahrtstrecke (exkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Inklusiv-Kilometer | 50 km/Tag |\n| Je Extra-Kilometer | 0,20 €/km |\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 950€ | 0 € (inklusive) |\n| max. 500€ | 3 €/Tag |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand geringer als zu Beginn  | 2,50 € / Liter |\n| Tankstand < 10%  | 50 € |\n\n> Bei jeder Buchung gilt:\nFahrzeug ist mit dem Tankstand zurückzugeben, wie es übernommen wurde.\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf) zu entnehmen.",

      "mileage": "Km-Stand",

      "minute": "Minute",

      "minute.short": "Min.",

      "minutes": "Minuten",

      "misSmart.MietSmart.pricing": "| Kurzzeit (bis 30 Tage)|||\n| :------------- |-------------:|-------------:|\n|       | <b>Tag 1 bis 3     | <b>Ab Tag 4     |\n| Smart 451      | 30 €/Tag     | 15 €/Tag     |\n| Smart 453      | 40 €/Tag     | 20 €/Tag     |\n| Audi      | 40 €/Tag     | 20 €/Tag     |\n\n| Langzeit (über 30 Tage)   |  |\n| :-------- | -------: |\n| Smart 451 | 12,50 €/Tag |\n| Smart 453 | 15 €/Tag |\n| Audi | 15 €/Tag |\n\n\n| Fahrtstrecke (exkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Inklusiv-Kilometer | 50 km/Tag |\n| Je Extra-Kilometer | 0,20 €/km |\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 950€ | 0 € (inklusive) |\n| max. 500€ | 3 €/Tag |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand geringer als zu Beginn  | 2,50 € / Liter |\n| Tankstand < 10%  | 50 € |\n\n> Bei jeder Buchung gilt:\nFahrzeug ist mit dem Tankstand zurückzugeben, wie es übernommen wurde.\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf) zu entnehmen.",

      "misSmartSpain.MietSmart.pricing": "| Kurzzeit (bis 30 Tage)|||\n| :------------- |-------------:|-------------:|\n|       | <b>Tag 1     | <b>Ab Tag 2     |\n| Smart 453      | 59 €/Tag     | 35 €/Tag     |\n| Audi      | 69 €/Tag     | 45 €/Tag     |\n\n\n| Fahrtstrecke (exkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Inklusiv-Kilometer | unbegrenzt |\n| Je Extra-Kilometer | inklusive (0€) |\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 950€ | 0 € (inklusive) |\n| max. 500€ | 3 €/Tag |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand geringer als zu Beginn  | 2,50 € / Liter |\n| Tankstand < 10%  | 50 € |\n\n> Bei jeder Buchung gilt:\nFahrzeug ist mit dem Tankstand zurückzugeben, wie es übernommen wurde.\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf) zu entnehmen.",

      "more": "Mehr",

      "multiplecars.add": "Jetzt neues Auto prüfen lassen",

      "multiplecars.footnote": "Tipps und Tricks, wie du mit deinem Auto Geld verdienst, erfährst du in unserem %@.",

      "multiplecars.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#geld-verdienen",

      "multiplecars.footnote.url.text": "HilfeCenter",

      "multiplecars.released.returntime": "bis",

      "multiplecars.rented.returntime": "bis spät.",

      "multiplecars.title": "MEINE AUTOS",

      "navigate": "Navigieren",

      "needsAccessKitCheck": "AccessKit prüfen",

      "needsBattery": "Niedriger Batteriestand",

      "needsCleaning": "Reinigung nötig",

      "needsFuel": "Kraftstoff nötig",

      "needsFuelCard": "Tankkarte nötig",

      "needsKeyFob": "KeyFob nötig",

      "needsOther": "Sonstiges nötig",

      "needsRelocation": "Verlegungsfahrt nötig",

      "needsRepair": "Reparatur nötig",

      "new.insurance.charterline.150": "150 €",

      "new.insurance.charterline.1500": "1500 €",

      "new.insurance.charterline.15000": "15.000 €",

      "new.insurance.charterline.151": "150 €",

      "new.insurance.charterline.500": "500 €",

      "new.insurance.charterline.501": "500 €",

      "new.insurance.charterline.650": "650 €",

      "new.insurance.charterline.750": "750 €",

      "new.insurance.charterline.description": "Max",

      "new.insurance.charterline.description.15000": "Bis zu",

      "no": "Abbrechen",

      "no.geocode": "Rückgabezone anzeigen",

      "noBlockReason": "Kein Downtime-Grund verfügbar",

      "noRentals": "Keine Mietvorgänge vorhanden",

      "noServices": "Keine Aufträge",

      "nocars.invite": "EINLADEN",

      "nocars.screen.title": "Lade Freunde ein für mehr Autos in deiner Nähe!",

      "notNow": "Nicht jetzt",

      "notification.backby.desc": "Bitte denke an die rechtzeitige Rückgabe, andernfalls können Gebühren anfallen.",

      "notification.backby.hide": "HINWEIS VERBERGEN",

      "notification.backby.title": "Noch %s",

      "notification.backby.title.late": "Seit %s",

      "notification.booking.rentals": "Meine Mieten",

      "notification.booking.success": "Du hast deine Buchung erfolgreich durchgeführt. Du findest den Buchungsstatus in deinem Nutzerkonto.",

      "notification.email": "Per E-Mail",

      "notification.email.desc": "Erlaubnis, dich per E-Mail zu kontaktieren.",

      "notification.finishRental.complain": "😕 Ich möchte mich beschweren.",

      "notification.finishRental.description": "Deine Fahrt wurde beendet. Du erhältst eine Übersicht per E-Mail.",

      "notification.finishRental.title": "Vielen Dank, dass du GETAWAY nutzt!",

      "notification.mail": "Per Post",

      "notification.mail.desc": "Erlaubnis, dich per Post zu kontaktieren.",

      "notification.push": "Per App-Mitteilung",

      "notification.push.desc": "Erlaubnis, dich per App-Mitteilung zu kontaktieren.",

      "notification.rental.dongle.finish.description": "Sobald die Stationsmitarbeiter Schlüssel und Fahrzeug entgegengenommen haben, wird deine Miete beendet und dir eine Übersicht per E-Mail geschickt.",

      "notification.sms": "Per SMS",

      "notification.sms.desc": "Erlaubnis, dich per SMS zu kontaktieren.",

      "now": "Jetzt",

      "obiEmployees.OBIMitarbeiter.pricing": "| Mietdauer    |  |\n| :-------- | -------: |\n| Mo-Sa, 8-20 Uhr | ~~19,99~~ 16,99 €/Std. (inkl. 20km/Std)|\n| Mo-Sa, 20-8 Uhr | ~~59,90~~ 50,92 € (inkl. 100km)|\n| Sa, 20 Uhr - Mo, 8 Uhr | ~~139,90~~ 102,00 € (inkl. 150km) |\n| An Feiertagen | ~~59,90~~ 50,92 €  € (inkl. 100km) |\n\n| Fahrtstrecke (inkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Je Mehrkilometer | 0,30 € |\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 1.500€ | 0 € (inklusive) |\n| max. 500€ | 11 €/Kal.Tag |\n| max. 150€ | 19 €/Kal.Tag |\n\n| Zusatzleistungen    |  |\n| :-------- | -------: |\n| Insassenschutz  | 10 €/Miete |\n| Rückgabe außerhalb der Zone | 25€ + 0,49€/100m (max. 500 EUR) |\n| Grenzübertrittsgebühr | 7,50 € / Tag |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand < 25%  | 15 € |\n| Tankstand < 10%  | 50 € |\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf) zu entnehmen.",

      "obiPublicUsers.OBIMitarbeiter.pricing": "| Mietdauer    |  |\n| :-------- | -------: |\n| Mo-Sa, 8-20 Uhr | ~~19,99~~ 16,99 €/Std. (inkl. 20km/Std)|\n| Mo-Sa, 20-8 Uhr | ~~59,90~~ 50,92 € (inkl. 100km)|\n| Sa, 20 Uhr - Mo, 8 Uhr | ~~139,90~~ 102,00 € (inkl. 150km) |\n| An Feiertagen | ~~59,90~~ 50,92 €  € (inkl. 100km) |\n\n| Fahrtstrecke (inkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Je Mehrkilometer | 0,30 € |\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 1.500€ | 0 € (inklusive) |\n| max. 500€ | 11 €/Kal.Tag |\n| max. 150€ | 19 €/Kal.Tag |\n\n| Zusatzleistungen    |  |\n| :-------- | -------: |\n| Insassenschutz  | 10 €/Miete |\n| Rückgabe außerhalb der Zone | 25€ + 0,49€/100m (max. 500 EUR) |\n| Grenzübertrittsgebühr | 7,50 € / Tag |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand < 25%  | 15 € |\n| Tankstand < 10%  | 50 € |\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf) zu entnehmen.",

      "obiPublicUsers.heyOBI.pricing": "| Mietdauer    |  |\n| :-------- | -------: |\n| Mo-Sa, 8-20 Uhr | ~~19,99~~ 16,99 €/Std. (inkl. 20km/Std)|\n| Mo-Sa, 20-8 Uhr | ~~59,90~~ 50,92 € (inkl. 100km)|\n| Sa, 20 Uhr - Mo, 8 Uhr | ~~139,90~~ 102,00 € (inkl. 150km) |\n| An Feiertagen | ~~59,90~~ 50,92 €  € (inkl. 100km) |\n\n| Fahrtstrecke (inkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Je Mehrkilometer | 0,30 € |\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 1.500€ | 0 € (inklusive) |\n| max. 500€ | 11 €/Kal.Tag |\n| max. 150€ | 19 €/Kal.Tag |\n\n| Zusatzleistungen    |  |\n| :-------- | -------: |\n| Insassenschutz  | 10 €/Miete |\n| Rückgabe außerhalb der Zone | 25€ + 0,49€/100m (max. 500 EUR) |\n| Grenzübertrittsgebühr | 7,50 € / Tag |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand < 25%  | 15 € |\n| Tankstand < 10%  | 50 € |\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf) zu entnehmen.",

      "obiPublicUsers.ubeeqo.pricing": "| Mietdauer    |  |\n| :-------- | -------: |\n| Mo-Sa, 8-20 Uhr | 19,99 €/Std. (inkl. 20km/Std)|\n| Mo-Sa, 20-8 Uhr | 59,90 € (inkl. 100km)|\n| Sa, 20 Uhr - Mo, 8 Uhr | 139,90 € (inkl. 150km) |\n| An Feiertagen | 59,90 € (inkl. 100km) |\n\n| Fahrtstrecke (inkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Je Mehrkilometer | 0,30 €|\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 1.500€ | 0 € (inklusive) |\n| max. 500€ | 11 €/Kal.Tag |\n| max. 150€ | 19 €/Kal.Tag |\n\n| Zusatzleistungen    |  |\n| :-------- | -------: |\n| Insassenschutz  | 10 €/Miete |\n| Rückgabe außerhalb der Zone  |  25€ + 0,49€/100m (max. 500 EUR) |\n| Grenzübertrittsgebühr | 7,50 € / Tag |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand < 25%  | 15 € |\n| Tankstand < 10%  | 50 € |\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf) zu entnehmen.",

      "offline": "OFF",

      "offlineService.report.title": "Auftragsübersicht",

      "ok": "OK",

      "online": "ON",

      "open.directions.title": "Navigation starten",

      "opennow": "Aktuell geöffnet",

      "openstoday": "Heute geöffnet",

      "openstomorrow": "Morgen geöffnet",

      "parking.description": "Gratis für 120 Min. Jede weitere kostet nur 5 Ct",

      "parking.hours": "Stunden",

      "parking.minutes": "Minuten",

      "parking.title": "Parkmodus",

      "password.token.expired": "Dieser Zurücksetzen-Link ist abgelaufen. Bitte setze dein Passwort erneut zurück.",

      "payment.3ds.title": "Kreditkarte authentifizieren",

      "payment.add.description": "Zur Verifizierung deines Nutzerkontos. Es erfolgt keine Abbuchung. Die Anmeldung ist und bleibt gratis.",

      "payment.add.error": "Fehlgeschlagen. Bitte versuche es erneut oder kontaktiere unser Service Team.",

      "payment.bank.owner.consent": "Ich handele in eigenem Namen.",

      "payment.billing.address.button": "Übernehmen",

      "payment.billing.address.company.contact": "Ansprechpartner",

      "payment.billing.address.company.name": "Firmenname",

      "payment.billing.address.description": "Lege fest, an wen die Rechnung adressiert werden soll.",

      "payment.billing.address.headline": "Adresse",

      "payment.billing.address.title": "Rechnungsdaten",

      "payment.billing.company.headline": "Firma",

      "payment.birthday.prefix": "geb. am",

      "payment.card.add.description": "Kredit-/Debitkarte hinzufügen und verifizieren",

      "payment.card.add.title": "Kredit-/Debitkarte hinzufügen",

      "payment.card.add.verifying": "Verifizierung läuft...",

      "payment.card.code.label": "Prüfziffern (CVC)",

      "payment.card.expiration.label": "Gültigkeit (mm/jj)",

      "payment.card.name.label": "Karteninhaber",

      "payment.card.number.label": "Kartennummer",

      "payment.footnote": "Mit der Auswahl des SEPA-Lastschriftverfahrens willige ich der Verarbeitung meiner Daten zur %s ein.",

      "payment.footnote.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",

      "payment.footnote.url.text": "Prüfung der Zahlungswahrscheinlichkeit",

      "payment.paypal.block.description": "Im nächsten Schritt wirst du zu Paypal weitergeleitet, um dein Paypal-Konto als Zahlmethode zu hinterlegen.",

      "payment.paypal.block.title": "Paypal hinzufügen",

      "payment.paypal.block.yes": "Fortfahren",

      "payment.postingText": "Danke.",

      "payment.postingTextReference": "%s",

      "payment.profile.billing.add": "Rechnungsadresse ändern",

      "payment.profile.billing.title": "RECHNUNGSDATEN",

      "payment.profile.company.add": "Firmeninformationen hinterlegen",

      "payment.profile.default": "Als Standard festlegen",

      "payment.profile.delete": "Zahlungsprofil entfernen",

      "payment.profile.option.business": "Business",

      "payment.profile.option.personal": "Privat",

      "payment.profile.reset": "Rechnungsdaten zurücksetzen",

      "payment.profile.title": "Zahlungsprofil",

      "payment.profile.type": "Art des Profils",

      "payment.secured.success": "Authentifizierung erfolgreich bestanden, danke!",

      "payment.sepa.description": "SEPA-Lastschrift",

      "payment.shopperStatement": "mietsmart.app - %s",

      "payment.shopperStatement.noTrip": "mietsmart.app (im Auftrag der S&S Car Service, Inh. Steffen Friedrich)",

      "paypal.desc": "Füge eine unserer sicheren und beliebten Zahlungsmittel hinzu.",

      "paypal.placeholder": "Paypal-Konto",

      "paypal.title": "Paypal hinzufügen",

      "per.calday": "/Kal.Tag",

      "per.km": "je km",

      "permission.settings.button": "ERLAUBEN",

      "personal": "privat",

      "phone.pairing.message": "Klicke hier, um dieses Handy zu verifizieren.",

      "phone.resend.code": "Erneut senden",

      "phone.verification.resend.success": "Bestätigungscode ist erneut versendet worden.",

      "phone.verify.code.apply": "CODE ÜBERNEHMEN",

      "phone.verify.code.desc": "Wir haben dir soeben einen 4-stelligen Code per SMS an %s geschickt. Bitte trage ihn jetzt ein.",

      "phone.verify.code.title": "SMS-Bestätigungscode eingeben",

      "phone.verify.desc": "Trage deine Handy-Nr ein. Du erhältst im Anschluss einen Bestätigungscode per SMS.",

      "phone.verify.mobile.phone.text": "Handy-Nummer",

      "phone.verify.send.button": "Bestätigen",

      "phone.verify.send.code": "SMS-Code anfordern",

      "phone.verify.sms": "{{code}} lautet dein GETAWAY Bestätigungscode.",

      "phone.verify.title": "Handy-Nr hinzufügen",

      "phone.wrong.format.error": "Bitte gebe die richtige Telefonnummer ein",

      "phoneNumberVerification.authy.custom_message": "{{code}} lautet dein GETAWAY Bestätigungscode.",

      "phonenumber.verification.error": "Diese Handynummer ist ungültig oder bereits einem aktivem Nutzerkonto hinterlegt. Bitte verwende eine andere oder melde dich mit dem bestehenden Nutzerkonto an.",

      "photo": "Foto",

      "photo.continue": "FORTFAHREN",

      "photo.library": "Fotos",

      "photo.source.dialog.title": "Fotos hochladen via",

      "photos": "Fotos",

      "photos.add": "Fotos vom Auto",

      "photos.add.button": "Foto hinzufügen",

      "photos.add.description": "Von Vorne, von der Seite und vom Innenraum. Je ansprechender, desto mehr Mieteinnahmen 🤩",

      "photos.desc": "Ein Foto sagt mehr als Tausend Wort. Füge jetzt mindestens eins hinzu.",

      "photos.permission": "Bitte erlaube GETAWAY Zugriff auf deine Fotos in deinen Einstellungen.",

      "photos.permission.details": "Um fortzufahren und Fotos hochzuladen, erlaube Mietsmart jetzt Zugang zu deinen Fotos.	",

      "photos.permission.no": "Nicht jetzt",

      "photos.permission.title": "Zugang zu Fotos",

      "photos.permission.yes": "Erlauben",

      "photos.photo": "Foto",

      "photos.photo.reached.max": "Du hast die Maximalanzahl an Fotos erreicht.",

      "photos.skip.no.photos": "Du kannst Fotos jederzeit ergänzen.",

      "photos.skip.photos": "Du hast mindestens ein Foto hochgeladen.",

      "picker.placeholder": "Wähle aus ...",

      "price": "Preis",

      "price.details.footnote": "*Alle Preise inkl. MwSt. Inklusivkilometer werden für den gesamten Mietzeitraum summiert und verrechnet. Die vollständige Aufstellung aller Entgelte sind der %s zu entnehmen.",

      "price.details.footnote.url.text": "aktuellen Preisliste",

      "price.distance.100.prefix": "bis 100 km:",

      "price.distance.101.prefix": "ab 101. km:",

      "price.distance.headline": "Fahrtstrecke",

      "price.duration.headline": "Mietdauer",

      "price.duration.headline.mo-fr": "Mietdauer (Mo-Fri)",

      "price.duration.headline.mo-sa": "Mietdauer",

      "price.duration.headline.sa-mo": "Sa, 18:00 - Mo, 08:00",

      "price.duration.headline.sa-so": "Mietdauer (Sa-So)",

      "price.duration.holiday": "Feiertags: %s/Kal.Tag (inkl. 100km)",

      "price.duration.holiday.headline": "Feiertag",

      "price.duration.mo-fr.night.prefix": "21 - 9:30 Uhr:  ",

      "price.duration.mo-fr.prefix": "9:30 - 21 Uhr: %s",

      "price.duration.mo-sa.daily": "Mo-Sa, 8-18 Uhr: %s/Kal.Tag (inkl. 100 km)        ",

      "price.duration.mo-sa.hourly": "Mo-Sa 8 - 20: %s/Std (inkl. 20 km/Std), max. %s/Kal.Tag (inkl. 100 km)",

      "price.duration.mo-sa.night": "Mo-Sa, außerhalb der Öffnungszeiten: %s (inkl. 100km)",

      "price.duration.sa-mo": "Sa, 18 Uhr - Mo, 8 Uhr: %s (inkl. 150 km)",

      "price.duration.sa-so.night.prefix": "Sa, 18 - Mo, 9:30 Uhr:  ",

      "price.duration.sa-so.prefix": "Sa, 9:30 - 18 Uhr:  %s  (1. Std. gratis)",

      "price.insurance.150.prefix": "max. 150€:",

      "price.insurance.1500.prefix": "max. 1.500€: Inkl.",

      "price.insurance.15000.prefix": "max. 15.000€:  inkl.",

      "price.insurance.151.prefix": "max. 150€:",

      "price.insurance.500.prefix": "max. 500€:",

      "price.insurance.501.prefix": "max. 500€:",

      "price.insurance.650.prefix": "max. 650€:  inkl.",

      "price.insurance.750.prefix": "max. 750€:  inkl.",

      "price.insurance.headline": "Selbstbeteiligung (je Schadensfall)",

      "price.insurance.young.headline": "Selbstbeteiligung (Fahranfänger*)",

      "price.return.service.headline": "Rückhol-Service (bei Rückgabe)",

      "price.tank.10.prefix": "Tankstand <10%:",

      "price.tank.25.prefix": "Tankstand <25%: ",

      "price.tank.headline": "Tank-Service (bei Rückgabe)",

      "price.weekend.surcharge.prefix": "Wochenendzuschlag (Fr-So):",

      "pricedetails.basic.title": "Zahle nur, was du verfährst",

      "pricedetails.distancePackages.remaining": "Ab %@. Kilometer",

      "pricedetails.example.day": "Ein Tag (24 Std.)",

      "pricedetails.example.description": "inkl. %s km* und Kraftstoff",

      "pricedetails.example.hour": "Eine Stunde",

      "pricedetails.example.kilometer": "Ein Kilometer",

      "pricedetails.example.kilometer.description": "Inkl. Kraftstoff",

      "pricedetails.example.month": "Ein Monat (28 Tage)",

      "pricedetails.example.title": "Preisbeispiele (inkl. Kraftstoff & Versicherung)",

      "pricedetails.example.value.description": "entspricht %s je Min.",

      "pricedetails.example.value.description.day": "Ø %s €/Kal.Tag",

      "pricedetails.example.value.description.owner": "du verdienst %s",

      "pricedetails.example.week": "Eine Woche",

      "pricedetails.longterm.description.oneday": "inkl. 80 km je 24 Std.*",

      "pricedetails.longterm.footnote": "*Jeder weitere Kilometer bzw. weitere Standzeit wird nutzungsgenau abgerechnet.\n\nDir wird automatisch das günstigste Angebot berechnet. Eine Übersicht aller Entgelte findest du %@.",

      "pricedetails.longterm.footnote.url": "https://get-a-way.com/agb/entgelte-und-gebuehren/",

      "pricedetails.longterm.footnote.url.text": "hier",

      "pricedetails.longterm.tier1.description": "inkl. 80 km* und Kraftstoff",

      "pricedetails.longterm.tier1.title": "Ab dem 1. Tag",

      "pricedetails.longterm.tier1.value.description": "je 24 Std.",

      "pricedetails.longterm.tier2.description": "inkl. 40 km* und Kraftstoff",

      "pricedetails.longterm.tier2.title": "Ab dem 4. Tag",

      "pricedetails.longterm.tier2.value.description": "je 24 Std.",

      "pricedetails.longterm.tier3.description": "inkl. 20 km* und Kraftstoff",

      "pricedetails.longterm.tier3.title": "Ab dem 8. Tag",

      "pricedetails.longterm.tier3.value.description": "je 24 Std.",

      "pricedetails.longterm.title": "Ab %s.",

      "pricedetails.longterm.value.description": "je %s",

      "pricedetails.parking.freeminutes": "%s Min. gratis je km",

      "pricedetails.parking.title": "Standzeit",

      "pricedetails.parking.value": "danach %s je Min.",

      "pricedetails.pricePerKm.title": "Gefahrene Strecke",

      "pricedetails.pricePerKm.value": "%s je km",

      "pricedetails.specialoffers.title": "TAGESRABATTE",

      "pricedetails.title": "Preisinfo",

      "pricing.CharterlinePricing.url": "https://api.get-a-way.com/pricing/5d170e35f892b13d8468f283/pdf",

      "pricing.HagebauMarktPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/58944_Preisliste+Ubeeqo_Hagebaumarkt_A4_v02.pdf",

      "pricing.HeyOBIPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",

      "pricing.IkeaInnsbruck.duration.after_first_n_hours": "9:30 - 21:00 Uhr (ab 5. Std): %s/Std",

      "pricing.IkeaInnsbruck.duration.first_n_hours": "9:30 - 21:00 Uhr (1. bis 4. Std): %s/Std",

      "pricing.IkeaInnsbruck.duration.hourly": "9:30 Uhr - 21:00 Uhr: %s/Std",

      "pricing.IkeaInnsbruck.duration.night": "21:00 Uhr - 9:30 Uhr: %s",

      "pricing.IkeaInnsbruck.duration.weekday": "Mietdauer (Mo-Fri)",

      "pricing.IkeaInnsbruck.duration.weekend": "Mietdauer (Sa-So)",

      "pricing.IkeaInnsbruck.duration.weekend.sat": "Sa, 9:30 Uhr - 18:00 Uhr : %s",

      "pricing.IkeaInnsbruck.duration.weekend.sat.after_first_n_hours": "Sa, 9:30 - 18:00 Uhr (ab 5. Std): %s/Std",

      "pricing.IkeaInnsbruck.duration.weekend.sat.first_n_hours": "Sa, 9:30 - 18:00 Uhr (1. bis 4. Std): %s/Std ",

      "pricing.IkeaInnsbruck.duration.weekend.so": "Sa, 18 - Mo, 9:30 Uhr: %s",

      "pricing.IkeaInnsbruckPricing.url": "https://api.get-a-way.com/pricing/5d8f8cbba3dd8910deb5d7cf/pdf",

      "pricing.IkeaMainPricing.url": "https://api.get-a-way.com/pricing/5d8f8cbba3dd8910deb5d7ce/pdf",

      "pricing.MISSmartPricing.url": "https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf",

      "pricing.MISSmartSpainPricing.url": "https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf",

      "pricing.MISTransporterPricing.url": "https://api.get-a-way.com/pricing/61cb2b425071913188afebcf/pdf",

      "pricing.OBIEmployeesPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_OBI_Employees.pdf",

      "pricing.OBIPublicUsersPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",

      "pricing.OBIUsersPricing.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",

      "pricing.charterline.distance.100": "bis 100km: %s/km",

      "pricing.charterline.distance.101": "ab dem 101. km: %s/km",

      "pricing.charterline.duration.hourly": "%s/Std (max. %s/Kal.Tag)",

      "pricing.charterline.duration.weekend": "Fr-So: +2,00 €/Std (max. 12,00 €/Kal.Tag)",

      "pricing.degressive.distance": "${format:euro(price.driversPricePerKm)}/km (ab dem ${price.firstDiscountedKm}. km nur ${format:euro(price.firstDiscountedPrice)})",

      "pricing.degressive.parking": "${price.freeParkingMinutesPerKm} Freiminuten/km, danach ${format:cents(price.pricePerParkingMinute)} Ct/Min.",

      "pricing.distance": "Fahrtstrecke (inkl. Kraftstoff)",

      "pricing.distance.details": "%s/km",

      "pricing.distance.excess": "Fahrtstrecke (je Mehrkilometer)",

      "pricing.distance.noFuel": "Fahrtstrecke",

      "pricing.duration": "Mietdauer",

      "pricing.duration.hourly": "%s/Std",

      "pricing.expert.distance": "%s/km",

      "pricing.expert.duration": "%s/Std",

      "pricing.expert.duration.hourly": "Mo-Fr 9-19 Uhr, Sa 9-16 Uhr: %s/Std, max. %s/Kal.Tag",

      "pricing.expert.duration.night": "Mo-Sa, 19 Uhr - 9 Uhr: %s",

      "pricing.expert.duration.weekend": "Sa 16:00 Uhr - Mo 09:00 Uhr: %s",

      "pricing.fee.crossBorder": "Auslandsfahrten",

      "pricing.fee.crossBorder.details": "%s pro Tag für erlaubte Länder, max. %s pro Miete",

      "pricing.fee.driver.injury.protection": "Insassenschutz: %s",

      "pricing.fee.driver.protection": "Zusatzleistungen",

      "pricing.fee.noShow": "Stornierungsgebühr",

      "pricing.fee.noShow.details": "Bis zu %s (abhängig von der geplanten Mietdauer)",

      "pricing.fee.return": "Rückbring-Service (bei Rückgabe)",

      "pricing.fee.return.details": "%s + %s/100m",

      "pricing.fee.tank": "Tank-Service (bei Rückgabe)",

      "pricing.fee.tank.fuel.consumption": "geringerer Tankstand: %s je Liter",

      "pricing.fee.tank.low.10": "Tankstand <10%:",

      "pricing.fee.tank.low.25": "Tankstand <25%:",

      "pricing.heyOBI.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",

      "pricing.ikea.duration.after_first_n_hours": "ab 5. Stunde: %s/Std",

      "pricing.ikea.duration.first_n_hours": "1. bis 4. Stunde: %s/Std",

      "pricing.insurance": "Selbstbeteiligung (je Schadensfall)",

      "pricing.insurance.table": "max. %s€: %s€/Kal.Tag",

      "pricing.insurance.table.daily": "max. %s€: %s€/KalTag",

      "pricing.insurance.table.free": "max. %s€: inklusive",

      "pricing.insurance.young": "Selbstbehalt (Young Driver)",

      "pricing.longterm.distance": "${format:euro(price.driversPricePerKm)}/km",

      "pricing.longterm.parking": "${price.freeParkingMinutesPerKm} Freiminuten/km, danach ${format:cents(price.pricePerParkingMinute)} Ct/Min.",

      "pricing.longtermanddistance.distance": "${format:euro(price.driversPricePerKm)}/km (ab dem ${price.firstDiscountedKm + 1}. km nur ${format:euro(price.firstDiscountedPrice)})",

      "pricing.longtermanddistance.parking": "${price.freeParkingMinutesPerKm} Freiminuten/km, danach ${format:cents(price.pricePerParkingMinute)} Ct/Min.",

      "pricing.mba.duration": "Mietdauer (Stündlich)",

      "pricing.mba.duration.package": "Mietdauer (Pakete)",

      "pricing.mba.duration.transporter.1day": "1 Tag: %s",

      "pricing.mba.duration.transporter.3days": "3 Tage: %s",

      "pricing.mba.duration.transporter.3days.per_day": "3 Tage: %s/Tag	",

      "pricing.mba.duration.transporter.6hours": "6 Stunden: %s",

      "pricing.mba.duration.transporter.extra_hours": "%s/zusätzliche Stunde",

      "pricing.mba.duration.transporter.hourly": "%s/Stunde",

      "pricing.mba.duration.transporter.hourly_weekend": "+%s/Std (max. %s/Tag)",

      "pricing.mba.duration.weekend": "Wochenendzuschlag (Fr-So):	",

      "pricing.mba.passenger.duration.after.30": "Mietdauer (30 Tage und mehr)",

      "pricing.mba.passenger.duration.before.30": "Mietdauer (Bis 30 Tage)",

      "pricing.mba.passenger.duration.daily": "Tag 1 bis 3: %s/Tag",

      "pricing.mba.passenger.duration.daily.plus3": "Ab Tag 4: %s/Tag",

      "pricing.mba.passenger.duration.daily.plus30": "ab Tag 1: %s/Tag",

      "pricing.mba.passenger.included_distance": "Inklusiv-km: %s km/Tag (%s/Extra-km)",

      "pricing.mba.transporter.distance": "%s/km",

      "pricing.mba.transporter.extra_distance": "Extra-km: %s/km",

      "pricing.mba.transporter.included_distance": "Inklusiv-km: %s km/Std (max. %s km/Tag)",

      "pricing.mis.distance": "inklusive 50 km/24Std. (%s/Mehr-Km)",

      "pricing.mis.duration.after.30": "Mietdauer (mehr 30 Tage)",

      "pricing.mis.duration.before.30": "Mietdauer (von Tag 1 - 30)",

      "pricing.mis.duration.daily": "Tag 1 bis 3:  %s/24Std.",

      "pricing.mis.duration.daily.plus3": "Ab dem 4. Tag: %s/24Std.",

      "pricing.mis.duration.daily.plus30": "ab Tag 1: %s/24Std.",

      "pricing.mis.duration.transporter.hourly": "ab der 4. Stunde:  %s/Std. (max. %s/24Std.)",

      "pricing.mis.duration.transporter.hourly.lt3h": "bis zu 3 Stunden: %s",

      "pricing.mis.spain.daily.plus1": "danach  %s/24Std",

      "pricing.mis.spain.duration.daily": "erste 24Std %s",

      "pricing.mis.transporter.distance": "inklusive 50 km/24Std. (%s/Mehr-Km)",

      "pricing.obi.distance": "%s/ Mehr-Km",

      "pricing.obi.duration.holiday": "An Feiertagen: %s (inkl. 100km)",

      "pricing.obi.duration.hourly": "Mo-Sa, 8-20 Uhr: %s/Std (inkl. 20 km/Std), max. %s/Kal.Tag (inkl. 100 km)",

      "pricing.obi.duration.night": "Mo-Sa, 20 Uhr - 8 Uhr: %s (inkl. 100km)",

      "pricing.obi.duration.weekend": "Sa 20:00 Uhr - Mo 08:00 Uhr: %s (inkl. 150km)",

      "pricing.obiEmployees.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_OBI_Employees.pdf",

      "pricing.obiPublicUsers.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",

      "pricing.obiUsers.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_charterline/CHA_UBQ_OBI_v211118_4_Preisliste_endCustomer.pdf",

      "pricing.serviceTrip.distance": ".",

      "pricing.serviceTrip.parking": ".",

      "pricing.spp.duration.firstNHours": "Bis 4 Stunden: %s/Std.",

      "pricing.spp.duration.hourly": "Ab 5. Stunde: %s/Std.",

      "pricing.spp.duration.night": "21 Uhr - 9:30 Uhr: %s/Nacht",

      "pricing.spp.duration.weekend": "Sa, 18 Uhr - Mo, 9:30 Uhr: %s",

      "pricing.static.distance": "${price.driversPricePerKm}/km",

      "pricing.static.parking": "${price.freeParkingMinutes} Freiminuten/km, danach ${format:cents(price.pricePerParkingMinute)} Ct/Min.",

      "pricing.title": "Preisangaben",

      "privacy.text": "DEINE DATEN SIND PRIVAT & GESCHÜTZT",

      "privacy.url": "https://mietsmart.app/privacy",

      "profile-deposit.warning.btn": "Kaution hinterlegen",

      "profile-deposit.warning.desc": "Um diese Zahlungsmethode freizuschalten, hinterlege bitte jetzt eine einmalige Kaution.",

      "profile.blocked.msg.cC": "Die Freischaltung dieser Zahlungsmethode ist fehlgeschlagen. Leider können wir dir derzeit keine Alternative anbieten, um die Registrierung abzuschließen.",

      "profile.blocked.msg.creditCheck": "Die Freischaltung dieser Zahlungsmethode ist fehlgeschlagen. Die Bonitätsprüfung ergab keine Übereinstimmung. Leider können wir Ihnen derzeit keine Alternative anbieten, um die Registrierung abzuschließen.\n\nWir bitten, von weiteren Registrierungsversuchen abzusehen.",

      "profile.blocked.msg.d": "Jeder Nutzer darf nur ein Nutzerkonto haben.\n \nBitte melde Dich mit deinem initialen Nutzerkonto an. Falls Du dein Passwort vergessen hast, kannst Du es in der Anmeldemaske zurücksetzen.",

      "profile.blocked.msg.duplicate": "Jeder Nutzer darf nur ein Nutzerkonto haben.\nBitte lösche die doppelten Nutzerkonten und kontaktiere uns im Anschluss.",

      "profile.blocked.msg.f": "Dieses Nutzerkonto ist temporär deaktiviert. Bei Rückfragen kontaktiere bitte unser Service Team.",

      "profile.blocked.msg.fraud": "Jeder Nutzer darf nur ein Nutzerkonto haben. Nach einer internen Prüfung können wir deine Registrierung leider nicht abschließen.\n\nBitte sieh von weiteren Registrierungsversuchen ab.\nFalls die Deaktivierung ein Versehen war, wende dich bitte an unser Serviceteam.\"",

      "profile.blocked.msg.originalactive": "Jeder Nutzer darf nur ein Nutzerkonto haben.\n\nBitte melden Sie sich mit Ihrem initialen Nutzerkonto an. Falls Sie Ihr Passwort vergessen haben, können Sie es in der Anmeldemaske zurücksetzen.",

      "profile.blocked.title.d": "Nutzerkonto temporär deaktiviert. ",

      "profile.blocked.title.duplicate": "Nutzerkonto temporär deaktiviert. ",

      "profile.blocked.title.f": "Nutzerkonto temporär deaktiviert. ",

      "profile.blocked.title.fraud": "Nutzerkonto temporär deaktiviert. ",

      "profile.blocked.title.originalactive": "Nutzerkonto temporär deaktiviert. ",

      "profile.completed.msg": "Schaue unter \"Log-in Daten\", wo du dich mit welchen Daten anmelden kannst.",

      "profile.completed.org.msg": "Schaue unter \"Log-in Daten\", wo du dich mit welchen Daten anmelden kannst.",

      "profile.completed.org.title": "Registrierung abgeschlossen",

      "profile.completed.title": "Registrierung abgeschlossen",

      "profile.gdpr.dialog.more": "Mehr zum Datenschutz",

      "profile.gdpr.dialog.more.url": "https://mietsmart.app/privacy",

      "profile.gdpr.dialog.msg": "Du kannst hier deine personenbezogenen Daten herunterladen.\n\nZum Schutz deiner Privatsphäre wirst du zunächst gebeten, deine Anfrage zu bestätigen, indem du einen Bestätigungscode eingibst, der dir per E-Mail geschickt wird. Im Anschluss wird der Download gestartet.\n\nBitte beachte, dass es sich um die personenbezogenen Daten im Zusammenhang mit der Nutzung von %s handelt. Etwaige Datenverarbeitungen im Zusammenhang mit anderen Services, sind auf der Datenschutzseite von %s in Erfahrung zu bringen.",

      "profile.gdpr.dialog.request": "Jetzt anfordern",

      "profile.gdpr.dialog.title": "DSGVO-Rechte nach Auskunft und Übertragbarkeit wahrnehmen",

      "profile.gdpr.resend": "Der eingegebene Code ist nicht korrekt.",

      "profile.gdpr.saved": "Datei wurde in Downloads gespeichert.",

      "profile.payment.type.business": "Business",

      "profile.payment.type.personal": "Privat",

      "profile.tab.account": "Meine Daten",

      "profile.tab.services": "Log-in-Daten",

      "profile.tx.open.pay": "Betrag begleichen",

      "profile.tx.open.title": "Ausstehende Beträge",

      "profile.verify.email.sent": "Bestätigungsmail wurde versendet",

      "push.permission": "Bitte aktiviere jetzt App-Mitteilungen",

      "push.permission.details": "Erlaube GETAWAY dir App-Mitteilungen zu senden, um dich über den aktuellen Status deines Autos bzw. deiner Miete informieren zu können.",

      "push.permission.no": "Später",

      "push.permission.title": "Erlaube Mitteilungen",

      "push.permission.yes": "Erlauben",

      "qrCamera.title": "QR-Code scannen",

      "referFriend.code.error": "Bitte trage einen gültigen Einladungscode ein",

      "refuel": "Betankung",

      "refuel.payment.subTitle": "Bezahlung",

      "refuel.payment.title": "Kreditkarten PIN",

      "refuel.subTitle": "Bitte nimm die GETAWAY-Tankkarte aus dem Handschuhfach und nutze die angezeigte PIN. Die Quittung brauchst du nicht aufzuheben.",

      "refuel.title": "Tanke gratis",

      "rent.car.driver.gear.restricted": "Du kannst dieses Auto nicht mieten, da deine Fahrerlaubnis nur für Automatikgetriebe gilt.",

      "rent.car.driver.license.baned": "Du hast ein Fahrverbot",

      "rent.car.driver.license.open": "Deine Fahrerlaubnis ist nicht geprüft",

      "rental": "Miete",

      "rental.action.damages": "Schaden melden",

      "rental.action.finish": "Miete beenden",

      "rental.action.fuel": "Tanken",

      "rental.add.damage.wizard.description": "Protokolliere alle aktuellen Mängel, um eventuelle Neuschäden besser identifizieren zu können.",

      "rental.allaround.add": "Rundum-Fotos aufnehmen",

      "rental.allarouund.add": "Rundum-Fotos aufnehmen",

      "rental.backby.button": "Übernehmen",

      "rental.backby.update": "Ändern",

      "rental.backby.update.desc": "Aktuell: %s ",

      "rental.backby.update.other": "anzeigen",

      "rental.backby.update.title": "Rückgabezeit ändern",

      "rental.backby.update.until": "Verfügbar bis",

      "rental.cancel": "Abbrechen",

      "rental.cancel.handover.ok": "Mietvorgang beenden",

      "rental.cancel.handover.title": "Du bist dabei, den aktuellen Vorgang abzubrechen.",

      "rental.carCheck.button": "Fortfahren",

      "rental.carCheck.cancel": "Beenden",

      "rental.carCheck.footnote": "Dies schützt dich im Schadensfalle vor zu hohen Kosten und ist nötig, um den Motor starten zu können.",

      "rental.carCheck.header.desc": "Nimm jetzt je ein Foto aus vier verschiedenen Perspektiven auf, um den Motor starten zu können.",

      "rental.carCheck.header.title": "Rundum-Check",

      "rental.checkBelongings.finish.message": "Bitte achte auf deine persönlichen Sachen, bevor du fortfährst.",

      "rental.checkBelongings.finish.messege": "Bitte achte auf deine persönlichen Sachen, bevor du fortfährst.",

      "rental.checkBelongings.finish.title": "Nichts vergessen?",

      "rental.damage.added": "Der Schaden/Mangel ist um %@ erfolgreich protokolliert worden.",

      "rental.dialog.service.error": "Bitte wähle den durchgeführten Service und mindestens ein Foto aus.",

      "rental.dongle.end.title": "Miete beenden",

      "rental.dongle.handover.description": "Bitte gehe nun die Checkliste durch, um den Mietvorgang zu beenden",

      "rental.dongle.key.return.description": "Bitte übergebe Fahrzeugschlüssel und -papiere (inkl. der Tankkarte) am Ende deiner Miete einem Stationsmitarbeiter am Express-Schalter. Alternativ wirf diese in den entsprechend ausgeschilderten Nachteinwurf der Station.",

      "rental.dongle.key.return.title": "Schlüssel & Papiere zurückgeben",

      "rental.driving.backBy": "Rückgabezeit",

      "rental.driving.damages.add": "Melden",

      "rental.driving.greeting": "Gute Fahrt!",

      "rental.driving.tank": "Tankstand",

      "rental.extra.F3023.title": "Insassenschutz: 10,00 €",

      "rental.finish.button": "Beenden & verriegeln",

      "rental.finish.success": "Dein Mietvorgang ist erfolgreich beendet worden. Habe einen schönen Tag!",

      "rental.fuelScreen.title": "Bargeldlos Tanken",

      "rental.fuelSettings.desc": "Tanke bequem & bargeldlos mit der Tankkarte aus dem Handschuhfach an allen Partnertankstellen.",

      "rental.fuelSettings.fuelCard": "Tankkarte: ",

      "rental.fuelSettings.fuelNote": "Keine Premium-Kraftstoffe tanken. Andernfalls können Entgelte fällig werden.",

      "rental.fuelSettings.fuelPin": "PIN:  ",

      "rental.fuelSettings.gasStation.desc": "Bitte vergewissere dich vor dem Tankvorgang, dass die Tankstelle die Tankkarte akzeptiert.",

      "rental.fuelSettings.instruction": "Die Quittung muss nicht aufbewahrt werden.\nIm Anschluss lege bitte die Tankkarte in die dafür vorgesehene Halterung im Handschuhfach zurück.",

      "rental.fuelSettings.navigate": "Karte",

      "rental.fuelSettings.stepOne": "1. Partnertankstelle anfahren",

      "rental.fuelSettings.stepThree": "3. Mit Tankkarte bezahlen",

      "rental.fuelSettings.stepTwo": "2. Kraftstoff tanken (keine Premiumsorte)",

      "rental.fuelSettings.title": "Kostenlos Tanken",

      "rental.fuelSettings.where": "Wo?",

      "rental.fuelstation": "Tankestelle",

      "rental.handover.description": "Verriegele das Fahrzeug von außen mit deinem Handy, um die Miete zu beenden.",

      "rental.handover.header": "Danke,",

      "rental.handover.lock.title": "Miete beenden",

      "rental.handover.title": "Miete beenden",

      "rental.history.header": "Miete",

      "rental.history.invoice": "Rechnung zur Miete anzeigen",

      "rental.immobilizer.allaround.msg": "Die Motorsperre wird deaktiviert, sobald die Rundum-Fotos aufgenommen und hochgeladen wurden.",

      "rental.immobilizer.blocked": "Gesperrt",

      "rental.immobilizer.payment.msg": "Dies ist keine Panne. Die Motorsperre ist aufgrund eines Zahlungsproblems aktiviert. Bitte hinterlege eine belastbare Zahlungsmethode und kontaktiere unser Service Team, um den Motor wieder zu entsperren.",

      "rental.immobilizer.title": "Motor-/Wegfahrsperre",

      "rental.insurance.change.error": "Für diese Fahrt ist keine Versicherungsoption verfügbar.",

      "rental.insurance.dialog.button": "VERSICHERUNG ANRUFEN",

      "rental.insurance.dialog.description": "Für unmittelbare Hilfe rufe jetzt bitte unseren Versicherungspartner \"Gothaer Versicherung\" an und beziehe dich auf folgende Versicherungsschein-Nummer:",

      "rental.insurance.dialog.footer": "24/7 Schadenservicecenter unter der Festnetznummer +49 30 5508 81508",

      "rental.insurance.dialog.number": "02.925.777 777",

      "rental.insurance.dialog.number.description": "Versicherungsschein-Nr",

      "rental.insurance.dialog.phone.number": "+4930550881508",

      "rental.insurance.dialog.title": "Soforthilfe",

      "rental.insurance.gothaer.dialog.description": "Für unmittelbare Hilfe rufe jetzt bitte unseren Versicherungspartner \"Gothaer Versicherung\" an und beziehe dich auf folgende Versicherungsschein-Nummer:",

      "rental.insurance.gothaer.dialog.footer": "24/7 Schadenservicecenter unter der Festnetznummer +49 30 5508 81508",

      "rental.insurance.label": "Selbstbeteiligung",

      "rental.insurance.settings.footnote": "Im Schadensfall kann es schnell teuer werden. Begrenze dein Haftungsrisiko und verringere deine Selbstbeteiligung.",

      "rental.insurance.settings.more.url.text": "Selbstbeteiligung",

      "rental.insurances.info.url": "https://getaway.app/insurance",

      "rental.internet.disconnected.message": "Es kann keine Verbindung hergestellt werden. Bitte stelle sicher, dass du online bist. Bei Schwierigkeiten kontaktiere bitte unser Service Team.",

      "rental.internet.disconnected.title": "Keine Internetverbindung",

      "rental.keyOut.button.title": "Ich habe den Autoschlüssel",

      "rental.opened.damages.title": "Schadenprotokoll",

      "rental.opened.dongle.continue": "Weiter",

      "rental.opened.header.desc": "Wenn das Fahrzeug keine neuen Schäden aufweist, dann starte jetzt den Motor.",

      "rental.opened.header.title": "Starte den Motor",

      "rental.opened.instruction": "Bitte den Schlüssel der Halterung entnehmen.",

      "rental.opened.key.option": "Wo finde ich den Fzg-Schlüssel?",

      "rental.opened.troubleshoot.door": "Türen verschlossen?",

      "rental.opened.troubleshoot.engine": "Motor startet nicht?",

      "rental.opened.troubleshoot.key": "Wo finde ich den Schlüssel?",

      "rental.opened.troubleshoot.options": "Optionen",

      "rental.opened.troubleshoot.title": "Können wir helfen?",

      "rental.opened.troubleshoot.unblock": "Freischalten",

      "rental.opened.troubleshoot.unlock": "Entriegeln",

      "rental.opened.video.description": "Die Schlüsselhalterung befindet sich im Handschuhfach.",

      "rental.opened.video.title": "Entnehme den Schlüssel",

      "rental.parking.findCar": "Finde mein Auto",

      "rental.prompty.end": "Miete beenden",

      "rental.rate.negative": "NEIN",

      "rental.rate.positive": "JA",

      "rental.rate.title": "Hat dir das Auto gefallen?",

      "rental.refuel.warning": "Vor Rückgabe bitte mit bereitgestellter Tankkarte tanken. Andernfalls können Gebühren anfallen.",

      "rental.reserved.desc.connected": "Mit dem Entriegeln startest du den kostenpflichtigen Mietvorgang.",

      "rental.reserved.desc.disabled": "Gehe jetzt zum Fahrzeug, um es mit deinem Handy zu entriegeln.",

      "rental.reserved.desc.searching": "Gehe jetzt zum Fahrzeug, um es mit deinem Handy zu entriegeln.",

      "rental.reserved.header.connected": "Bitte entriegeln",

      "rental.reserved.header.disabled": "Verringere die Distanz ",

      "rental.reserved.header.searching": "Verringere die Distanz ",

      "rental.reserved.location.enable": "Erlaube Zugriff auf Standort",

      "rental.returnlocation": "Rückgabeort",

      "rental.returntime.late": "%@ zu spät",

      "rental.review.negative.notification": "Vielen Dank für dein Feedback...",

      "rental.section.booked": "Gebucht",

      "rental.section.locked": "Verriegelt",

      "rental.section.reserved": "Reserviert",

      "rental.section.return": "Rückgabe",

      "rental.section.unlocked": "Entriegelt",

      "rental.service.note": "Zusätzlicher Hinweis (optional)",

      "rental.service.report.button": "Servicebericht absenden",

      "rental.service.report.submit.success": "Dein Bericht wurde übermittelt",

      "rental.service.report.title": "Servicebericht",

      "rental.settings.insurance.more": "Mehr Infos zur Selbstbeteiligung",

      "rental.settings.insurance.title": "Reduziere deine Selbstbeteiligung!",

      "rental.settings.title": "Mieteinstellung",

      "rental.slider.lock": "VERRIEGELN",

      "rental.slider.unlock": "Miete starten",

      "rental.startEngine.decr": "Sofern das Auto keine neuen Schäden (siehe Schadensprotokoll) aufweist, starte bitte jetzt den Motor. Hab eine sichere Fahrt!",

      "rental.startEngine.title": "Starte jetzt den Motor!",

      "rental.status": "Status",

      "rental.title": "Miete %s",

      "rental.vehicle.booking.link": "Verfügbarkeit",

      "rental.vehicle.booking.prelink": "Nächste Buchung in %s ,",

      "rental.walkthrough.button": "Ok",

      "rental.walkthrough.page1.description": "Im Schadensfall kann es schnell teuer werden. Wähle deine gewünschte Haftungsbegrenzung (Selbstbeteiligung) bevor du das Fahrzeug mit deinem Handy entriegelst.",

      "rental.walkthrough.page1.title": "Reduziere deine Selbstbeteiligung",

      "rental.walkthrough.page2.description": "Bringe das Fahrzeug in die farblich hervorgehobene Rückgabezone mit einem Tankstand von mind. 25% zurück und verriegele es mit deinem Handy.\n\nNeu: Möchtest du, dass wir das Fahrzeug zurückzubringen? Dann wähle am Ende der Miete einfach den bequemen Rückbring-Service iHv. nur 10€ + 0,49€ je 100m.",

      "rental.walkthrough.page2.title": "Zurückbringen und Miete beenden",

      "resend": "Erneut senden",

      "reserve.btn.blocked": "in Wartung",

      "reserve.btn.unavailable": "Aktuell gebucht",

      "reset": "Zurücksetzen",

      "reset.password.button.title": "Aktualisieren",

      "reset.password.placeholder": "Neues Passwort",

      "reset.password.title": "Passwort ändern",

      "reset.password.updated": "Dein Passwort wurde aktualisiert.",

      "returncar.apply": "JETZT FREIGEBEN",

      "returncar.cancel": "Auto jetzt nicht freigeben",

      "returncar.title": ", wann möchtest du dein Auto wieder?",

      "review": "Bewertung",

      "review.dialog.later": "Nicht jetzt 😒",

      "review.dialog.message": "Wenn dir unsere App gefällt, schreibe uns bitte eine Bewertung.\n\nDamit hilfst du, mehr Nutzer für nachhaltige Mobilität zu begeistern 🙏",

      "review.dialog.no": "Feedback",

      "review.dialog.title": "⭐⭐⭐⭐⭐",

      "review.dialog.yes": "Ja, gefällt mir 😃",

      "route.dialog.applemaps": "Apple Maps",

      "route.dialog.copy": "Adresse kopieren",

      "route.dialog.googlemaps": "Google Maps",

      "salutation": "Anrede",

      "save": "Speichern",

      "schufa.address": "ADRESSE",

      "schufa.button.title": "Jetzt freischalten",

      "schufa.dateOfBirth.dateFormat": "Tag / Monat / Jahr",

      "schufa.dateOfBirth.label": "Geburtstag (TT/MM/JJJJ)",

      "schufa.instruction": "Um diese Zahlungsmethode freizuschalten, gib bitte folgende Infos zur Prüfung an.",

      "schufa.notification.message": "😥 Unser Zahlungsmittelanbieter kann leider kein SEPA-Mandat für dich einrichten. Bitte wähle eine alternative Zahlungsmethode. Wir arbeiten bereits an weiteren Alternativen.",

      "schufa.notification.title": "Zahlungsmethode freischalten",

      "schufa.personalInfo": "PERSÖNLICHE INFORMATIONEN",

      "schufa.title": "Zahlungsart freischalten",

      "schufaCheck.dialog.message": "😥 Unser Zahlungsmittelanbieter kann leider kein SEPA-Mandat für dich einrichten. Bitte wähle eine alternative Zahlungsmethode. Wir arbeiten bereits an weiteren Alternativen.",

      "schufaCheck.dialog.title": "Zahlungsmittel-Freigabe",

      "schufaCheck.dialog.yes": "OK",

      "seconds.short": "Sek.",

      "selectPayment.desr": "Hinterlege dein gewünschtes Zahlungsmittel, um deine Registrierung abzuschließen. ",

      "selectPayment.sepaCard.text": "Lastschrift hinzufügen",

      "selectPayment.title": "Zahlungsmittel hinzufügen",

      "selectPayment.visaCard.text": "Visa oder MasterCard hinzufügen",

      "selfie.add.button": "Video (3 Sekunden) aufnehmen",

      "service.TIL.desc": "teilAuto ist mit über 1.800 Fahrzeugen in 26 Städten das bedeutendste Carsharing-Angebot für Sachsen, Sachsen-Anhalt und Thüringen.",

      "service.TIL.provider": "Mobility Center GmbH",

      "service.TIL.title": "teilAuto",

      "service.account": "Kundenkonto",

      "service.agb": "AGB",

      "service.bnd.org.note": "Sobald alle geforderten Daten eingetragen und geprüft wurden, wirst du nach erfolgreicher Freischaltung per E-Mail benachrichtigt.",

      "service.button.text": "Auftrag annehmen",

      "service.ctf.desc": "cityflitzer ist mit über 150 Fahrzeugen der erste Freefloating-Carsharer in Leipzig für spontane Fahrten von A nach B. Logge dich nach erfolgter Freischaltung einfach mit deiner Kundennummer und dem bei der Registrierung gewählten Passwort ein.",

      "service.ctf.provider": "Mobility Center GmbH",

      "service.ctf.title": "cityflitzer",

      "service.customer.number": "Kundennummer",

      "service.customer.pin": "PIN (Entriegeln)",

      "service.data": "Datenschutzerklärung",

      "service.email": "E-Mail",

      "service.imprint": "Impressum",

      "service.info": "Anbieterinformationen",

      "service.info.desc": "Beschreibung",

      "service.info.provider": "Anbieter",

      "service.login": "Log-in",

      "service.login.section": "Zugangsdaten",

      "service.map.filter.apply": "Auf Karte anzeigen",

      "service.payment": "Zahlungsprofil",

      "service.report.accomplished": "Ich habe Services durchgeführt",

      "service.report.image.note": "Auf mindestens einem Foto muss das Kennzeichen zu erkennen sein.",

      "service.report.noService.note": "Zusätzliche Notizen",

      "service.reserved.services.title": "Angefragte Aufträge",

      "service.start.button": "Auftrag starten",

      "service.started.finish.button": "Auftrag abschließen",

      "service.started.services.title": "Angefragte Aufträge",

      "service.status": "Status",

      "service.status.locked": "Nicht freigeschaltet",

      "service.status.locked.note": "Entweder fehlen noch Daten für eine erfolgreiche Freischaltung oder es besteht bereits ein Nutzerkonto.",

      "service.tab.all": "Alle",

      "service.tab.thisWeek": "Woche",

      "service.tab.thisweek": "Woche",

      "service.tab.today": "Heute",

      "service.tab.tomorrow": "Morgen",

      "service.tariff": "Tarif",

      "service.title": "Service-Fahrt",

      "service.type.basicBranding": "Autosticker",

      "service.type.basicCarPhotos": "Fotos für Autoprofil",

      "service.type.basicDamageRecords": "Schadensakte",

      "service.type.batteryChangeOpening": "Batteriewechsel (mit Notfallöffnung)",

      "service.type.batteryChangeSimple": "Batteriewechsel (einfach)",

      "service.type.batteryCharge": "Batterie laden",

      "service.type.cleanOutsideDry": "Außenreinigung (trocken)",

      "service.type.comboPremium": "comboPremium",

      "service.type.comboPro": "Paket \"Komplettpflege\"",

      "service.type.comboRefuelWashInside": "Paket \"Tanken & Innenreinigung\"",

      "service.type.comboRefuelWashOutside": "Package \"Refuel & Clean outside\"",

      "service.type.comboRefuelWashTotal": "Package \"Refuel & Total Clean\"",

      "service.type.dashboardCleaning": "Lüftungsschlitze und Ablagen intensiv reinigen",

      "service.type.desinfektion": "Desinfektion",

      "service.type.exchangeFuelCard": "Tankkarte wechseln",

      "service.type.extra10min": "Sonderreinigungszeit, je 10 Min",

      "service.type.grillCleaning": "Kühlergrill-Reinigung",

      "service.type.highGlossFinish": "Hochglanz-Finish mit Lackversiegelung",

      "service.type.noService": "Ich habe keinen Service durchgeführt",

      "service.type.otherBasicService": "Sonstiger Service",

      "service.type.refuel": "Betankung",

      "service.type.relocate": "Verlegungsfahrt",

      "service.type.relocation": "Verlegungsfahrt",

      "service.type.rimsCleaning": "Felgen-Intensivpflege",

      "service.type.safetyCheck": "Safety-Check",

      "service.type.tirePressure": "Reifendruck",

      "service.type.vacuumCleaning": "Kofferraum und Fußmatten intensiv saugen",

      "service.type.washInside": "Innenreinigung",

      "service.type.washOutside": "Außenreinigung (nass)",

      "service.type.windshieldFluidRefill": "Auffüllen des Scheibenwischwassers",

      "services.item.subTitle": "Wasch-Mein-Auto Serviceauftrag",

      "services.map.filter.apply": "Auf Karte anzeigen",

      "settings": "Einstellungen",

      "settings.addPaymentMethod.payIn": "Vorkasse freischalten",

      "settings.addPaymentMethod.paypal": "PayPal hinzufügen",

      "settings.addPaymentMethod.sepa": "Lastschrift",

      "settings.addPaymentMethod.sepa.disabled": "Aktuell nicht verfügbar",

      "settings.addPaymentMethod.visa": "Kredit-/Debitkarte hinzufügen",

      "settings.address.footnote": "Mit dem Bearbeiten meiner Adressangaben stimme ich einer etwaigen %s zur Identitätsprüfung zu.",

      "settings.address.footnote.url": "https://getaway-docs.s3.eu-central-1.amazonaws.com/de-DE/Getaway_DataPrivacy-SchufaChecks-de.pdf",

      "settings.address.footnote.url.text": "Datenprüfung",

      "settings.address.org.text": "Geschäftsadresse hinzufügen",

      "settings.circles.data": "EXKLUSIVE NUTZERKREISE",

      "settings.circles.data.add": "Nutzerkreis beitreten",

      "settings.consents": "Einwilligungen",

      "settings.email.update.success": "Danke, deine E-Mail-Adresse ist aktualisiert worden.",

      "settings.gdpr": "Meine Daten anfordern",

      "settings.login.data": "KONTODATEN",

      "settings.login.data.driverslicense": "FAHRERLAUBNIS",

      "settings.login.data.email.edit": "Tippe, um deine E-Mail-Adresse zu ändern",

      "settings.login.data.email.facebook": "Verbinde deinen Facebook-Account",

      "settings.login.data.facebook.dest": "Verbinde deinen Facebook-Account und nutze der 1-Klick Login. Wir posten nichts auf Facebook ohne deinen ausdrücklichen Wunsch!",

      "settings.login.data.moreactions": "WEITERE EINSTELLUNGEN",

      "settings.login.data.notifications": "Benachrichtigungen",

      "settings.login.data.notifications.change": "Verwalte deine Benachrichtigungen",

      "settings.login.data.password": "Passwort zurücksetzen",

      "settings.login.data.password.edit": "Tippe, um dein Passwort zu ändern",

      "settings.login.data.terminate": "Konto entfernen",

      "settings.login.data.touchId": "Touch/Face ID",

      "settings.login.data.touchId.edit": "Login mit Touch/Face ID",

      "settings.login.data.user.add.address.text": "Meldeadresse hinzufügen",

      "settings.login.data.user.add.phone.text": "Handy-Nummer hinzufügen",

      "settings.login.data.user.address.disabled.text": "Für Änderungen kontaktiere bitte unser Service Team",

      "settings.login.data.user.address.text": "Tippe, um deine Adresse zu ändern",

      "settings.login.data.user.info.text": "Für Änderungen kontaktiere bitte unser Service Team",

      "settings.login.data.user.phone.text": "Tippe, um deine Handy-Nummer zu ändern",

      "settings.logout": "Abmelden",

      "settings.notification": "Benachrichtigung",

      "settings.org.data": "FIRMENDATEN",

      "settings.org.personal.data": "PERSÖNLICHE DATEN",

      "settings.payIn.open": "Diese Zahlungsart kann nicht genutzt werden, bis die Identitätsprüfung abgeschlossen ist. Bitte kontaktiere unseren Support.",

      "settings.payIn.status.open.message": "Bitte füge deinen Personalausweis hinzu, um Zahlung per Vorkasse nutzen zu können.",

      "settings.payment.data": "ZAHLUNGSPROFILE",

      "settings.payment.data.add": "Zahlungsmittel hinzufügen",

      "settings.payment.footnote": "Mit der Auswahl des SEPA-Lastschriftverfahrens willige ich der Verarbeitung meiner Daten zur %s ein.",

      "settings.payment.footnote.url": "https://getaway-b2b.s3.eu-central-1.amazonaws.com/b2b_smp/WL_DataPrivacy-DATA_Checks-de+02_2024.pdf",

      "settings.payment.footnote.url.text": "Prüfung der Zahlungswahrscheinlichkeit",

      "settings.payment.payIn.title": "Zahlung per Vorkasse",

      "settings.payment.paypal.title": "PayPal hinzufügen",

      "settings.payment.profile.add": "Zahlungsprofil hinzufügen",

      "settings.payment.sepa.title": "Lastschrift hinzufügen",

      "settings.payment.sepa.url": "https://google.com",

      "settings.permission": "Berechtigung erforderlich",

      "settings.personal.data": "PERSÖNLICHE DATEN",

      "settings.select.tariff": "Tarif wählen",

      "settings.selectedPayment.payIn.title": "Vorkasse",

      "settings.tariff": "Tarif %s",

      "settings.tariff.6654adffda9d82661df73dcb.name": "cityflitzer",

      "settings.tariff.66574cfe7dfac60795ffa1bc.name": "cityflitzer",

      "settings.terminate.info": "Bitte kontaktiere unser Service Team, um dein Konto löschen zu lassen.",

      "settings.terminate.no": "Abbrechen",

      "settings.terminate.yes": "Kontakt",

      "settings.title": "EINSTELLUNGEN",

      "settings.upload.camera": "Foto aufnehmen",

      "settings.upload.file": "Datei auswählen",

      "settings.upload.title": "Dokument zur Prüfung hochladen",

      "settings.uploadDoc": "Dokument hochladen",

      "share.car.message": "Schau dir dieses Auto bei GETAWAY an: %@ %@, ab %@/km bzw. %@/Tag inkl. Versicherung und Benzin https://applink.get-a-way.com/map?id=%@",

      "share.car.subject": "Ich habe ein Auto für dich gefunden",

      "share.panel.description": "Teile deinen Einladungslink",

      "share.panel.title": "Du willst mehr Autos?",

      "shortcut.release": "Mein Auto freigeben",

      "shortcut.release4hours": "Mein Auto für 4 Std. freigeben",

      "signup.button.title": "Jetzt gratis registrieren",

      "signup.card.org.desc": "zB. GmbH, AG, e.V., Gmbh & Co KG, KG, OHG",

      "signup.card.org.title": "Als Organisation",

      "signup.company.name.placeholder": "Firmenname (ohne Rechtsform)",

      "signup.desc": "Melde dich jetzt gratis an und starte deine erste Miete in wenigen Minuten.",

      "signup.desc.noBonus": "Melde dich jetzt gratis an und starte deine erste Miete.",

      "signup.description": "melde dich jetzt gratis bei GETAWAY an und erhalte %@.",

      "signup.description.highlight": " 5€ Startguthaben ",

      "signup.dialog.ownsACar.message": "Die Info hilft uns, unsere Services für dich zu verbessern.",

      "signup.dialog.ownsACar.title": "Hast du ein Auto?",

      "signup.domain.dialog.confirm": "Bestätigen",

      "signup.domain.dialog.message": "Bitte prüfe deine E-Mail-Adresse.",

      "signup.email.org.placeholder": "E-Mail (dienstlich)",

      "signup.email.placeholder": "E-Mail",

      "signup.facebook.complete.placeholder": "VERVOLLSTÄNDIGEN",

      "signup.facebook.dateofbirth.placeholder": "Geburtstag",

      "signup.facebook.email.placeholder": "E-Mail",

      "signup.facebook.firstname.placeholder": "Vorname",

      "signup.facebook.lastname.placeholder": "Nachname",

      "signup.facebook.picker.mr": "Herr",

      "signup.facebook.picker.mrs": "Frau",

      "signup.facebook.picker.ms": "Frau",

      "signup.facebook.title": "VIA FACEBOOK ANMELDEN",

      "signup.facebook.title.placeholder": "Anrede",

      "signup.fees.text": "Entgelte & Gebühren",

      "signup.footnote.login.action": "Login",

      "signup.footnote.login.text": "Du hast ein Nutzerkonto?",

      "signup.header": "Jetzt registrieren",

      "signup.individual.dialog.btn": "» Zur Registrierung als Einzelperson",

      "signup.individual.dialog.msg": "Bitte nutze in diesem Falle die Registrierung als Einzelperson.",

      "signup.individual.org.desc": "zB. Einzelunternehmer, GbR, eK, UG",

      "signup.individual.org.title": "Als Einzelunternehmer oder Mitglied einer GbR",

      "signup.invite.circleCard.text": "Schließ dich einem unserer exklusiven Nutzerkreise an und erhalte besonderen Zugang zu Fahrzeugen und Preisen!",

      "signup.invite.code.seleted.text": "Erhalte jetzt dein %@ Startguthaben von %@ !",

      "signup.invite.title": "Ich bin eingeladen worden (optional)",

      "signup.legal.other.placeholder": "Andere",

      "signup.legal.type.placeholder": "Rechtsform",

      "signup.name.placeholder": "Vorname",

      "signup.name.policy.text": "Datenschutzerklärung",

      "signup.name.terms.text": "Mit der Registrierung akzeptiere ich die AGB und Datenschutzerklärung von GETAWAY.",

      "signup.name.termsservice.text": "AGB",

      "signup.org.director.header": "Und nun zu deiner Person. Bitte wählen:",

      "signup.org.header": "Als Organisation registrieren",

      "signup.org.info": "Angaben zu deiner Organisation",

      "signup.org.manual.link": "» Nicht dabei? Manuell eingeben",

      "signup.org.search.button": "Suchen",

      "signup.org.search.header": "Ok, lass uns deine Organisation finden",

      "signup.org.search.placeholder": "Firmenname inkl. Rechtsform (zB. Firma GmbH)",

      "signup.org.type.header": "Welche Rechtsform hat deine Organisation?",

      "signup.password.placeholder": "Passwort",

      "signup.personal.section": "Angaben zu deiner Person",

      "signup.policy.cancel": "Abbrechen",

      "signup.register.location.placeholder": "Registergericht",

      "signup.register.nr.placeholder": "Register-Nr",

      "signup.terms.text": "Mit deiner Registrierung akzeptierst du die %s und %s von %s.",

      "signup.terms.url": "https://mietsmart.app/terms#avb",

      "signup.terms.url.text": "AGB",

      "signup.title": "Hi,",

      "signup.type.company": "Als Organisation registrieren",

      "signup.type.dialog.bnd": "book-n-drive",

      "signup.type.dialog.bnd.link": "https://web.book-n-drive.de/registration/pid/575",

      "signup.type.dialog.cf": "cityFlitzer",

      "signup.type.dialog.cf.link": "https://de1.cantamen.de/pub/okw/form?orgnr=125&package=&authKey=77f2663b-3e06-84d7-9dfb-a521724124f4&product=firm-jp-wok",

      "signup.type.dialog.title": "Als Organisation registrieren bei",

      "signup.type.private": "Als Einzelperson registrieren",

      "signup.update.placeholder": "Neue E-Mail Adresse",

      "signup.zipcode.placeholder": "Wie lautet deine Postleitzahl?",

      "sort.byDistance": "Sortieren (Distanz)",

      "sort.byDistance.label": "Nach Distanz sortieren",

      "sort.byDue": "Sortieren (Fälligkeit)",

      "sort.byDue.label": "Nach Fälligkeit sortieren",

      "sppStandard.SPPKunde.pricing": "| Mietdauer    |  |\n| :-------- | -------: |\n| Stunde 1 bis 4 | ~~6,90~~ 0 €/Std*|\n| Ab 5. Stunde | 8,90 €/Std|\n| Nachtarif | 20 €|\n| Wochenendtarif | 79 €|\n\n *Der Rabatt auf die ersten vier Stunden ist nur einmalig einlösbar.\n\n\n| Fahrtstrecke (inkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Je Kilometer | 0,30 € / km |\n\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 1.500€ | 0 € (inklusive) |\n| max. 500€ | 11 €/Kal.Tag |\n| max. 150€ | 19 €/Kal.Tag |\n\n| Zusatzleistungen    |  |\n| :-------- | -------: |\n| Insassenschutz  | 10 € |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand < 25%  | 15 € |\n| Tankstand < 5%  | 50 € |\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://api.get-a-way.com/pricing/64b800ea1c6567078806a254/pdf) zu entnehmen.",

      "sppStandard.SPPMitarbeiter.pricing": "| Mietdauer    |  |\n| :-------- | -------: |\n| Stunde 1 bis 4 | ~~6,90~~ 0 €/Std*|\n| Ab 5. Stunde | 8,90 €/Std|\n| Nachtarif | 20 €|\n| Wochenendtarif | 79 €|\n\n*Der Rabatt auf die ersten vier Stunden ist nur einmalig einlösbar.<br/>\n\n\n| Fahrtstrecke (inkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Je Kilometer | 0,30 € / km |\n\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 1.500€ | 0 € (inklusive) |\n| max. 500€ | 11 €/Kal.Tag |\n| max. 150€ | 19 €/Kal.Tag |\n\n| Zusatzleistungen    |  |\n| :-------- | -------: |\n| Insassenschutz  | 10 € |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand < 25%  | 5 € |\n| Tankstand < 5%  | 50 € |\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://api.get-a-way.com/pricing/64b800ea1c6567078806a254/pdf) zu entnehmen.",

      "sppStandard.ubeeqo.pricing": "| Mietdauer    |  |\n| :-------- | -------: |\n| Stunde 1 bis 4 | 6,90 €/Std|\n| Ab 5. Stunde | 8,90 €/Std|\n| Nachttarif | 20 €|\n| Wochenendtarif | 79 €|\n\n| Fahrtstrecke (inkl. Kraftstoff)   |  |\n| :-------- | -------: |\n| Je Kilometer | 0,30 € / km |\n\n\n| Selbstbeteiligung (je Schadensfall)    |  |\n| :-------- | -------: |\n| max. 750€ | 0 € (inklusive) |\n| max. 500€ | 11 €/Kal.Tag |\n| max. 150€ | 19 €/Kal.Tag |\n\n| Zusatzleistungen    |  |\n| :-------- | -------: |\n| Insassenschutz  | 10 € |\n\n| Tank-Service (bei Rückgabe)    |  |\n| :-------- | -------: |\n| Tankstand < 25%  | 5 € |\n| Tankstand < 5%  | 50 € |\n\n> Die vollständige Aufstellung aller Entgelte sind der [aktuellen Preisliste](https://api.get-a-way.com/pricing/64b800ea1c6567078806a254/pdf) zu entnehmen.",

      "start.engine.description": "Bitte entnehme den Schlüssel aus dem Handschuhfach.",

      "start.engine.title": "Starte den Motor und gute Fahrt!",

      "station.booking.button": "Schlüsselübergabe",

      "station.booking.cancel": "Reservierung stornieren",

      "station.booking.category": "Fahrzeugkategorie",

      "station.booking.code": "Reservierungscode",

      "station.booking.confirm.button": "Jetzt reservieren",

      "station.booking.confirm.category": "Fahrzeugkategorie",

      "station.booking.confirm.dates": "Reservierungsdaten",

      "station.booking.confirm.description": "Überprüfe deine Reservierungsdaten und schließe die Reservierung ab",

      "station.booking.confirm.from": "Von",

      "station.booking.confirm.group": "Abholung",

      "station.booking.confirm.title": "Prüfe deine Reservierung",

      "station.booking.confirm.to": "Bis",

      "station.booking.confirm.where": "Wo?",

      "station.booking.description": "Sobald deine Reservierung beginnt, hast du 30 Minuten Zeit, das Fahrzeug am Express-Schalter der Station abzuholen.",

      "station.booking.from": "Von",

      "station.booking.group": "Abholung",

      "station.booking.key.category": "Kategorie",

      "station.booking.key.code": "Reservierungscode",

      "station.booking.key.description": "Zeige diese Ansicht einem Mitarbeiter an der Station. Nach erfolgreicher Schlüsselübergabe beginnt dein Mietvorgang automatisch.",

      "station.booking.key.from": "Von",

      "station.booking.key.insurance": "Selbstbeteiligung",

      "station.booking.key.insurance.default": "SB500",

      "station.booking.key.item": "Fahrzeug",

      "station.booking.key.title": "Schlüsselübergabe",

      "station.booking.key.to": "Bis",

      "station.booking.later.than": "Bis spätestens",

      "station.booking.later.value": "Schlüsselübergabe",

      "station.booking.merchant.waiting": "wird abgerufen",

      "station.booking.reservation": "Reserviert",

      "station.booking.title": "Deine Reservierung",

      "station.booking.to": "Bis",

      "station.booking.where": "Wo?",

      "storage.permission.details": "Bitte gewähre GETAWAY Zugriff auf deinen Speicher um die angefragten Dateien abzuspeichern.",

      "storage.permission.title": "Zugriffsanfrage gewähren",

      "storage.permission.yes": "Erlauben",

      "submit": "Übernehmen",

      "submt": "Übernehmen",

      "support.call": "Service Team anrufen",

      "support.cancel": "Abbrechen",

      "support.chat": "LiveChat",

      "support.email": "E-Mail",

      "support.phone.number": "+493012088089",

      "support.whatsapp": "WhatsApp",

      "tariff.Firmentarif": "Firmentarif",

      "tariff.Firmentarif.desc": "✓ inklusive Selbstbeteiligung <s>1500€</s>  750€\n\n✓ inklusive Kraftstoffkosten\n\n✓ bis zu 50% auf den Zeitpreis sparen\n\n✓ Über 4.000 Fahrzeuge deutschlandweit im stationsbasierten und -flexiblen Carsharing",

      "tariff.Firmentarif.price": "<div style=\"display: flex;\">einmalig <s style=\"margin-left: 3px; margin-right: 3px;\"> 19€ </s> <strong style=\"color: #749600;\"> gratis</strong>・monatlich 0€</div>",

      "tariff.button.text": "Tarif übernehmen",

      "tariff.desc": "Wähle deinen passenden Tarif und optional die Reduzierung der Selbstbeteiligung.",

      "tariff.header.desc": "Bestätige jetzt den passenden Tarif.",

      "tariff.org.consent": "Der Kundenvertrag wird vorbehaltlich einer positiven Bonitätsauskunft abgeschlossen und kann bei negativer Auskunft widerrufen werden.",

      "tariff.title": "Tarif wählen",

      "tariff.upselling.SB.header": "Sicherheitspaket",

      "tariff.upselling.free.header": "Kein Extra-Schutz",

      "tariff.upselling.free.price": "0€ (inklusive)",

      "tariff.upselling.title": "Service-Paket",

      "tba": "tba",

      "terms.url": "https://get-a-way.com/agb?utm_source=app",

      "textkey": "de-DE",

      "time": "Zeitpunkt",

      "tirePressure": "Reifendruck",

      "today": "Heute",

      "toggle.or": "ODER",

      "tomorrow": "Morgen",

      "touchId.password.dialog.message": "Bitte gib jetzt dein GETAWAY Passwort ein, um Touch ID zu aktivieren.",

      "touchId.password.dialog.title": "Mit Passwort bestätigen",

      "touchid.enable.message": "Melde dich ab sofort noch einfacher bei GETAWAY per Touch ID an.",

      "touchid.login.message": "Nutze Touch ID, um dich noch einfacher bei GETAWAY anzumelden.",

      "transaction.open.button": "Jetzt bezahlen",

      "transaction.open.description": "Bitte zahle die offene Rechnung ",

      "transaction.open.title": "Offene Zahlung",

      "transactions.bonus.description": "[{0}]",

      "transactions.bonus.title": "Gutschrift",

      "transactions.cancel.description": "Storno [{0}]",

      "transactions.cancel.title": "Rückerstattung",

      "transactions.fee.description": "{0} {1}",

      "transactions.fee.title": "Entgelt #{0}",

      "transactions.firststepsbonus.description": "🎉 Willkommensbonus",

      "transactions.goodwillbonus.description": "Kulanz-Gutschrift [{0}]",

      "transactions.payment.deposit.description": "Rechnung [{0}]",

      "transactions.payment.deposit.title": "Kaution Einzahlung",

      "transactions.payment.title": "(An-)Zahlung [{0}]",

      "transactions.payout.title": "Auszahlung",

      "transactions.refuel.description": "{0} am {1} Uhr",

      "transactions.refuel.title": "Tankvorgang",

      "transactions.refuelbonus.description": "🙏 Danke für's Tanken",

      "transactions.refund.deposit.description": "Mietvorgang [{0}]",

      "transactions.refund.deposit.title": "Rückzahlung Kaution",

      "transactions.refund.title": "Auszahlung",

      "transactions.rental.description": "{0} | {1}km am {2} Uhr",

      "transactions.rental.title": "Rechnung [{0}]",

      "transactions.type.bonus": "Gutschrift",

      "transactions.type.bonus.description": "Erste Schritte wurden gemeistert",

      "transactions.type.fee": "Gebühr",

      "transactions.type.payment": "Zahlung",

      "transactions.type.payment.reason": "Grund",

      "transactions.type.referrerBonus": "Gutschrift",

      "transactions.type.referrerBonus.description": "Für deine Empfehlung an deine Freunde",

      "trip.adult.desc": "ab 15 Jahre",

      "trip.adult.placeholder": "Passagiere (Regeltarif)",

      "trip.cancel.confirm.msg": "Möchtest Du die Fahrt tatsächlich stornieren? Bitte beachte, dass Stornierungsgebühren anfallen können.",

      "trip.cancel.confirm.title": "Fahrt stornieren",

      "trip.cancel.dialog.title": "Möchtest Du die Fahrt tatsächlich stornieren? Bitte beachte, dass Stornierungsgebühren anfallen können.",

      "trip.cancel.no": "Abbrechen",

      "trip.cancel.yes": "Jetzt stornieren",

      "trip.children.desc": "0 - 14 Jahre",

      "trip.children.placeholder": "Passagiere (ermäßigt)",

      "trip.damage.report": "Schaden melden",

      "trip.driving.refuel": "Jetzt tanken",

      "trip.force.finish": "Fahrt vorzeitig beenden",

      "trip.force.finish.msg": "Möchtest Du die Fahrt auf eigenes Risiko jetzt vorzeitig beenden?",

      "trip.force.finish.title": "Fahrt vorzeitig beenden",

      "trip.force.no": "Verwerfen",

      "trip.force.yes": "Jetzt beenden",

      "trip.opened.stepOne": "Ich bin angeschnallt",

      "trip.opened.stepTwo": "Die Fahrzeugtür ist wieder verschlossen",

      "trip.order.arrived": "Rufbus ist da: Jetzt Seitentür öffnen",

      "trip.order.brake": "Notbremse",

      "trip.order.driving": "Gute Fahrt!",

      "trip.order.finished.submit": "Fahrt beenden",

      "trip.order.opened": "Bitte einsteigen",

      "trip.order.parking": "Bitte aussteigen",

      "trip.order.parking.submit": "Fahrt beenden",

      "trip.order.start": "Los geht’s",

      "trip.order.stop": "Zwischenhalt: nicht Dein Ausstieg.",

      "trip.order.transit": "Zwischenhalt: nicht Dein Ausstieg.",

      "trip.order.unlock": "Tür ist geöffnet",

      "trip.order.waiting": "Rufbus ist auf dem Weg",

      "trip.parking.stepOne": "Ich bin ausgestiegen",

      "trip.parking.stepThree": "Fahrzeugtür ist wieder verschlossen",

      "trip.parking.stepTwo": "Ich habe nichts vergessen",

      "trip.payByLink.description": "Kaution für Mietvorgang %@",

      "trip.search.confirm": "Bestellen",

      "trip.search.enter.destination": "Zieladresse übernehmen",

      "trip.search.enter.start": "Ziel eingeben",

      "trip.search.enter.start.location": "Startadresse übernehmen",

      "trip.service.type.refuel": "Refueling",

      "trip.service.type.relocate": "Relocating",

      "trip.service.type.relocateAndRelease": "Relocate And Release",

      "trip.service.type.title": "Welcher Service wurde durchgeführt?",

      "trip.start.location.placeholder": "Start-Adresse eingeben",

      "trip.stop.confirm.msg": "Möchtest du die Notbremse tatsächlich auslösen? Bitte beachte, dass ein Missbrauch strafbar ist.",

      "trip.stop.confirm.title": "Notbremse",

      "trip.stop.item": "Haltestelle %s",

      "trip.stop.no": "Abbrechen",

      "trip.stop.yes": "Notbremse auslösen",

      "trip.stops.title": "%s Haltestellen",

      "trip.support": "Service kontaktieren",

      "trip.wheelchair.desc": "1 Rollstuhl je Fzg möglich",

      "trip.wheelchair.placeholder": "Rollstuhlbedarf",

      "tripHistory.complain": "Beschwerde einreichen",

      "tripHistory.end": "Rückgabe",

      "tripHistory.fee": "Mietvorgang",

      "tripHistory.revenue": "Mieteinnahmen",

      "tripHistory.services": "Durchgeführte(r) Service(s)",

      "tripHistory.start": "Übernahme",

      "tutorial.listcar.button": "AUTO GRATIS CHECKEN",

      "tutorial.listcar.step1.message": "Dein Auto steht täglich 23 Std. still. Gib es in Standzeiten minutenweise zum Vermieten frei und verdiene bis zu 100€ pro Woche und mehr.",

      "tutorial.listcar.step1.title": "Parkst du \nnoch oder \nverdienst du \nschon?",

      "tutorial.listcar.step2.message": "Bestimme spontan die Verfügbarkeit und deinen Verdienst pro Kilometer. Erhalte deine Einnahmen automatisch gutgeschrieben.",

      "tutorial.listcar.step2.title": "Du \nbehältst\ndie \nKontrolle",

      "tutorial.listcar.step3.message": "Wir prüfen jeden Mieter, behalten dein Auto mit modernster Technologie im Blick und lassen es rundum über „Die Gothaer“ versichern.",

      "tutorial.listcar.step3.title": "Damit\ndu gelassen\nbleiben\nkannst",

      "tutorial.listcar.step4.message": "Bestehende Ressourcen effizienter nutzen: Entlaste die Umwelt ganz nebenbei, ohne auf deine Freiheit eines Autos zu verzichten.",

      "tutorial.listcar.step4.title": "Gut für’s \nGewissen. \nGut für die \nUmwelt.",

      "tutorial.listcar.step5.message": "Lass dein Auto jetzt gratis und unverbindlich von uns prüfen und erfahre, wie viel dein Auto für dich verdienen kann.",

      "tutorial.listcar.step5.title": "Wie viel\nkann dein\nAuto \nverdienen?",

      "tutorial.map.listCar.button": "JETZT CHECKEN",

      "tutorial.map.listCar.message": "Lass jetzt gratis und unverbindlich prüfen, wie viel dein Auto für dich verdienen kann.",

      "tutorial.map.listCar.title": "Wie viel verdient \ndein Auto?",

      "tutorial.map.step1.message": "Für Hilfe, Feedback oder Anregungen ist unser Team stets nur einen Klick entfernt.",

      "tutorial.map.step1.title": "Stets an\ndeiner Seite",

      "tutorial.map.step2.message": "Über das Nutzer-Symbol gelangst du zu deinen persönlichen Einstellungen.",

      "tutorial.map.step2.title": "Alles\nim Blick",

      "tutorial.map.step3.message": "Gib deinen Wunschzeitraum an und verfügbare Autos werden automatisch gefiltert.",

      "tutorial.map.step3.title": "Spontan\nein Auto?",

      "tutorial.map.step4.button": "LOS GEHT’S",

      "tutorial.map.step4.message": "Schließe die ersten Schritte ab und sichere dir dein Startguthaben. Es dauert nur 2 Minuten.",

      "tutorial.map.step4.title": "Erobere\ndie Straße",

      "tutorial.signup.button": "LOS GEHT’S",

      "tutorial.signup.extraAction": "Ich habe ein Nutzerkonto »",

      "tutorial.signup.step1.message": "Öffne & miete Privatautos in deiner Nähe sofort per Handy\n",

      "tutorial.signup.step1.title": "AUTOS\nVON\nNEBENAN",

      "tutorial.signup.step2.message": "Preise je Kilometer machen Kosten planbar und dich im Stau entspannt.",

      "tutorial.signup.step2.title": "STRESS\nDICH\nNICHT",

      "tutorial.signup.step3.message": "Vermiete dein Auto spontan, während du es selbst nicht benötigst.",

      "tutorial.signup.step3.title": "DU\nHAST\nEIN\nAUTO?",

      "tutorial.signup.step4.message": "Gib dein Auto beim Parken mit nur einem Klick zum Vermieten frei",

      "tutorial.signup.step4.title": "MIT\nNUR\nEINEM\nKLICK",

      "unavailablecar.request": "Start Booking",

      "until": "Bis",

      "update.address.add": "HINZUFÜGEN",

      "update.address.city": "Stadt",

      "update.address.country": "Land",

      "update.address.desc": "Bitte beachte, dass es sich hierbei um deine offizielle Meldeadresse handeln muss.",

      "update.address.germany": "Deutschland",

      "update.address.location.error": "GETAWAY ist noch nicht in deinem Land verfügbar.",

      "update.address.number": "Nr",

      "update.address.search": "Adresse suchen",

      "update.address.street": "Straße",

      "update.address.success": "Danke, deine Adresse ist erfolgreich aktualisiert worden.",

      "update.address.update": "AKTUALISIEREN",

      "update.address.zip": "Plz",

      "update.email.desc": "Deine aktuelle E-Mail-Adresse lautet %@. Bitte gib deine neue E-Mail ein und bestätige dies mit deinem Passwort.",

      "update.email.new": "Neue E-Mail-Adresse",

      "update.email.title": "E-Mail-Adresse aktualisieren",

      "update.email.update": "AKTUALISIEREN",

      "update.email.wrong.pass": "Falsches Passwort",

      "update.notifications.channels": "KANÄLE",

      "update.notifications.email": "Per E-Mail",

      "update.notifications.email.desc": "Erlaubnis, dich per E-Mail zu kontaktieren.",

      "update.notifications.mail": "Per Post",

      "update.notifications.mail.desc": "Erlaubnis, dich per Post zu kontaktieren.",

      "update.notifications.push": "Per Push-Nachricht",

      "update.notifications.push.desc": "Erlaubnis, dich per App-Mitteilung zu kontaktieren.",

      "update.notifications.push.wizard.button": "ZU DEN EINSTELLUNGEN",

      "update.notifications.push.wizard.desc": "Aktiviere Push-Nachrichten",

      "update.notifications.push.wizard.latter": "NICHT JETZT",

      "update.notifications.push.wizard.latter.error": "Push-Nachricht sind deaktiviert",

      "update.notifications.push.wizard.title": "Push-Nachrichten aktivieren",

      "update.notifications.sms": "Per SMS",

      "update.notifications.sms.desc": "Erlaubnis, dich per SMS zu kontaktieren.",

      "update.notifications.title": "BENACHRICHTIGUNGEN",

      "update.notifications.topics.name.bookings.description": "Wir halten dich über deine Buchungen auf dem Laufenden.",

      "update.notifications.topics.name.bookings.title": "Buchungen",

      "update.notifications.topics.name.monthlyOverview.description": "Behalte deine Fahrten und dein Guthaben im Blick",

      "update.notifications.topics.name.monthlyOverview.title": "Monatliche Übersicht",

      "update.password.forgot.error": "Bitte gib deine E-Mail-Adresse an und tippe erneut auf \"Vergessen\". Wir senden dir im Anschluss ein Link zum Zurücksetzen deines Passworts.",

      "update.password.text": "Sofern die E-Mail-Adresse registriert ist, ist soeben ein Link zum Zurücksetzen des Passworts an %@ gesendet worden.",

      "update.password.wizard.desc": "Bitte gib ein neues Passwort ein",

      "update.password.wizard.newpassword": "Neues Passwort",

      "update.password.wizard.title": "Passwort aktualisieren",

      "update.password.wizard.update": "AKTUALISIEREN",

      "upsellings.button.text": "Übernehmen",

      "upsellings.header.desc": " Im Schadenfall kann es schnell teuer werden. Beschränke dein Haftungsrisiko.",

      "upsellings.title": "Selbstbeteiligung",

      "user.DL.completed.message": "Um deine Fahrerlaubnisdaten (Fahrverbot oder neuer Führerschein) zu aktualisieren, wende dich bitte an unser Service Team.",

      "user.DL.inProgress.message": "Bitte habe noch etwas Geduld. Deine Fahrerlaubnis wird gerade verifiziert.",

      "user.DL.resubmission.message": "Maximale Anzahl an Versuchen überschritten. Bitte kontaktiere unser Service Team.",

      "user.ID.completed.message": "Um eine Änderung vorzunehmen, kontaktiere bitte unser Service Team.",

      "user.ID.inProgress.message": "Dein Ausweisdokument wird verifiziert. Wir bitten um Geduld.",

      "user.ID.resubmission.message": "Um ein Ausweis-Dokument hochzuladen, kontaktiere bitte unser Service-Team.",

      "user.action.support.text": "Service Team kontaktieren",

      "user.add_to_circle.brand_not_matches": "Nutzerkreis und Anbieter stimmen nicht überein, Nutzerkreis kann daher nicht hinzugefügt werden.",

      "user.address.completed.message": "Um deine Adresse zu ändern, wende dich bitte an unser Service Team.",

      "user.address.inProgress.message": "Bitte habe noch etwas Geduld. Deine Adresse wird gerade verifiziert.",

      "user.car.selector.listed": "%@ (vom %@)",

      "user.car.selector.release_ready": "%@ (Nicht freigegeben)",

      "user.car.selector.released": "%@ (Freigegeben)",

      "user.car.selector.rented": "%@ (In Miete)",

      "user.car.selector.submitted": "Neues Auto (vom %@)",

      "user.car.selector.title": "Wähle dein Auto",

      "user.circle.description": "Willst du diesen Nutzerkreis wirklich verlassen?",

      "user.circle.leave": "Nutzerkreis verlassen",

      "user.circle.leave.description": "Wenn du diesen Nutzerkreis verlässt, hast du keinen Zugang mehr zu dessen Fahrzeugen und Angeboten. Bist du sicher, dass du fortfahren möchtest?",

      "user.circle.switchAccess": "Nutzerkreis wechseln",

      "user.deletePayment.dialog.message": "Bist du sicher ?",

      "user.deletePayment.dialog.no": "Abbrechen",

      "user.deletePayment.dialog.title": "Zahlungsprofil entfernen",

      "user.deletePayment.dialog.yes": "Entfernen",

      "user.deposit.dialog.msg": "Um eine Änderung vorzunehmen, kontaktiere bitte unser Service Team.",

      "user.deposit.dialog.no": "Jetzt nicht",

      "user.deposit.dialog.yes": "LiveChat öffnen",

      "user.duplicate.email.cancel": "ABBRECHEN",

      "user.duplicate.email.msg": "Eine E-Mail darf nur ein Mal verwendet werden.\nBitte melde dich mit deinem initialen Nutzerkonto an. Falls du dein Passwort vergessen hast, kannst du es in der Anmeldemaske zurücksetzen.",

      "user.duplicate.email.yes": "JETZT ANMELDEN",

      "user.duplicate.iban.cancel": "ABBRECHEN",

      "user.duplicate.iban.msg": "Eine IBAN darf nur ein Mal verwendet werden. \n\nBitte melde dich mit deinem initialen Nutzerkonto an. \n\nFalls du dein Passwort vergessen hast, kannst du es in der Anmeldemaske zurücksetzen.",

      "user.duplicate.iban.yes": "JETZT ANMELDEN",

      "user.duplicate.phone.cancel": "ABBRECHEN",

      "user.duplicate.phone.msg": "Eine Handy-Nummer darf nur ein Mal verwendet werden.<br /><br />\nBitte melde dich mit deinem initialen Nutzerkonto an. Falls du dein Passwort vergessen hast, kannst du es in der Anmeldemaske zurücksetzen.",

      "user.duplicate.phone.yes": "JETZT ANMELDEN",

      "user.email.completed.message": "Um deine E-Mail-Adresse zu ändern, wende dich bitte an unser Service Team.",

      "user.logout.cancelReservation.message": "Bitte storniere die Reservierung, bevor du fortfährst.",

      "user.logout.cancelReservation.ok": "Ok",

      "user.logout.dialog.message": "Bist du sicher?",

      "user.logout.dialog.no": "Abbrechen",

      "user.logout.dialog.title": "Abmelden",

      "user.logout.dialog.yes": "Abmelden",

      "user.noPayment.action": "Zahlungsmittel hinzufügen",

      "user.noPayment.message": "Bitte hinterlege zuerst ein gültiges Zahlungsmittel",

      "user.notNow": "Jetzt nicht",

      "user.org.signupCertificate.events.signedUp.description": "Das Nutzerkonto ist als organisatorisches Konto unter dem Namen %s, mit der Rechtsform %s, der Register-Nr %s am Registergericht %s durch %s %s mit der E-Mail-Adresse %s registriert und den/r zu diesem Zeitpunkt gültigen/r AGB und Datenschutzerklärung ist zugestimmt worden.",

      "user.payment.not.added": "Es wurde bisher keine Zahlungsmethode hinterlegt. Bitte hole dies zuerst nach.",

      "user.phone.completed.message": "Um deine Handynummer zu ändern, wende dich bitte an unser Service Team.",

      "user.phone.edit.desc": "Um deine Telefonnummer zu aktualisieren, senden wir einen Bestätigungscode an deine E-Mail-Adresse.",

      "user.profile.payment.org.pending": "Die Zahlungsmethode wird nach erfolgreicher Prüfung durch das Service Team freigegeben.",

      "user.profile.payment.pending": "Die Zahlungsmethode wird verifiziert, sobald Ausweis und Führerschein hinzugefügt wurden.",

      "user.scan.attempts.out": "Maximale Anzahl an Versuchen überschritten. Bitte kontaktiere unser Service Team.",

      "user.selectPayment.noCC": "Ich habe keine Kredit-/Debitkarte",

      "user.signupCertificate.accountHistory": "Nutzerkonto-Verlauf",

      "user.signupCertificate.createdBy": "Erstellt von",

      "user.signupCertificate.createdBy.at": "am",

      "user.signupCertificate.deviceUuid": "UUID",

      "user.signupCertificate.documentId": "ID (Dokument)",

      "user.signupCertificate.events.addressVerified.description": "Dem Nutzerkonto ist die Meldeadresse %s hinzugefügt worden.",

      "user.signupCertificate.events.creditCheck.description": "Der Verarbeitung der Daten zur Prüfung der Zahlungswahrscheinlichkeit ist eingewilligt worden. Der Abgleich ergab eine Übereinstimmung und einen Score.",

      "user.signupCertificate.events.creditCheck.plusAddress.description": "Der Verarbeitung der Daten zur Prüfung der Zahlungswahrscheinlichkeit ist eingewilligt worden. Der Abgleich ergab eine Übereinstimmung, einen Score und die Adresse %s.",

      "user.signupCertificate.events.idVerified.description": "Dem Nutzerkonto ist das Ausweisdokument (Typ: %s) mit der Nummer %s hinzugefügt worden.",

      "user.signupCertificate.events.idVerified.plusAddress.description": "Dem Nutzerkonto ist das Ausweisdokument (%s) mit der Dokumenten-Nummer %s und Adresse %s hinzugefügt worden",

      "user.signupCertificate.events.licenseVerified.description": "Dem Nutzerkonto ist der Führerschein mit der Nummer %s hinzugefügt worden.",

      "user.signupCertificate.events.marketingConsentGiven.description": "Die Einwilligung \"Newsletter\" ist erteilt worden.",

      "user.signupCertificate.events.marketingConsentRevoked.description": "Die Einwilligung \"Newsletter\" ist entzogen worden.",

      "user.signupCertificate.events.merchantReferenceAssigned.description": "Dem Nutzerkonto ist im Zuge der Freischaltung die Kunden-Nr %s hinzugefügt worden.",

      "user.signupCertificate.events.phoneVerified.description": "Dem Nutzerkonto ist die Handynummer %s hinzugefügt worden.",

      "user.signupCertificate.events.sepaAdded.description": "Dem Nutzerkonto ist die Zahlungsmethode SEPA %s hinzugefügt und dem Lastschriftmandat zugestimmt worden.",

      "user.signupCertificate.events.signedUp.description": "Das Nutzerkonto ist als %s %s mit der E-Mail Adresse %s registriert und den/r zu diesem Zeitpunkt gültigen/r AGB und Datenschutzerklärung ist zugestimmt worden.",

      "user.signupCertificate.events.tariffSelected.description": "Dem Nutzerkonto ist der Tarif \"%s\" (ohne Reduzierung der Selbstbeteiligung) hinzugefügt und der Anmeldegebühr ist zugestimmt worden.",

      "user.signupCertificate.events.tariffSelected.descriptionWithServicePackage": "Dem Nutzerkonto ist der Tarif \"%s\" (mit Service-Paket) hinzugefügt und dem Verzicht auf das Widerrufsrecht zugestimmt worden.",

      "user.signupCertificate.events.tariffSelected.descriptionWithUpsellings": "Dem Nutzerkonto ist der Tarif \"%s\" (mit Reduzierung der Selbstbeteiligung) hinzugefügt und der Anmeldegebühr ist zugestimmt worden.",

      "user.signupCertificate.events.tariffSelected.descriptionWithoutServicePackage": "Dem Nutzerkonto ist der Tarif \"%s\" (ohne Service-Paket) hinzugefügt und dem Verzicht auf das Widerrufsrecht zugestimmt worden.",

      "user.signupCertificate.id.DRIVING_LICENSE": "Führerschein",

      "user.signupCertificate.id.ID_CARD": "Personalausweis",

      "user.signupCertificate.id.PASSPORT": "Reisepass",

      "user.signupCertificate.id.UNSUPPORTED": "Unbekannt",

      "user.signupCertificate.id.VISA": "Aufenthaltstitel",

      "user.signupCertificate.ip": "IP",

      "user.signupCertificate.page": "Seite",

      "user.signupCertificate.pageOf": "von",

      "user.signupCertificate.status": "Status",

      "user.signupCertificate.status.active": "Aktiv",

      "user.signupCertificate.title": "Titel",

      "user.signupCertificate.titleValue": "Nutzerkonto Registrierungsprotokoll",

      "user.signupCertificate.userId": "ID (Nutzer)",

      "user.signupCertificate.userIdentity": "Nutzer-Identität",

      "user.terminate.dialog.message": "Achtung: Die Löschung deines Benutzerkontos kann nicht wieder rückgängig gemacht werden!",

      "user.terminate.dialog.no": "Abbrechen",

      "user.terminate.dialog.title": "Nutzerkonto entfernen",

      "user.terminate.dialog.yes": "Jetzt endgültig entfernen",

      "user.transaction.settled.success": "Zahlung wurde erfolgreich abgeschlossen.",

      "usercar.fillprofile.footnote": "Die besten Tipps, um deine Mieteinnahmen zu erhöhen, findest du in unserem %@.",

      "usercar.fillprofile.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto#mein-autoprofil",

      "usercar.fillprofile.footnote.url.text": "HilfeCenter",

      "usercar.item.blocked.title": "Dein Auto kann nicht freigegeben werden. Bitte kontaktiere unser Service Team für mehr Infos.",

      "usercar.item.eligible.title": "In Warteschlange",

      "usercar.item.equipment_ready.title": "Installation in Kürze",

      "usercar.item.equipped.title": "Dein Auto ist ausgerüstet.",

      "usercar.item.listed.title": "Dein Auto steht auf der Warteliste.",

      "usercar.item.release_ready.title": "Nicht freigegeben",

      "usercar.item.released.title": "Freigegeben bis",

      "usercar.item.selected.title": "Dein Auto wird in Kürze ausgerüstet.",

      "usercar.item.submitted.description": "Übermittelt am",

      "usercar.item.submitted.title": "Dein Auto wird geprüft.",

      "usercar.item.terminated.description": "Entfernt am",

      "usercar.item.terminated.title": "Dein Autoprofil wird entfernt.",

      "usercar.photo.description": "Mach am besten ein Foto von vorne, von der Seite und vom Innenraum.",

      "usercar.profile.blocked.status": "BLOCKIERT",

      "usercar.profile.blocked.title": "Dieses Auto ist aktuell geblockt.",

      "usercar.profile.eligible.description": "Es ist nun in der Warteschlange, um in den Fuhrpark aufgenommen zu werden. Je mehr sich aus deiner Nähe anmelden, desto schneller bist du dabei. Teile jetzt deinen persönlichen Einladungscode mit deinen Freunden und Nachbarn.",

      "usercar.profile.eligible.status": "IN WARTESCHLANGE",

      "usercar.profile.eligible.title": "Prima %@, dein Auto hat alle Tests bestanden 💪",

      "usercar.profile.equipment_ready.description": "Unser Techniker kommt zum vereinbarten Wunschtermin zu der von dir gewählten Adresse. Er wird dich ca. 30 Minuten vor Terminbeginn anrufen, um den exakten Treffpunkt beim Auto abzusprechen. Bitte habe deinen Autoschlüssel und Kfz-Schein parat.\n\nBitte gib uns mind. 48 Stunden vorab Bescheid, solltest du den Termin nicht wahrnehmen können, um etwaige Gebühren zu vermeiden.",

      "usercar.profile.equipment_ready.status": "INSTALLATION",

      "usercar.profile.equipment_ready.title": "%@, der Einbautermin für dein Auto steht bevor",

      "usercar.profile.equipped.description": "Bitte habe einen Moment Geduld. Sobald die letzten Funktions- und Sicherheitschecks abgeschlossen sind, wird dein Auto freigeschaltet.",

      "usercar.profile.equipped.status": "WIRD FREIGESCHALTET",

      "usercar.profile.equipped.title": "%@, in Installation ist abgeschlossen",

      "usercar.profile.firssteps.description": "Um Vermieter zu werden, schließe jetzt bitte die Ersten Schritte ab.",

      "usercar.profile.firssteps.title": "Bitte vervollständige jetzt deine Nutzerangaben",

      "usercar.profile.firststeps.description": "Im Anschluss können wir prüfen, was dein Auto für dich verdienen könnte.",

      "usercar.profile.firststeps.title": "%@, bitte vervollständige jetzt deine https://applink.get-a-way.com/user-challenges-firststeps",

      "usercar.profile.firststeps.title.url": "https://applink.get-a-way.com/user-challenges-firststeps",

      "usercar.profile.firststeps.title.url.text": "Nutzerangaben",

      "usercar.profile.listed.description": "Lege hier fest, wie Mietern dein Auto angeboten werden soll. Du kannst diese Infos jederzeit anpassen.",

      "usercar.profile.listed.status": "IN PRÜFUNG",

      "usercar.profile.listed.title": "%@, vervollständige jetzt dein Autoprofil",

      "usercar.profile.release.availableuntil": "Jetzt freigeben bis",

      "usercar.profile.release_ready.status": "PRIVAT",

      "usercar.profile.released.availableuntil": "Freigegeben bis",

      "usercar.profile.released.status": "FREIGEGEBEN",

      "usercar.profile.rented.status": "VERMIETET",

      "usercar.profile.selected.description": "Um einfachste Mietabläufe zu gewährleisten und Acht auf dein Auto geben zu können, wenn es vermietet wird, rüsten wir es einmalig mit unserem SafetyKit aus. Unser Techniker-Team wird dich zeitnah kontaktieren, um einen individuellen Einbautermin zu vereinbaren.",

      "usercar.profile.selected.status": "AUSGEWÄHLT",

      "usercar.profile.selected.title": "%@, dein Auto kann ausgerüstet werden 🙌",

      "usercar.profile.service.status": "IM SERVICE",

      "usercar.profile.submit.action": "Jetzt prüfen lassen",

      "usercar.profile.submit.description": "Hunderte sind bereits dabei. Lass jetzt gratis & unverbindlich prüfen, wie viel dein Auto für dich verdienen könnte.",

      "usercar.profile.submit.left": "MEIN AUTO",

      "usercar.profile.submit.status": " ",

      "usercar.profile.submit.title": "erfahre, was dein Auto für dich verdienen könnte!",

      "usercar.profile.submitted.description": "Lege hier fest, wie Mietern dein Auto angeboten werden soll. Du kannst diese Infos jederzeit anpassen.",

      "usercar.profile.submitted.status": "IN PRÜFUNG",

      "usercar.profile.submitted.title": "%@, vervollständige jetzt dein Autoprofil",

      "usercar.profile.terminated.status": "TERMINATED",

      "usercar.profile.title": "Mein Auto",

      "usercar.review.novalue": "✨ Neues Auto",

      "usercar.troubleshoot": "Störungsbehebung",

      "usercar.troubleshoot.dialog.message": "Als Autobesitzer kannst du jetzt dein Auto entriegeln und die Motorsperre aufheben. Dein Auto ist im Anschluss nicht mehr über GETAWAY gesichert. Wir empfehlen dringend, nur fortzufahren, sofern du dich unmittelbar an deinem Auto befindest.",

      "usercar.troubleshoot.dialog.title": "Störungsbehebung",

      "usercar.troubleshoot.dialog.unlock": "Auto- und Motorsperre deaktivieren",

      "usercarprofile.example.title": "Was ein Mieter zahlen würde",

      "usercarprofile.price.details.footnote": "Weitere Infos, wie du den idealen Preis festlegst, findest du in unserem %@.",

      "usercarprofile.price.details.footnote.url": "https://service.get-a-way.com/wie-vermiete-ich-mein-auto/mein-autoprofil/so-legst-du-den-idealen-preis-fur-dein-auto-fest",

      "usercarprofile.price.details.footnote.url.text": "Hilfe-Center",

      "usercarprofile.price.indicator.title": "du verdienst",

      "usercarprofile.price.slider.aboveaverage": "überdurchschnittlich",

      "usercarprofile.submit.status": " ",

      "users.merchantSystem.sync": "Sync",

      "validation.accountholder.invalid": "Bitte gib einen gültigen Kontoinhaber an",

      "validation.address.street.invalid": "Bitte gültigen Straßennamen eintragen",

      "validation.address.streetnumber.invalid": "Bitte gültige Hausnummer eintragen",

      "validation.address.zip.invalid": "Bitte gültige Postleitzahl eintragen",

      "validation.email.invalid": "Bitte gültige E-Mail-Adresse eintragen",

      "validation.iban.invalid": "Bitte eine gültige IBAN eintragen",

      "validation.name.invalid": "Mind. zwei Buchstaben",

      "validation.password.invalid": "Mind. 8 Zeichen (inkl. Groß-/ Kleinbuchst./ Ziff./ Sonderz.)",

      "validation.phone.invalid": "Diese Handy-Nummer kann nicht bestätigt werden. Bitte nutze eine andere.",

      "validation.required": "Bitte ausfüllen.",

      "validation.required.empty": "Dieses Feld darf nicht leer bleiben",

      "validator.name.error": "Dein Name muss mindestens zwei Buchstaben enthalten.",

      "validator.password.error": "Passwort muss mind. aus 6 Zeichen inklusive einer Ziffer bestehen.",

      "validator.streetNumber.error": "Bitte gebe eine gültige Hausnummer an",

      "verify.phone.failed": "Falscher Code",

      "verify.phone.passwordView.description": "Deine aktuelle Handy-Nummer lautet %@. Bitte gib dein Passwort ein.",

      "verify.phone.passwordView.title": "Handy-Nummer ändern",

      "verify.phone.resend.success": "Du wirst in Kürze einen Bestätigungscode per SMS erhalten",

      "verify.phone.success": "Danke, deine Handy-Nummer ist erfolgreich aktualisiert worden.",

      "video": "Video",

      "violation.adyenPaymentProvider.responseValidator.authorized.assertTrue": "Diese Karte kann nicht hinzugefügt werden. Bitte nutze eine andere Kreditkarte.",

      "violation.adyenPaymentProvider.responseValidator.cvcDeclined.assertFalse": "Ungültige Prüfziffer. Bitte überprüfe deine Angaben und versuche es erneut.",

      "violation.adyenPaymentProvider.responseValidator.noResponse.assertFalse": "Verbindung fehlgeschlagen. Bitte probiere es in wenigen Momenten erneut.",

      "violation.adyenPaymentProvider.responseValidator.notificationSuccess.assertTrue": "Diese Karte kann nicht hinzugefügt werden. Bitte nutze eine andere Kreditkarte.",

      "violation.apiVersionCheck.version.min": "Deine App-Version wird nicht mehr unterstützt, bitte update sie über den App Store.",

      "violation.booking.bookingPayInAllowedValidator.payInAllowed.assertTrue": "Zahlung per Vorkasse kann nicht verwendet werden, da du ein Guthaben auf deinem Konto hast. Bitte wende dich an den Kundendienst, um eine Rückerstattung zu beantragen oder verwende eine andere Zahlungsmethode.",

      "violation.booking.bookingPaymentValidator.adyenRequestRejected.assertFalse": "Zahlungsanfrage wurde abgelehnt.",

      "violation.booking.bookingPaymentValidator.adyenRequestTimedOut.assertFalse": "Zahlungsanfrage ausgelaufen.",

      "violation.booking.bookingPaymentValidator.blockedPaymentNotNeededOrConfirmed.assertTrue": "Leider wurde die Autorisierung des Sicherheitsbetrages von deiner Bank abgelehnt. Bitte versuche es mit einer anderen Zahlungsmethode.",

      "violation.booking.bookingRequestValidator.canBook.assertTrue": "Leider kannst du dieses Fahrzeug nicht buchen, da du bereits eine Reservierung für diesen Zeitraum hast. Siehe dazu deine Mietvorgänge.",

      "violation.booking.bookingRequestValidator.confirmedCarValid.assertTrue": "Dieses Fahrzeug ist für den gewählten Mietzeitraum leider nicht verfügbar.",

      "violation.booking.bookingRequestValidator.watchlistCheckPassed.assertTrue": "Bitte stelle sicher, dass dein Nutzerkonto vollständig ist. Falls du Hilfe benötigst, wende dich bitte an den Kundenservice.",

      "violation.booking.bookingRequestValidator.withinOpeningHours.assertTrue": "Buchung nicht möglich. Abholung und Rückgabe des Fahrzeug sind nur innerhalb der Öffnungszeiten möglich. Die Start- und/oder Endzeit der Buchungsanfrage liegen außerhalb.\n\nBitte versuche es erneut mit geänderter Start- und/oder Endzeit. Die Zugangszeiten sind dem Fahrzeugprofil unter \"Info\" zu entnehmen.",

      "violation.booking.bookingRequestValidator.youngDriver.assertFalse": "Wir bitten um Geduld. Um Autos bei GETAWAY mieten zu können, muss dir die Führerscheinklasse B vor mehr als zwei Jahren erteilt worden sein. Du wirst bei Erreichen der Frist automatisch freigeschaltet und benachrichtigt.",

      "violation.booking.offerConfirmationValidator.watchlistCheckPassed.assertTrue": "Deine Nutzerdaten sind unvollständig. Bitte kontaktiere unser Service Team.",

      "violation.car.chassisNumberUniqueValidator.chassisNumber.assertTrue": "Fahrgestellnummer bereits im System",

      "violation.car.damages.characteristics.notNull": "Bitte spezifiziere die Art des Schadens.",

      "violation.car.damages.component.notNull": "Bitte wähle eine Schadenkomponente aus.",

      "violation.car.damages.where.notNull": "Bitte spezifiziere den Ort des Schadens am Auto.",

      "violation.car.generateOwnerAccessToken.rented.assertFalse": "Das Auto kann während eines laufenden Mietvorgangs nicht geöffnet werden.",

      "violation.car.onlineCheck.online.assertTrue": "Das Auto scheint sich in einem Funkloch zu befinden. Bitte probiere es an einer anderen Position erneut oder kontaktiere unser Service Team.",

      "violation.car.owner.notNull": "Das Auto muss einem Besitzer zugeordnet sein.",

      "violation.car.plateUniqueValidator.plate.assertTrue": "Autokennzeichen bereits im System",

      "violation.car.registrationCertificate.plate.pattern": "Bitte gib ein gültiges Kfz-Kennzeichen an.",

      "violation.car.registrationCertificate.plate.plateRegion": "Die Region des eingegebenen Kfz-Kennzeichens ist unbekannt.",

      "violation.car.releaseCheck.availableUntil.assertTrue": "Zu kurz. Bitte wähle einen Freigabezeitraum von mindestens 90 Minuten.",

      "violation.car.releaseCheck.basicPrice.notNull": "Um dein Auto freizugeben, lege jetzt deinen Verdienst pro Kilometer fest.",

      "violation.car.releaseCheck.doorsClosed.assertTrue": "Bitte verschließe alle Türen, um fortzufahren.",

      "violation.car.releaseCheck.fuelCardIn.assertTrue": "Bitte lege die Tankkarte in das dafür vorgesehene Fach, um fortzufahren.",

      "violation.car.releaseCheck.fuelLevel.min": "Der Tankstand muss mindestens 25% betragen, um das Auto freigeben zu können.",

      "violation.car.releaseCheck.ignitionOff.assertTrue": "Bitte schalte die Zündung aus, um fortzufahren.",

      "violation.car.releaseCheck.keyFobIn.assertTrue": "Bitte lege den Schlüssel in das dafür vorgesehene Fach, um fortzufahren.",

      "violation.car.releaseCheck.online.assertTrue": "Das Auto scheint sich in einem Funkloch zu befinden. Bitte probiere es an einer anderen Position erneut oder kontaktiere unser Service Team.",

      "violation.car.releaseCheck.paymentMethodExists.assertTrue": "Bitte hinterlege eine gültige Zahlungsmethode.",

      "violation.car.releaseCheck.windowsClosed.assertTrue": "Bitte verschließe alle Fenster, um fortzufahren.",

      "violation.clientVersionCheck.version.min": "Deine App-Version wird nicht mehr unterstützt, bitte update sie über den App Store.",

      "violation.device.alreadyPairedValidator.device.assertTrue": "Dein Nutzerkonto ist nicht mit diesem Smartphone verbunden. Bitte kontaktiere unser Service Team, um fortzufahren.",

      "violation.driversLicenseTokenData.validation.validToken.assertTrue": "Deine Sitzung ist ungültig bzw. abgelaufen. Bitte versuche es erneut.",

      "violation.fleetAdmin.validPasswordValidator.password.assertTrue": "Bitte trage ein gültiges Passwort ein (mind. 6 Zeichen, inkl. mind. einer Ziffer)",

      "violation.forgotPasswordResource.resetPasswordCheck.validToken.assertTrue": "Dieser Link ist nicht mehr aktuell. Bitte kontaktiere unser Service Team für weitere Infos.",

      "violation.installation.mechanic.notNull": "Die Installation muss einem registrierten Techniker zugeordnet werden.",

      "violation.paymentMethod.existingPaymentMethod.isBlocked.assertFalse": "Das Zahlungsmittel kann nicht hinzugefügt werden. Bitte verwende ein anderes Zahlungsmittel.",

      "violation.paymentResource.paymentMethodTypeCheck.paymentMethodNotAllowed.assertFalse": "Das Zahlungsmittel kann nicht hinzugefügt werden. Bitte verwende ein anderes Zahlungsmittel.",

      "violation.paymentResource.paymentMethodTypeCheck.tariffMissing.assertFalse": "Du musst einen Tarif auswählen bevor du eine Zahlungsmethode hinzufügen kannst.",

      "violation.phoneNumber.noNewNumberWhenPairedValidator.phoneNumber.assertTrue": "Nicht möglich. Dein Nutzerkonto ist mit diesem Smartphone verbunden. Bitte kontaktiere unser Service Team, um fortzufahren.",

      "violation.phoneNumber.userPhoneNumberUniqueValidator.phoneNumber.assertTrue": "Diese Handynummer ist ungültig oder bereits einem aktivem Nutzerkonto hinterlegt. Bitte verwende eine andere oder melde dich mit dem bestehenden Nutzerkonto an.	",

      "violation.phoneNumber.validPhoneNumberValidator.phoneNumber.assertTrue": "Ungültiger Code! Bitte stelle sicher, dass du die richtige Handynummer angegeben hast oder fordere einen neuen Code an.",

      "violation.phoneNumber.validPhoneNumberValidator.smsLimitExceeded.assertFalse": "Telefon-Verifizierungsversuche überschritten.",

      "violation.phoneNumber.validVerificationCodeValidator.phoneNumber.assertTrue": "Bitte gebe den richtigen Bestätigungscode ein",

      "violation.rentalResource.serviceTripCheck.userUnauthorized.assertFalse": "Tut mir leid, du darfst leider keinen ServiceTrip durchführen. Bitte starte eine reguläre Miete.",

      "violation.sepa.ibanValidator.sepa.assertTrue": "Bitte gib eine gültige IBAN ein.",

      "violation.serviceCircle.serviceCircleValidator.pricingEmpty.assertTrue": "Die Änderung von Preisen für Service UserGroups wird nicht unterstützt",

      "violation.trip.cancellation.reserved.assertTrue": "Nur gültige Reservierungen können zurückgenommen werden.",

      "violation.trip.driverQualificationValidator.addressVerified.assertTrue": "Bitte hinterlege deine Meldeadresse, um fortzufahren.",

      "violation.trip.driverQualificationValidator.creditCheck.assertTrue": "Deine Nutzerdaten sind unvollständig. Bitte kontaktiere unser Service Team.",

      "violation.trip.driverQualificationValidator.identityChecked.assertTrue": "Deine Nutzerdaten sind unvollständig. Bitte kontaktiere unser Service Team.",

      "violation.trip.driverQualificationValidator.paymentMethodExists.assertTrue": "Bitte hinterlege eine gültige Zahlungsmethode, um fortzufahren.",

      "violation.trip.driverQualificationValidator.phoneNumberVerified.assertTrue": "Bitte verifiziere deine Handy-Nummer auf diesem Gerät, um fortzufahren.",

      "violation.trip.driverQualificationValidator.positiveBalance.assertTrue": "Bitte stelle sicher, dass dein Guthaben positiv ist, um fortzufahren.",

      "violation.trip.driverQualificationValidator.validLicence.assertTrue": "Um Autos zu mieten, muss dein Führerschein verifiziert worden sein. Den aktuellen Status kannst du in deinem Nutzerprofil einsehen.",

      "violation.trip.finishCheck.bitLessFuelOnReturn.assertFalse": "Tank-Service (2,80 €/Liter) wählen",

      "violation.trip.finishCheck.correctLocation.assertTrue": "Bitte beende die Miete im Umkreis des angegebenen Rückgabeorts. Andernfalls können Gebühren anfallen.",

      "violation.trip.finishCheck.extremelyLowFuelLevel.assertFalse": "Ich wähle den Tank-Service  <10% (50,- €)",

      "violation.trip.finishCheck.finishLocationAllowed.assertTrue": "Ich habe das Fahrzeug zurückgebracht",

      "violation.trip.finishCheck.fuelLevel.min": "Ich wähle den Tank-Service (2 €/l)",

      "violation.trip.finishCheck.fuelLevelOBI.min": "Ich wähle den Tank-Service (15 €)",

      "violation.trip.finishCheck.initialUnlockIsSet.assertTrue": "Das Auto muss initial geöffnet worden sein, um den Mietvorgang beenden zu können.",

      "violation.trip.finishCheck.insideArea.assertTrue": "Ich wähle den Rückbringservice (10€ + 4.9€/km)",

      "violation.trip.finishCheck.keyFobIn.assertTrue": "Die Miete kann nur beendet werden, wenn der Schlüssel korrekt in der Halterung im Handschuhfach hinterlegt ist (neben der Tankkarte). Bitte prüfe dies und versuche es dann erneut.",

      "violation.trip.finishCheck.online.assertTrue": "Es konnte leider keine Verbindung zum Fahrzeug hergestellt werden. Bitte versuche es erneut oder kontaktiere unseren Support.",

      "violation.trip.finishCheck.payAsReserved.assertFalse": "Ich beende meine Miete vorzeitig.",

      "violation.trip.postReservation.accessToken.assertTrue": "Das Fahrzeug kann zum jetzigen Zeitpunkt nicht reserviert werden.",

      "violation.trip.postReservation.accessToken.notNull": "Ups..etwas ist schief gelaufen. Bitte probiere es gleich noch einmal.",

      "violation.trip.postReservation.adyenRequestRejected.assertFalse": "Zahlungsanfrage wurde abgelehnt.",

      "violation.trip.postReservation.adyenRequestTimedOut.assertFalse": "Zahlungsanfrage ausgelaufen.",

      "violation.trip.postReservation.blockedPaymentConfirmed.assertTrue": "Deine Bank hat diese Transaktion abgelehnt. Bitte kontaktiere deine Bank für weitere Informationen oder versuche es mit einem anderen Zahlungsmittel.",

      "violation.trip.rating.onlyFinished": "Der Mietvorgang muss erfolgreich beendet worden sein, um diesen bewerten zu können.",

      "violation.trip.rentalReservationValidator.addressVerified.assertTrue": "Bitte hinterlege deine Meldeadresse, um fortzufahren.",

      "violation.trip.rentalReservationValidator.backByConflictsWithBooking.assertFalse": "Dieses Fahrzeug ist für den gewählten Zeitraum bereits reserviert. Bitte wähle ein anderes Fahrzeug oder eine andere Zeit.",

      "violation.trip.rentalReservationValidator.blockedByUpcomingBooking.assertFalse": "Dieses Fahrzeug ist bereits reserviert, bitte wähle ein anderes.",

      "violation.trip.rentalReservationValidator.carAvailable.assertTrue": "Dieses Auto ist nicht mehr verfügbar. Bitte wähle ein anderes.",

      "violation.trip.rentalReservationValidator.carStatusReleased.assertTrue": "Dieses Auto ist nicht mehr verfügbar. Bitte wähle ein anderes.",

      "violation.trip.rentalReservationValidator.creditworthinessApproved.assertTrue": "Deine Nutzerdaten sind unvollständig. Bitte kontaktiere unser Service Team.",

      "violation.trip.rentalReservationValidator.identityChecked.assertTrue": "Deine Angaben zu Name, Geburtsdatum und Meldeadresse konnten nicht bestätigt werden. Bitte kontaktiere uns über das LiveChat-Symbol oben rechts, um deine Verifizierung abzuschließen.",

      "violation.trip.rentalReservationValidator.online.assertTrue": "Das Fahrzeug ist aktuell nicht verfügbar und kann daher nicht gemietet werden.",

      "violation.trip.rentalReservationValidator.paymentMethodExists.assertTrue": "Bitte hinterlege eine gültige Zahlungsmethode, um fortzufahren.",

      "violation.trip.rentalReservationValidator.phoneNumberVerified.assertTrue": "Es kann keine Reservierung angelegt werden, solange keine Telefonnummer verifiziert ist. Bitte hinterlege eine Telefonnummer in deinem Nutzerkonto und versuche es erneut.",

      "violation.trip.rentalReservationValidator.positiveBalance.assertTrue": "Bitte stelle sicher, dass dein Guthaben positiv ist, um fortzufahren.",

      "violation.trip.rentalReservationValidator.serviceTripAllowed.assertTrue": "Dieses Fahrzeug kann mit deinem Nuterkonto nicht für Service-Fahrten gemietet werden.",

      "violation.trip.rentalReservationValidator.userMarkedForFraud.assertFalse": "😐 Leider ist diese Aktion momentan nicht möglich. Für weitere Informationen kontaktiere bitte unser Service Team.",

      "violation.trip.rentalReservationValidator.userMarkedForFraud.assertTrue": "😐 Leider ist diese Aktion momentan nicht möglich. Für weitere Informationen kontaktiere bitte unser Service Team.",

      "violation.trip.rentalReservationValidator.validLicence.assertTrue": "Um Autos zu mieten, muss dein Führerschein verifiziert worden sein. Den aktuellen Status kannst du in deinem Nutzerprofil einsehen.",

      "violation.trip.rentalReservationValidator.watchlistCheckPassed": "Bitte stelle sicher, dass dein Nutzerkonto vollständig ist. Wenn du Hilfe benötigst, kontaktiere gern unseren Kundenservice.",

      "violation.trip.rentalReservationValidator.withinOpeningHours.assertTrue": "MIete nicht möglich. Start- und/oder Endzeit der Buchungsfrage liegen außerhalb der Öffnungszeiten.",

      "violation.trip.rentalReservationValidator.youngDriver.assertFalse": "Wir bitten um Geduld. Um Autos bei GETAWAY mieten zu können, muss dir die Führerscheinklasse B vor mehr als zwei Jahren erteilt worden sein. Du wirst bei Erreichen der Frist automatisch freigeschaltet und benachrichtigt.",

      "violation.trip.reservationValidator.manualGearRestricted.assertFalse": "Deine Fahrerlaubnis erstreckt sich nicht für Autos mit manueller Schaltung! Bitte wähle ein anderes.",

      "violation.user.account.paymentMethods.sepa.ibanValid": "Bitte gib eine gültige IBAN ein.",

      "violation.user.archiveCheck.hasOpenBookingRequests.assertFalse": "Es scheint als hättest du noch offene Rechnungen bei uns. Bitte kontaktiere einmal unser Service Team für weitere Informationen und um fortzufahren.",

      "violation.user.archiveCheck.hasOpenTransactions.assertFalse": "Kann nicht fortgesetzt werden, da entweder eine offene oder eine nicht beglichene Rechnung vorliegt. Bitte versuchen Sie es später erneut, da dies von der Bestätigung der Zahlung durch die Bank abhängig ist.",

      "violation.user.dateOfBirth.min": "Um ein Fahrzeug mieten zu können, musst du mindestens 2 Jahre lang im Besitz eines gültigen Führerscheins der Klasse B sein.",

      "violation.user.device.alreadyPaired": "Dein Nutzerkonto ist nicht mit diesem Smartphone verbunden. Bitte kontaktiere unser Service Team, um fortzufahren.",

      "violation.user.email.notNull": "Bitte hinterlege in deinem Nutzerkonto eine gültige E-Mail Adresse.",

      "violation.user.email.pattern": "Bitte hinterlege deinem Nutzerkonto eine gültige E-Mail Adresse.",

      "violation.user.email.userEmail.Unique": "Es scheint, als sei deine E-Mail Adresse bereits hinterlegt. Möchtest du dein Passwort zurücksetzen?",

      "violation.user.email.userEmailUnique": "Es scheint, als sei deine E-Mail Adresse bereits hinterlegt. Möchtest du dein Passwort zurücksetzen?",

      "violation.user.emailVerificationCheck.codeNotMatches.assertFalse": "Verifizierungs-Code stimmt nicht überein",

      "violation.user.emailVerificationCheck.codeWasNotGenerated.assertFalse": "Derzeit keine ausstehende E-Mail-Verifizierung.",

      "violation.user.emailVerificationCheck.maxVerificationAttemptsPassed.assertFalse": "Maximale Anzahl an Versuchen überschritten. Bitte kontaktiere unser Service Team.",

      "violation.user.firstName.notNull": "Bitte hinterlege in deinem Nutzerkonto einen gültigen Vornamen.",

      "violation.user.firstName.size": "Bitte hinterlege in deinem Nutzerkonto einen gültigen Vornamen.",

      "violation.user.lastName.size": "Dein Nachname muss aus mind. 2 Buchstaben bestehen.",

      "violation.user.password.validPassword": "Bitte trage ein gültiges Passwort ein (mind. 6 Zeichen, inkl. mind. einer Ziffer)",

      "violation.user.phoneNumber.phoneNumber.noNewNumberWhenPaired": "Nicht möglich. Dein Nutzerkonto ist mit diesem Smartphone verbunden. Bitte kontaktiere unser Service Team, um fortzufahren.",

      "violation.user.phoneNumber.phoneNumber.validVerificationCode": "Ungültiger Code! Bitte stelle sicher, dass du die richtige Handynummer angegeben hast oder fordere einen neuen Code an.",

      "violation.user.phoneNumber.userPhoneNumberUnique": "Diese Handynummer kann nicht hinterlegt werden. Bitte probiere eine andere.",

      "violation.user.phoneNumber.validPhoneNumber": "Bitte hinterlege in deinem Nutzerkonto eine gültige Handynummer.",

      "violation.user.phoneNumber.validVerificationCode": "Dieser Bestätigungscode ist ungültig. Bitte fordere einen neuen an.",

      "violation.user.referredBy.referralCodeExists": "Dieser Einladungscode ist ungültig.",

      "violation.user.requireDevice.hasPairedDevice.assertTrue": "Bitte verifiziere deine Handynummer auf diesem Smartphone, um fortzufahren.",

      "violation.user.requireDevice.isCorrectDevice.assertTrue": "Dieses Handy ist nicht autorisiert für diese Interaktion. Bitte kontaktiere unser Service Team.",

      "violation.user.sendVerificationCodeCheck.smsLimitExceeded.assertFalse": "Telefon-Verifizierungsversuche überschritten.",

      "violation.user.userEmailUniqueValidator.email.assertTrue": "Es scheint, als sei deine E-Mail Adresse bereits hinterlegt. Möchtest du dein Passwort zurücksetzen?",

      "violation.user.validPasswordValidator.password.assertTrue": "Bitte trage ein gültiges Passwort ein (mind. 6 Zeichen, inkl. mind. einer Ziffer) ",

      "violation.validatePaymentMethods.haveBlockedPaymentIfPayInOnly": "bitte wählest du den Zeitrahmen für die Reservierung.",

      "violation.validatePaymentMethods.haveBlockedPaymentIfPayInOnly.assertTrue": "bitte wählest du den Zeitrahmen für die Reservierung.",

      "wait": "Einen Augenblick bitte..",

      "web.copy": "kopieren",

      "web.edit": "ändern",

      "web.external.browser.title": "Bitte fahre jetzt im Web-Browser fort.",

      "web.external.button.section": "Klicke auf folgenden Button",

      "web.external.button.title": "Registrierung im Browser fortfahren",

      "web.external.email.section": "Schau bitte in dein E-Mail-Postfach",

      "web.external.email.section.desc": "Klicke auf den Button in der E-Mail, die wir dir soeben an diese Adresse gesendet haben:",

      "web.external.link.section": "Oder",

      "web.external.link.section.desc": "Kopiere folgenden Link und öffne diesen in deinem Web-Browser:",

      "web.share.message": "Schau bei Miet Smart: %@ %@ per Handy mieten ab %@. auf https://mis.app.getaway.pro/map/?id=%@",

      "whatsapp": "LiveChat (Whatsapp)",

      "wj.copy.no": "Abbrechen",

      "wj.copy.title": "In Zwischenablage speichern",

      "wj.copy.yes": "Kopieren",

      "wj.email.code.placeholder": "4-stelligen Code eingeben",

      "wj.email.verify.desc": "Gib den Code ein, den wir soeben an diese E-Mail-Adresse gesendet haben:",

      "wj.email.verify.title": "E-Mail bestätigen",

      "wj.link.copied": "In die Zwischenablage kopiert",

      "wj.map.filter.violation.duration": "Zeitraum muss min. 1 Stunde betragen.",

      "wj.map.filter.violation.min": "Stelle sicher, dass die angegebenen Daten korrekt sind.",

      "wj.map.filter.violation.order": "Stelle sicher, dass die angegebenen Daten korrekt sind.",

      "wj.map.filter.violation.period": "Buchung muss kürzer sein",

      "wj.phone.code.placeholder": "4-stelligen SMS-Code eingeben",

      "wj.phone.verify.code.desc": "Gib den Code ein, den wir soeben per SMS an diese Handy-Nr gesendet haben:",

      "wj.phone.verify.code.title": "Handy-Nr bestätigen",

      "wj.schufa.birthday.violation.max": "Prüfe, ob das Datum korrekt ist.",

      "wj.schufa.birthday.violation.min": "Prüfe, ob das Datum korrekt ist.",

      "wj.value.copied": "In die Zwischenablage kopiert: %s",

      "wma.trackingPage.extendedArea.name": "Anfahrt, erweitertes Gebiet",

      "yes": "Ja",

      "yesterday": "gestern",

    },
};